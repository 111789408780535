import {
  QCQuoteValues,
  QuoteContract,
  QuoteItemBaseContract,
  QuoteShippingInformation,
  RequestGetSSI
} from '@softtech/webmodule-data-contracts';
import { QuoteContainerManager } from '../quote-container';
import { isDefaultShipping, isFrame, isSSI, isSpecialItem, serverSideShippingItem } from '../quote-helper-functions';
import { localDateToServer } from '../../../components/datetime-converter';
import { addressToV6Strings, emptyAddress } from '../../../components/ui/maps/map-helpers';
import { v6Config, v6ConfigSupplierOptions } from '../../../v6config/v6config';
import { getCurrentUser, tlang } from '@softtech/webmodule-components';
import {
  InputUpdateQuoteItems,
  QuoteItem,
  QuoteItemPrice,
  QuoteItemRowAction,
  QuoteItemType
} from '../../../api/dealer-api-interface-quote';
import { money } from '../../../components/currency-formatter';
import { emptyGuid, newGuid } from '../../../api/guid';
import { quoteItemContentType } from '../default-quote-item-content-type';
import { supplierQuoteItemContentType } from '../supplier-quote-item-content-type';
import { applyV6PricesToQuoteItemPrice } from './helper-functions';
import { fromJsonStr, toJsonStr } from '../../../blob/converters';
import { SSIProcessor } from '../ssi-processor';
import { QuoteItemContainer } from '../quote-item-container';

export type QuoteCalculationSaveEvent = (values: QCQuoteValues | undefined | null) => Promise<void> | void;
export class V6SSIProcessor implements SSIProcessor {
  quoteManager: QuoteContainerManager;
  excludedItemFilter: string[];
  onQuoteCalculationSave?: QuoteCalculationSaveEvent;
  extraItems: QuoteItemContainer[] | undefined;
  constructor(
    qm: QuoteContainerManager,
    itemFilter?: string[],
    onQCSaveEvent?: QuoteCalculationSaveEvent,
    extraItems?: QuoteItemContainer[]
  ) {
    this.extraItems = extraItems;
    this.quoteManager = qm;
    this.excludedItemFilter = itemFilter ?? [];
    this.onQuoteCalculationSave = onQCSaveEvent;
  }

  /**
   *
   * @returns a QuoteContract that only contains the frame items needed for the generation and processing of
   * SSI Freehand items or special items added that are for the supplier to create
   */
  private createQuoteContractForSSIGeneration(): QuoteContract {
    const qm = this.quoteManager;
    const items = (): QuoteItemBaseContract[] => {
      const result: QuoteItemBaseContract[] = [];
      qm.container.items?.forEach(quoteItem => {
        if (!this.excludedItemFilter.includes(quoteItem.id)) {
          const qic = qm.quoteItemContainer(quoteItem.id);
          addQuoteItemContainer(qic, result);
        }
      });
      this.extraItems?.forEach(itemContainer => {
        addQuoteItemContainer(itemContainer, result);
      });

      return result;
    };

    return {
      comment: '',
      deliveryDate: localDateToServer(new Date()), // not used
      quoteCommonId: qm.quote.id,
      quoteItems: items(),
      shipToAddress: addressToV6Strings(qm.quote.shippingAddress ?? emptyAddress()),
      supplierId: qm.quote.supplierId,
      supplierQuoteData: qm.quote.serviceProviderData,
      terms1: null,
      terms2: null,
      terms3: null,
      terms4: null,
      terms5: null,
      title: qm.quote.title
    };

    function addQuoteItemContainer(qic: QuoteItemContainer, result: QuoteItemBaseContract[]) {
      if (isFrame(qic.item) || isSpecialItem(qic.item)) {
        let providerData: string | null = null;
        //This is using internal knowledge of the V6 data structure.
        //we probably want to expose this at some point
        const raw = fromJsonStr(qic.data?.providerData);
        if (raw) (raw as any).bomView = undefined;
        providerData = toJsonStr(raw) ?? null;
        addQuoteItemContainerToResult(qic, providerData, result);
      }
    }

    function addQuoteItemContainerToResult(
      qic: QuoteItemContainer,
      providerData: string | null,
      result: QuoteItemBaseContract[]
    ) {
      const item = qic.item;
      const contractItem: QuoteItemBaseContract = {
        comments: item.comment ?? '',
        description: item.description,
        itemType: isFrame(item) ? supplierQuoteItemContentType.CID_FRAM : supplierQuoteItemContentType.CID_FREEHAND,
        itemSubType: item.quoteItemContentType,
        price: qic.price.quantityCost,
        grossPrice: qic.price.supplierGrossQuantityCost,
        quantity: item.quantity,
        quoteItemCommonId: item.id,
        supplierData: isFrame(item) || isSpecialItem(item) ? providerData : null,
        title: item.title
      };
      result.push(contractItem);
    }
  }

  private async createShippingInformation(): Promise<QuoteShippingInformation> {
    return {
      address: this.quoteManager.quote.shippingAddress ?? emptyAddress(),
      installationAddress: (await this.quoteManager.getInstallationAddress()) ?? emptyAddress()
    };
  }
  private async buildSSIRequest(): Promise<RequestGetSSI> {
    return {
      quote: this.createQuoteContractForSSIGeneration(),
      shippingInformation: await this.createShippingInformation(),
      supplierReferenceOverrideKey: this.quoteManager.quote.quoteOwnerId
    };
  }
  private collectExistingSSIFromQuote() {
    return this.quoteManager.container?.items?.filter(item => isSSI(item)) ?? [];
  }
  public async processSSI() {
    const supplierOptions = v6ConfigSupplierOptions(this.quoteManager.quote.supplierId);
    const getInput = async () => {
      const ssiRequest = await this.buildSSIRequest();
      const ssi = await v6Config().getQuoteSSI(ssiRequest);
      const existingssi = this.collectExistingSSIFromQuote();
      await this.onQuoteCalculationSave?.(ssi.quoteCalculations);

      const shippingLine = this.quoteManager.container?.items?.find(x => isDefaultShipping(x));
      const deletedSSI = existingssi.filter(
        item => !ssi.items.some(x => x.quoteItemContentType === item.quoteItemContentType)
      );
      const updatedSSI = existingssi.filter(item =>
        ssi.items.some(x => x.quoteItemContentType === item.quoteItemContentType)
      );
      const insertedSSI = ssi.items.filter(
        ssiItem => !existingssi.some(x => x.quoteItemContentType === ssiItem.quoteItemContentType)
      );
      const user = getCurrentUser();
      if (!user) return; //cant happen, nullable fix

      const input: InputUpdateQuoteItems = {
        items: []
      };
      deletedSSI.forEach(item => {
        input.items.push({
          action: QuoteItemRowAction.Delete,
          input: {
            quoteItemId: item.id,
            quoteItem: item,
            quoteItemBuyInData: null,
            quoteItemPrice: null,
            quoteItemProviderData: null
          }
        });
      });
      updatedSSI.forEach(item => {
        const updatedSSIItem = ssi.items.find(x => x.quoteItemContentType === item.quoteItemContentType);
        if (!updatedSSIItem) return; //cant happen, nullable fix

        item.comment = updatedSSIItem.comment;
        item.description = updatedSSIItem.description;
        item.title = updatedSSIItem.title;
        item.quantity = updatedSSIItem.quantity;
        const price = this.quoteManager.quoteItemContainer(item.id).price;
        if (price) {
          if (updatedSSIItem.sourceData) applyV6PricesToQuoteItemPrice(item.quantity, updatedSSIItem.sourceData, price);
          else {
            //This is legacy code. from macro v8
            price.singleUnitCost = money(updatedSSIItem.unitPrice, 2);
            price.quantityCost = money(updatedSSIItem.unitPrice * item.quantity, 2);
            price.supplierGrossQuantityCost = price.quantityCost;
            price.supplierGrossSingleUnitCost = price.singleUnitCost;
            price.supplierNettQuantityCost = price.quantityCost;
            price.supplierNettSingleUnitCost = price.singleUnitCost;
          }
        }
        input.items.push({
          action: QuoteItemRowAction.Update,
          input: {
            quoteItemId: item.id,
            quoteItem: item,
            quoteItemBuyInData: null,
            quoteItemPrice: price,
            quoteItemProviderData: null
          }
        });
      });
      const createDate = localDateToServer(new Date());
      const userId = user.id;

      insertedSSI.forEach(newSSIItem => {
        const newId = newGuid();
        const item: QuoteItem = {
          isRestrictedToPowerUser: false,
          comment: newSSIItem.comment,
          commonId: newId, //ignored
          dateCreated: createDate, //ignored
          description: newSSIItem.description,
          id: newId, //ignored
          itemType: QuoteItemType.Basic, //Provider Type is ONLY for items with serviceProvider and Data
          lastModifiedDate: createDate,
          lastModifiedUserId: userId,
          providerReferenceId: emptyGuid,
          quantity: newSSIItem.quantity,
          quoteId: this.quoteManager.quote.id,
          quoteItemContentType: newSSIItem.quoteItemContentType,
          recordVersion: '',
          serviceProvider: '',
          title: newSSIItem.title,
          virtualThumbnailPath: ''
        };
        //initialize a new price object
        const price: QuoteItemPrice = {
          id: item.id,
          singleUnitCost: money(newSSIItem.unitPrice, 2),
          quantityCost: money(newSSIItem.unitPrice * item.quantity, 2), //DNU
          supplierGrossSingleUnitCost: money(newSSIItem.unitPrice, 2),
          supplierNettSingleUnitCost: money(newSSIItem.unitPrice, 2),
          calculatedNetSellingPrice: 0, //DNU
          dateCreated: createDate, //DNU
          marginPercentage: 0, //DNU
          calculatedGrossSellingPrice: 0, //DNU
          priceAdjustment: 0, //DNU
          recordVersion: '', //DNU
          requiresRepricing: false, //DNU
          supplierGrossQuantityCost: 0, //DNU
          supplierNettQuantityCost: 0, //DNU
          supplierPriceAdjustment: 0, //DNU
          sourceData: {},
          isTaxableItem: true
        };
        //V9 Macros up, we use consistent pricing and source data attachment
        if (newSSIItem.sourceData) applyV6PricesToQuoteItemPrice(newSSIItem.quantity, newSSIItem.sourceData, price);

        input.items.push({
          action: QuoteItemRowAction.Insert,
          input: {
            quoteItemId: item.id,
            quoteItem: item,
            quoteItemBuyInData: null,
            quoteItemPrice: price,
            quoteItemProviderData: null
          }
        });
      });

      const hasSSIShipping = input.items.some(
        x => isSSI(x.input.quoteItem, serverSideShippingItem) && x.action !== QuoteItemRowAction.Delete
      );
      if ((hasSSIShipping && shippingLine) || (!hasSSIShipping && shippingLine && !supplierOptions.includeShipping)) {
        input.items.push({
          action: QuoteItemRowAction.Delete,
          input: {
            quoteItemId: shippingLine.id,
            quoteItem: shippingLine,
            quoteItemBuyInData: null,
            quoteItemPrice: null,
            quoteItemProviderData: null
          }
        });
      }
      if (!hasSSIShipping && !shippingLine && supplierOptions.includeShipping) {
        const newId = newGuid();
        const item: QuoteItem = {
          isRestrictedToPowerUser: false,
          comment: '',
          commonId: newId, //ignored
          dateCreated: createDate, //ignored
          description: tlang`Total Shipping Cost`,
          id: newId, //ignored
          itemType: QuoteItemType.Basic,
          lastModifiedDate: createDate,
          lastModifiedUserId: userId,
          providerReferenceId: emptyGuid,
          quantity: 1,
          quoteId: this.quoteManager.quote.id,
          quoteItemContentType: quoteItemContentType.shipping,
          recordVersion: '',
          serviceProvider: '',
          title: tlang`Shipping`,
          virtualThumbnailPath: ''
        };

        input.items.push({
          action: QuoteItemRowAction.Insert,
          input: {
            quoteItemId: item.id,
            quoteItem: item,
            quoteItemBuyInData: null,
            quoteItemPrice: null, //reset price to 0.
            quoteItemProviderData: null
          }
        });
      }
      return input;
    };

    return await getInput();
  }
}
