import { html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { constructAsync } from '../../async-constructor';
import { FranchiseeView } from '../../franchisee/view/franchisee-view';
import { tlang } from '@softtech/webmodule-components';
import { userDataStore } from '../common/current-user-data-store';
import { PageBase } from './helpers/page-base';

import { disposeOf } from '../../components/dispose';

@customElement('app-settings')
export class AppSettings extends PageBase {
  @state()
  franchiseeView: FranchiseeView | null = null;

  constructor() {
    super();
  }

  render() {
    if (!this.loggedIn) return html``;
    return html` <div class="page-content">
      <div class="page-container">${this.franchiseeView}</div>
    </div>`;
  }

  meta() {
    return {
      title: tlang`%%franchisee%%`,
      description: tlang`%%franchisee%% settings page`
    };
  }

  protected async canLeavePage(): Promise<boolean> {
    if (this.franchiseeView) return await this.franchiseeView.canClose();
    else return true;
  }
  protected async awaken() {
    await this.setActiveTabByHash();
    await super.awaken();
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  protected async franchiseeInformationFactory(): Promise<FranchiseeView> {
    return await constructAsync(new FranchiseeView());
  }
  async dispose() {
    await super.dispose();
    await disposeOf(this.franchiseeView);
    this.franchiseeView = null;
  }
  private async setActiveTabByHash() {
    await this.franchiseeView?.setActiveTabByHash();
  }
  protected async afterUserConnected() {
    //we need to flush the user data store any time we come to this page in case it is stale information
    //switch to clear. this is causing problems in the main UI by leaving things in an unloaded state.
    await userDataStore.reLoadFranchisee();
    this.franchiseeView = await this.franchiseeInformationFactory();
    await this.setActiveTabByHash();
  }
}
