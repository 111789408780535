import { ApiCommunications } from '@softtech/webmodule-data-contracts';
import { NullPromise } from '../../null-promise';
import { ItemReference } from '../../cache/definitions/cache-item-reference';
import { DataCacheBase } from '../../cache/data-cache-base';
import {
  RequestGetClientDefaultPaymentProfileListByIds,
  ResultGetClientDefaultPaymentProfileListByIds
} from '../../api/dealer-api-interface-franchisee';
import { PaymentProfileCacheData } from './cache-data';

export class PaymentProfileCache extends DataCacheBase<PaymentProfileCacheData> {
  constructor(api: ApiCommunications) {
    super(api);
    //depending on what high level controller api we create we could swap this to ClientApi etc.
  }

  //override;
  protected async internalFetch(requestIds: string[]): NullPromise<ItemReference<PaymentProfileCacheData>[]> {
    if (requestIds.length == 0) return null;

    const input: RequestGetClientDefaultPaymentProfileListByIds = {
      paymentProfileIds: requestIds
    };
    const result = await this.api.post<ResultGetClientDefaultPaymentProfileListByIds>(
      `api/Franchisee/GetClientDefaultPaymentProfileListByIds`,
      input
    );
    if (!result) return null;

    return result.profiles.map(paymentProfile => {
      const data: PaymentProfileCacheData = { paymentProfile: paymentProfile };
      const qcr: ItemReference<PaymentProfileCacheData> = {
        id: paymentProfile.id,
        displayValue: `${paymentProfile.name}`,
        data: data,
        onClick: null
      };
      return qcr;
    });
  }
}
