import { PropertyValueMap, TemplateResult, html } from 'lit';
import { ModalDialog } from '../../../components/ui/modal-base';
import { ChartSeriesXY } from '../data/chart-data-types';
import { tlang } from '@softtech/webmodule-components';
import { customElement, property } from 'lit/decorators.js';
import { EventTemplate } from '../../../components/ui/events';

const ApexCharts = (globalThis as any).ApexCharts;
@customElement('wm-timelinewidgetmodal')
export class TimelineWidgetModal extends ModalDialog {
  @property({ type: String })
  public eventTitle: string = '';
  @property({ type: Array })
  public seriesData: ChartSeriesXY[] = [];

  chart: any | null = null;

  protected getTitle(): string {
    return this.eventTitle;
  }
  protected async firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): Promise<void> {
    const options = {
      chart: {
        type: 'area',
        height: 400,
        selection: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: { show: false },
      states: {
        hover: {
          filter: {
            type: 'none',
            value: 0
          }
        },
        active: {
          filter: {
            type: 'none',
            value: 0
          }
        }
      },
      series: this.seriesData,
      xaxis: {
        type: 'datetime'
      },
      yaxis: {
        labels: {
          formatter: (value: number) => this.getYAxisLabel(value)
        }
      }
    };

    this.chart = new ApexCharts(this.ui.querySelector('.timeline-chart'), options);
    this.chart.render();
  }

  bodyTemplate(): EventTemplate {
    return html`<div class="timeline-chart"></div>`;
  }

  protected footerTemplate(): TemplateResult | null {
    return html`<button @click=${() => this.hideModal()} class="btn btn-secondary">${tlang`Close`}</button>`;
  }

  protected modalSize(): string {
    return 'modal-xl';
  }

  protected getYAxisLabel(value: number): string {
    return `$${Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1
    }).format(value)}`;
  }
}
