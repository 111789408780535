import { customElement } from 'lit/decorators.js';
import { tlang } from '@softtech/webmodule-components';
import { isEmptyOrSpace } from '../../components/ui/helper-functions';
import {
  createPurchaseOrderView,
  getPurchaseOrderContainer,
  getPurchaseOrderContainerManager,
  PurchaseOrderLock
} from '../purchase-orders/purchase-order-ui-adapter';
import { NullPromise } from '../../null-promise';
import { AppResourcePage } from './app-resource-base';
import { ModalViewBase } from '../../components/ui/data-entry-screen-base';
import { ResourceLocker } from '../common/resource-lock';
import { goStaticURL } from '../../components/ui/resource-resolver';
import { branchSwitchOnResource } from './helpers/branch-switch';

@customElement('app-purchase-order')
export class AppPurchaseOrderPage extends AppResourcePage {
  async createModalEditor(orderId: string): NullPromise<ModalViewBase> {
    const container = getPurchaseOrderContainer(orderId);
    if (isEmptyOrSpace(orderId)) orderId = container.purchaseOrderId;

    const qm = getPurchaseOrderContainerManager(container, () => !this.lock?.isLockOwner);
    await qm.needsPurchaseOrder();

    if (!(await branchSwitchOnResource(qm.quoteOwnerId, tlang`%%purchase-order%%`))) return null;

    return await createPurchaseOrderView(qm);
  }

  async redirectPath(): NullPromise<string> {
    return '/purchase-orders';
  }

  meta() {
    return {
      title: tlang`%%purchase-order%% Editor`,
      description: 'SoftTech dealer CPQ'
    };
  }

  protected createLock(id: string): ResourceLocker | undefined {
    return new PurchaseOrderLock(id, async () => {
      await this.view?.abandonAndClose(true);
      goStaticURL('/purchase-orders');
    });
  }
}
