import * as luxon from 'luxon';
import { getApiFactory } from '../../../api/api-injector';
import { QuoteState, ViewQuoteTotalByState } from '../../../api/dealer-api-interface-quote';
import { emptyGuid } from '../../../api/guid';
import { QuoteApi } from '../../../api/quote-api';
import { MultiPromise } from '../../../multicast-promise';
import { isEmptyOrSpace, isFlagInFilterSet } from '../../../components/ui/helper-functions';

class DashboardCache {
  numberOfDays = Math.ceil(Math.abs(luxon.DateTime.local().startOf('month').diffNow('days').days));

  get quoteApi(): QuoteApi {
    return getApiFactory().quote();
  }

  private branchId?: string;

  quoteFinancialSummaryDataForMonth: ViewQuoteTotalByState[] | null = null;
  quoteFinancialSummaryDataForMonthPromise = new MultiPromise<void>(async () => {
    const result = await this.quoteApi.getQuoteTotalsByState({
      quoteOwnerId: this.branchId ?? emptyGuid,
      options: [
        {
          state: 255 as QuoteState,
          numberOfDaysHistory: this.numberOfDays
        }
      ]
    });

    this.quoteFinancialSummaryDataForMonth = result?.totals ?? null;
  });

  async needsMonthlyQuoteFinancials(branchId?: string) {
    this.branchId = branchId;
    /**
     * the date times are passed in using a local time. which will then be converted to UTC time
     * as stored in the server.
     * when looking for items we always pass in the start as an exact match, and the end as
     * the start of the next day. This is so that when converting to UTC, the search is between
     * the 2 numbers, with the end decremented 1 second
     */
    await this.quoteFinancialSummaryDataForMonthPromise.run();
  }

  /**filter all items by state, or user or both or none */
  getMonthlyQuoteFinancials(state?: QuoteState, userId?: string) {
    return (
      this.quoteFinancialSummaryDataForMonth?.filter(
        x => isFlagInFilterSet(x.quoteState, state) && (isEmptyOrSpace(userId) || userId === x.userId)
      ) ?? []
    );
  }
}

let _db: DashboardCache | null = null;
export function flushDashboard() {
  _db = null;
}
export function dashboard(): DashboardCache {
  if (!_db) {
    _db = new DashboardCache();
  }
  return _db;
}
