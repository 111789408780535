import { customElement, property, query, state } from 'lit/decorators.js';
import { LitElementBase } from '../../components/litelement-base';
import {
  BranchQuoteSupportItemType,
  BranchQuoteSupportStatus,
  InputAddBranchQuoteSupport
} from '../../api/dealer-api-interface-franchisee';
import { PropertyValueMap, html } from 'lit';
import { tlang } from '@softtech/webmodule-components';
import { getApiFactory } from '../../api/api-injector';
import { isEmptyOrSpace } from '../../components/ui/helper-functions';
import { lockUIandExecute } from '../../ui-lock';

import { DevelopmentError } from '../../development-error';

import { getSupportStatusBadge, getSupportTypeDisplayValue } from './conversation-helper';
import { Editor } from '@toast-ui/editor';

import { showValidations } from '../../components/ui/modal-validationhandler';
import { newStandardEditor } from '../../components/ui/toastui';
import { newGuid } from '../../api/guid';
import { WebModuleConversationEntryDropZone } from './wm-conversation-entry-dropzone';
import { ConversationActionTemplate } from './conversation-types';
import { FranchiseeQuoteContainerManager } from '../quotes/data/franchisee-quote-manager';

export interface SupportNewActions {
  cancel: ConversationActionTemplate;
  post: ConversationActionTemplate;
  draft: ConversationActionTemplate;
}

@customElement('wmview-branchquote-support-new')
export class WebModuleBranchQuoteSupportNew extends LitElementBase {
  @property() quoteManager?: FranchiseeQuoteContainerManager;
  get quoteId(): string | undefined {
    return this.quoteManager?.branchQuote.id;
  }
  get quoteSetId(): string | undefined {
    return this.quoteManager?.branchQuote.quoteSetId;
  }
  @property() supportType: BranchQuoteSupportItemType = BranchQuoteSupportItemType.QuoteAssistanceRequest;
  @state()
  isUploading = false;
  @property() showButtons = true;
  @property() showPostButton = true;
  @property() showCancelButton = true;

  @property()
  public get text() {
    return this.editor?.getMarkdown() ?? '';
  }
  @property() branchQuoteSupportId = newGuid();
  @property() conversationEntryId = newGuid();
  @query('#subject') subjectEditor?: HTMLInputElement;

  @property() supplierName = '';

  textEditor: HTMLElement = document.createElement('div');
  @query('#attachments')
  attachmentsEditor?: WebModuleConversationEntryDropZone;
  protected editor?: Editor;

  get subject() {
    return this.subjectEditor?.value ?? '';
  }
  protected getInput(draft = false): InputAddBranchQuoteSupport {
    if (!this.quoteId) throw new DevelopmentError('Support Ticket must have quoteid');
    return {
      id: this.branchQuoteSupportId,
      masterDocumentConversationEntry: null,
      conversationId: this.branchQuoteSupportId,
      branchQuoteId: this.quoteId,
      status: draft ? BranchQuoteSupportStatus.Draft : BranchQuoteSupportStatus.New,
      subject: this.subject,
      type: this.supportType,
      conversationEntry: {
        id: this.conversationEntryId,
        attachments: this.attachmentsEditor?.allFilesAsInputs ?? [],
        text: this.text,
        users: []
      }
    };
  }
  protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (this.textEditor && !this.editor) {
      this.editor = newStandardEditor(this.textEditor);
    }
    setTimeout(() => {
      this.subjectEditor?.focus();
    }, 50);
  }
  protected eventCancelEntry = async (e: CustomEvent) => {
    e.stopImmediatePropagation();
    this.dispatchCustom('cancel', {});
  };

  public getValidations(): string[] {
    const result: string[] = [];
    if (isEmptyOrSpace(this.subject)) {
      result.push(
        tlang`Please add a %%support-subject%% to describe this ${getSupportTypeDisplayValue(this.supportType)}`
      );
    }
    if (isEmptyOrSpace(this.text)) {
      // message specified via PBI 218417
      result.push(tlang`${'ref:branchquote-support-missing-support-initial-topic-msg'}Please provide a %%support-initial-topic%% 
                        before submitting this ${getSupportTypeDisplayValue(this.supportType)} ticket to ${this.supplierName}`);
    }
    return result;
  }

  public async canSave(): Promise<boolean> {
    const validation = this.getValidations();
    return validation.length == 0;
  }

  protected async internalCreateEntry(draft = false) {
    await lockUIandExecute(async () => {
      const result = await getApiFactory().franchisee().addBranchQuoteSupport(this.getInput(draft));
      if (result)
        this.dispatchCustom('created', {
          branchQuoteSupport: result.branchQuoteSupport
        });
    });
    this.requestUpdate();
  }
  public async save(): Promise<boolean> {
    if (await this.canSave()) {
      await this.internalCreateEntry(true);
      return true;
    }
    return false;
  }
  protected eventCreateEntry = async (_e: CustomEvent<{ draft: boolean }>) => {
    const valid = this.getValidations();
    if (valid.length > 0) {
      await showValidations(valid);
      return;
    }
    await this.internalCreateEntry();
  };
  protected eventCreateDraftEntry = async (_e: CustomEvent<{ draft: boolean }>) => {
    const valid = this.getValidations();
    if (valid.length > 0) {
      await showValidations(valid);
      return;
    }
    await this.internalCreateEntry(true);
  };

  connectedCallback(): void {
    super.connectedCallback();
    this.subjectEditor?.focus();
  }

  render() {
    const mainHeader = html`<div
      class="main-header d-flex justify-content-between  border-bottom border-secondary mb-2"
    >
      <h2>${tlang`%%support-request%%`} ${getSupportStatusBadge(BranchQuoteSupportStatus.Draft)}</h2>
    </div> `;

    return html`
      <div class="new-support-ticket form-two-col ">
        <div class="row">
          <div class="eto-left form-column2">
            ${mainHeader}
            <div class="conversation conversation-entry-create">
              <div class="conversation-header mb-3">
                <div class="d-flex conversation-subject-wrapper">
                  <label class="conversation-subject-label me-3">${tlang`%%support-subject%%:`}</label>
                  <input id="subject" class="conversation-subject" type="text" maxlength="100" />
                </div>
              </div>
              ${this.conversationNoteHeader()} ${this.addNoteTemplate()}${this.attachmentsTemplate()}
              ${this.footerTemplate()}
            </div>
          </div>
          <div class="eto-right form-column">
            <!-- Empty, to mimic structure of editor page -->
          </div>
        </div>
      </div>
    `;
  }
  conversationNoteHeader(): unknown {
    const title = tlang`%%support-initial-topic%%`;

    return html`<div class="pb-1 mb-1 mt-2 border-bottom border-secondary">
      <h2 class="text-primary">${title}</h2>
    </div>`;
  }

  protected eventUploadingChanged = (e: CustomEvent<{ isUploading: boolean }>) => {
    this.isUploading = e.detail.isUploading;
    this.dispatchCustom('changed', { isUploading: this.isUploading });
  };
  attachmentsTemplate(): unknown {
    return html` <wmview-conversation-attachments
      id="attachments"
      @wm-event-changed=${this.eventUploadingChanged}
      .conversationId=${this.branchQuoteSupportId}
      .conversationEntryId=${this.conversationEntryId}
    ></wmview-conversation-attachments>`;
  }

  actionsTemplate(): SupportNewActions {
    const result: SupportNewActions = {
      draft: (_hide?: boolean) => {
        return html`<button
          ?disabled=${this.isUploading}
          class="btn btn-primary ms-1"
          @click=${this.eventCreateDraftEntry}
        >
          ${tlang`Save Draft`}
        </button>`;
      },
      post: (_hide?: boolean) => {
        return html`<button ?disabled=${this.isUploading} class="btn btn-primary ms-1" @click=${this.eventCreateEntry}>
          ${tlang`Issue Ticket`}
        </button>`;
      },
      cancel: () => {
        return html`<button ?disabled=${this.isUploading} class="btn btn-secondary" @click=${this.eventCancelEntry}>
          ${tlang`Cancel`}
        </button>`;
      }
    };

    return result;
  }

  footerTemplate(): unknown {
    const actions = this.actionsTemplate();

    return this.showButtons
      ? html`<div class="conversation-list-actions">
          ${this.showPostButton ? actions.post() : ''}${this.showCancelButton ? actions.cancel() : ''}
        </div>`
      : html``;
  }
  addNoteTemplate(): unknown {
    return this.textEditor;
  }
}
