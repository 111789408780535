//Provide a global abstraction from the implementation details of an api call
import { BlobApi } from './blob-api';
import { ApiCommunications } from '@softtech/webmodule-data-contracts';
import { QuoteApi } from './quote-api';
import { ClientApi } from './client-api';
import { ProjectApi } from './project-api';
import { FranchiseeApi } from './franchisee-api';
import { PaymentProfileApi } from './payment-profile-api';
import { PurchaseOrderApi } from './purchase-order-api';
import { SupplierApi } from './supplier-api';
import { ClientAgentApi } from './clientagent-api';
import { AuthenticationOptions, getCurrentUser } from '@softtech/webmodule-components';
import { UserApi } from './user-api';

export type ApiProvider = () => ApiCommunications;

export interface ApiFactory {
  blob: () => BlobApi;
  quote: () => QuoteApi;
  client: () => ClientApi;
  project: () => ProjectApi;
  franchisee: () => FranchiseeApi;
  paymentProfile: () => PaymentProfileApi;
  purchaseOrder: () => PurchaseOrderApi;
  supplier: () => SupplierApi;
  clientAgent: () => ClientAgentApi;
  user: () => UserApi;
}

let _apiInjector: ApiProvider = () => {
  throw new Error('please call setApiInjector');
};

let _apiFactory: ApiFactory = {
  quote: () => {
    throw new Error('please call setApiInjector - quote api not registered');
  },
  blob: () => {
    throw new Error('please call setApiInjector - blob api not registered');
  },
  client: () => {
    throw new Error('please call setApiInjector - client api not registered');
  },
  project: () => {
    throw new Error('please call setApiInjector - project api not registered');
  },
  franchisee: () => {
    throw new Error('please call setApiInjector - franchisee api not registered');
  },
  paymentProfile: () => {
    throw new Error('please call setApiInjector - paymentProfile api not registered');
  },
  purchaseOrder: () => {
    throw new Error('please call setApiInjector - purchaseOrder api not registered');
  },
  supplier: () => {
    throw new Error('please call setApiInjector - supplier api not registered');
  },
  clientAgent: () => {
    throw new Error('please call setApiInjector - clientAgent api not registered');
  },
  user: () => {
    throw new Error('please call setApiInjector - user api not registered');
  }
};

export function setApiInjector(apiInjector: ApiProvider) {
  _apiInjector = apiInjector;
}
export function getApiInjectorEvent(): ApiProvider {
  return _apiInjector;
}

export function getApi(): ApiCommunications {
  return _apiInjector();
}

export function setApiFactory(factory: ApiFactory) {
  _apiFactory = factory;
}

export function getApiFactory() {
  return _apiFactory;
}

export function authenticationOptions(): AuthenticationOptions {
  return {
    api: getApi(),
    apiHost: globalThis.dealerConfiguration.apiHost,
    licenseServerHost: globalThis.dealerConfiguration.licenseServerHost,
    branding: globalThis.dealerConfiguration.branding,
    deploymentId: globalThis.dealerConfiguration.dealerDeploymentId,
    user: getCurrentUser()
  };
}
