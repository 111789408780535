// eslint-disable-next-line import/named
import { html, TemplateResult } from 'lit';
import { DataBinding } from '../databinding/databinding';
import { DataTracker } from '../databinding/data-tracker';

export function formPicker(
  fieldName: string,
  title: string,
  display: string,
  dataBinding: DataBinding,
  dataTracker: DataTracker,
  clickEvent: () => void,
  required = false,
  readonly = false,
  classes = ''
): TemplateResult {
  return html` <bs-form-picker
    class=${classes}
    data-id=${dataBinding.field(fieldName)}
    data-label=${title}
    ?required=${required}
    .valuedisplay=${display}
    ?readonly=${readonly}
    .valuedata=${dataTracker.getObjectValue(fieldName)}
    @picker-clicked=${() => clickEvent()}
  >
  </bs-form-picker>`;
}
