import { customElement } from 'lit/decorators.js';
import { tlang } from '@softtech/webmodule-components';
import { isEmptyOrSpace } from '../../components/ui/helper-functions';
import { NullPromise } from '../../null-promise';
import { ModalViewBase } from '../../components/ui/data-entry-screen-base';
import { AppResourcePage } from './app-resource-base';
import { ResourceLocker } from '../common/resource-lock';
import { goStaticURL } from '../../components/ui/resource-resolver';
import { branchSwitchOnResource } from './helpers/branch-switch';
import { createProjectView, ProjectLock } from '../projects/project-ui-adapter';
import { getProjectContainer, getProjectContainerManager } from '../projects/project-ui-container-manager';

@customElement('app-project')
export class AppProjectPage extends AppResourcePage {
  async redirectPath(): NullPromise<string> {
    return '/projects';
  }

  async createModalEditor(id: string): NullPromise<ModalViewBase> {
    const container = getProjectContainer(id);
    if (isEmptyOrSpace(id)) id = container.projectId;

    const qm = getProjectContainerManager(container, () => !this.lock?.isLockOwner);
    if (!(await qm.needsProject())) return null;
    const branchId = qm.project.projectOwnerId;
    if (!(await branchSwitchOnResource(branchId, tlang`%%project%%`))) return null;

    return await createProjectView(qm);
  }

  meta() {
    return {
      title: tlang`%%project%% Editor`,
      description: 'SoftTech dealer CPQ'
    };
  }

  protected createLock(id: string): ResourceLocker | undefined {
    return new ProjectLock(id, async () => {
      await this.view?.abandonAndClose(true);
      goStaticURL('/projects');
    });
  }
}
