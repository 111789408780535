// eslint-disable-next-line import/named
import { html } from 'lit';
import { QuoteItemType } from '../../api/dealer-api-interface-quote';
import { FieldType } from '../../components/ui/databinding/data-tracker';
import { EventTemplate, Snippet } from '../../components/ui/events';
import { FormInputAssistant } from '../../components/ui/templateresult/form-input-assistant';
import { tlang } from '@softtech/webmodule-components';
import { DevelopmentError } from '../../development-error';
import { quoteItemContentType } from '../data/default-quote-item-content-type';
import { QuoteItemContainer } from '../data/quote-item-container';
import { LineItemDialog, LineItemView } from './line-item-view';
import { QuoteContainerManager } from '../data/quote-container';

import { updateFreehandPrice } from '../../dealer-franchisee/quotes/data/v6/freehand-pricing';
import { customElement } from 'lit/decorators.js';
@customElement('wm-shippinglineitemdialog')
export class ShippingLineItemDialog extends LineItemDialog {
  constructor(quoteManager: QuoteContainerManager, quoteItemContainer: QuoteItemContainer, forceReadonly: boolean) {
    super(quoteManager, quoteItemContainer, forceReadonly);

    if (quoteItemContainer.item.itemType !== QuoteItemType.Basic)
      throw new DevelopmentError(`shipping-line-item-view, Invalid  item type`);
    if (quoteItemContainer.item.quoteItemContentType !== quoteItemContentType.shipping)
      throw new DevelopmentError(`shipping-line-item-view, Invalid  item type`);

    this.dataTracker.addObjectBinding(() => this.getQuoteItem(), 'title', 'title', FieldType.string, false);
    this.dataTracker.addObjectBinding(() => this.getQuoteItem(), 'description', 'description', FieldType.string, false);
    this.dataTracker.addObjectBinding(
      () => this.getQuoteItemPrice(),
      'supplierGrossSingleUnitCost',
      'supplierGrossSingleUnitCost',
      FieldType.money,
      false
    );
    this.dataTracker.addObjectBinding(() => this.getQuoteItem(), 'comment', 'comment', FieldType.string, false);
  }

  protected getTitle(): Snippet {
    return tlang`Shipping`;
  }

  protected async internalSave() {
    await updateFreehandPrice(
      this.quoteManager.quote.supplierId,
      this.quoteManager.quote.quoteOwnerId,
      this.quoteItemContainer.item,
      this.quoteItemContainer.price
    );
    this.ok = true;
  }

  protected bodyTemplate(): EventTemplate {
    //quantity is always 1 on shipping, so using only 2dp on singleUnitCost
    const forms = new FormInputAssistant(this.dataTracker, this.forceReadonly);
    return html`
      <form class="shipping-item-editor-container form-one-col">
        <div class="row">
          <div class="form-column">
            ${forms.textRequired('title', tlang`Title`, 120)} ${forms.text('description', tlang`Description`, 120)}
            ${forms.money('supplierGrossSingleUnitCost', tlang`Cost`)} ${forms.note('comment', 'Notes', 500)}
          </div>
        </div>
      </form>
    `;
  }
}

//base class to manage the intricacies of quoteitems that may be v6 or otherwise
@customElement('wm-shippinglineitemview')
export class ShippingLineItemView extends LineItemView {
  public async executeModalEditDialog(): Promise<void> {
    if (!this.quoteItemContainer) return;
    const dlg = new ShippingLineItemDialog(this.quoteManager, this.quoteItemContainer, this.quoteManager.isReadonly());
    await dlg.showModal();
    if (dlg.ok) {
      //price and item has been updated by modal
      this.quoteItemContainer = await this.quoteManager.saveAndUpdateQuoteItemWithIndicator(
        this.quoteItemContainer,
        ''
      );
    }
  }

  public async prepareForSave(): Promise<void> {
    //there is nothing to do. this wont ever be called as a shipping line item is not a pagecontrol
  }
}
