import { customElement } from 'lit/decorators.js';
import { tlang } from '@softtech/webmodule-components';
import { NullPromise } from '../../null-promise';
import {
  ClientLock,
  clientContainerManagerFactory,
  createClientView,
  getClientContainer
} from '../clients/client-ui-adaptor';
import { AppResourcePage } from './app-resource-base';
import { ModalViewBase } from '../../components/ui/data-entry-screen-base';
import { ResourceLocker } from '../common/resource-lock';
import { goStaticURL } from '../../components/ui/resource-resolver';
import { isEmptyOrSpace } from '../../components/ui/helper-functions';

@customElement('app-client')
export class AppClientPage extends AppResourcePage {
  async redirectPath(): NullPromise<string> {
    return '/clients';
  }

  async createModalEditor(id: string): NullPromise<ModalViewBase> {
    const container = getClientContainer(id);

    if (isEmptyOrSpace(id)) id = container.clientId;
    const qm = clientContainerManagerFactory(container, () => !this.lock?.isLockOwner);
    await qm.needsClient();
    return await createClientView(qm);
  }

  meta() {
    return {
      title: tlang`%%client%% Editor`,
      description: 'SoftTech dealer CPQ'
    };
  }

  protected createLock(id: string): ResourceLocker | undefined {
    return new ClientLock(id, async () => {
      await this.view?.abandonAndClose(true);
      goStaticURL('/clients');
    });
  }
}
