import {
  Frame,
  FrameAttribute,
  FrameAttributeGroup,
  FrameAttributeGroupType,
  FrameBuyInItem
} from '@softtech/webmodule-data-contracts';
import { html, TemplateResult } from 'lit';
import { firstValidString } from '../components/ui/helper-functions';
import { supplierQuoteItemContentType } from '../quotes/data/supplier-quote-item-content-type';

export function getBuyInResourceReference(frameBuyInItem: FrameBuyInItem) {
  const type: string =
    frameBuyInItem.resourceType == supplierQuoteItemContentType.CID_FRAM
      ? `FRAM`
      : frameBuyInItem.resourceType == supplierQuoteItemContentType.CID_COMP
        ? `COMP`
        : frameBuyInItem.resourceType == supplierQuoteItemContentType.CID_EXTN
          ? `EXTN`
          : frameBuyInItem.resourceType == supplierQuoteItemContentType.CID_FILL
            ? `FILL}`
            : frameBuyInItem.resourceType == supplierQuoteItemContentType.CID_IGU
              ? `IGU`
              : frameBuyInItem.resourceType == supplierQuoteItemContentType.CID_FREEHAND
                ? `FREEHAND`
                : frameBuyInItem.resourceType == supplierQuoteItemContentType.CID_LOUVRE
                  ? `LOUVRE`
                  : `UNK`;

  return `[${type}:${frameBuyInItem.extraDetails?.['SuppCode'] ?? ''}:${frameBuyInItem.code}]`;
}

export function getBuyInDetailsTemplate(frameBuyInItem: FrameBuyInItem, heightFirst: boolean | null) {
  const details = frameBuyInItem.extraDetails;
  const empty = ``;

  if (!details) return empty;

  switch (frameBuyInItem.resourceType) {
    case supplierQuoteItemContentType.CID_IGU:
    case supplierQuoteItemContentType.CID_FILL:
      return heightFirst
        ? `${details['HeightDisplayValue'] ?? empty} (H) x ${details['WidthDisplayValue'] ?? empty} (W)`
        : `${details['WidthDisplayValue'] ?? empty} (W) x ${details['HeightDisplayValue'] ?? empty} (H)`;
    case supplierQuoteItemContentType.CID_EXTN:
      return `${details['LengthDisplayValue']}` ?? empty;
    default:
      return `Unknown Type`;
  }
}

export function getFrameDetailsTemplate(frame: Frame, isReports = true): TemplateResult {
  const attributeTemplate = (a: FrameAttribute) => {
    const visible = (isReports && !a.hideFromReport) || (!isReports && !a.hideFromUI);
    return visible
      ? html` <li>
          <span class="attribute-label">${a.description}</span>
          <span class="attribute-value">${firstValidString(a.displayValue, a.value)}</span>
        </li>`
      : html``;
  };

  const groupHasAttributes = (g: FrameAttributeGroup) => {
    return g.attributes.filter(a => (isReports ? !a.hideFromReport : !a.hideFromUI)).length > 0;
  };
  const visibleGroups = (g: FrameAttributeGroup[]) => {
    return isReports
      ? g.filter(g => g.groupType !== FrameAttributeGroupType.Quote && groupHasAttributes(g) && !g.hideFromReport)
      : g.filter(g => g.groupType !== FrameAttributeGroupType.Quote && groupHasAttributes(g) && !g.hideFromUI);
  };
  const groupTemplate = (g: FrameAttributeGroup): TemplateResult => {
    const attributes = g.attributes.filter(a => (isReports && !a.hideFromReport) || (!isReports && !a.hideFromUI));
    if (attributes.length === 0) return html``;
    //we want to fit 3 columns per row??
    return html`
      <div class="col-4 extended-options-block">
        <h5>${g.description}</h5>
        <ul>
          ${attributes.map(a => attributeTemplate(a))}
        </ul>
      </div>
    `;
  };
  const frameDataTemplate = (frameData: Frame) => {
    const stackHasAttributes = (nf: Frame): boolean => {
      const processFrame = (frame: Frame): boolean => {
        return (
          frame.attributeGroups.some(g => groupHasAttributes(g)) || frame.nestedFrames.some(nf => processFrame(nf))
        );
      };
      return processFrame(nf);
    };

    //do not represent frames without useful information
    const frames = frameData.nestedFrames.filter(nf => stackHasAttributes(nf));
    
    const nestedFrameTemplate = () => {
      if (frames.length === 0) return html``;
      return html`
        <div class="extended-options-level1">
          ${frames.map(
            f =>
              html` <h4 class="extended-options-level-header">${f.description}</h4>
                <div class="extended-options-level2">${frameDataTemplate(f)}</div>`
          )}
        </div>
        </div>`;
    };

    const groups = visibleGroups(frameData.attributeGroups);
    //the layout here is to create a row element and fill it with columns spanning 3 cols per width
    //and then for nested embed and continue
    return html`
      <div class="row">${groups.map(g => groupTemplate(g))}</div>
      ${nestedFrameTemplate()}
    `;
  };

  return html`${frameDataTemplate(frame)}`;
}
