// eslint-disable-next-line import/named
import { html } from 'lit';
import { QuoteItemType } from '../../api/dealer-api-interface-quote';
import { money } from '../../components/currency-formatter';
import { DynamicValueBinder, FieldType } from '../../components/ui/databinding/data-tracker';
import { EventTemplate, Snippet } from '../../components/ui/events';
import { FormInputAssistant } from '../../components/ui/templateresult/form-input-assistant';
import { tlang } from '@softtech/webmodule-components';
import { DevelopmentError } from '../../development-error';
import { quoteItemContentType } from '../data/default-quote-item-content-type';
import { QuoteItemContainer } from '../data/quote-item-container';
import { newGuid } from '../../api/guid';
import { LineItemDialog, LineItemView } from './line-item-view';
import { QuoteContainerManager } from '../data/quote-container';
import { updateDealerFreehandPrice } from '../data/v6/helper-functions';
import { userDataStore } from '../../dealer-franchisee/common/current-user-data-store';
import { customElement } from 'lit/decorators.js';

@customElement('wm-freehandlineitemdialog')
export class FreehandLineItemDialog extends LineItemDialog {
  interval: NodeJS.Timeout | null = null;

  constructor(quoteManager: QuoteContainerManager, quoteItemContainer: QuoteItemContainer, forceReadonly: boolean) {
    super(quoteManager, quoteItemContainer, forceReadonly);

    if (quoteItemContainer.item.itemType !== QuoteItemType.Basic)
      throw new DevelopmentError(`freehand-line-item-view, ${quoteItemContainer.item.description} is not a freehand`);
    if (quoteItemContainer.item.quoteItemContentType !== quoteItemContentType.freehand)
      throw new DevelopmentError(`quote item ${quoteItemContainer.item.title} is not a freehand item`);

    const item = this.quoteItemContainer.item;
    const price = this.quoteItemContainer.price;

    this.dataTracker.addObjectBinding(() => item, 'title', 'title', FieldType.string, false);
    this.dataTracker.addObjectBinding(() => item, 'description', 'description', FieldType.string, false);
    this.dataTracker.addObjectBinding(() => item, 'quantity', 'quantity', FieldType.float, false);

    this.dataTracker.addObjectBinding(() => item, 'comment', 'comment', FieldType.string, false);
    this.dataTracker.addObjectBinding(() => price, 'singleUnitCost', 'singleUnitCost', FieldType.money, false);
    this.dataTracker.addBinding(
      new DynamicValueBinder(
        FieldType.money,
        false,
        () => {
          try {
            const qty = (this.dataTracker.getEditorValue('quantity') as number) ?? 0;
            const unitCost = (this.dataTracker.getEditorValue('singleUnitCost') as number) ?? 0;
            return money(qty * unitCost, 2);
          } catch {
            return price.quantityCost;
          }
        },
        () => {
          console.log('wont get here');
        },
        () => {
          return true;
        }
      ),
      'quantityCost',
      'quantityCost',
      FieldType.money,
      false
    );

    if (userDataStore.getFranchiseeConfig().franchiseeConfiguration.allowNonTaxableItems) {
      this.dataTracker.addObjectBinding(() => price, 'isTaxableItem', 'isTaxableItem', FieldType.boolean, false);
    }

    const triggerEvent = () => {
      const qtyCost = this.dataTracker.getEditorValue('quantityCost') as number;
      const unitCost = this.dataTracker.getEditorValue('singleUnitCost') as number;
      const qty = this.dataTracker.getEditorValue('quantity') as number;
      if (money(qty * unitCost, 2) !== qtyCost) {
        this.requestUpdate(); //no wait
      }
    };

    $(this.ui).on('keyup', 'input', (e: Event) => {
      const elem = e.target as HTMLInputElement;
      const event = e as KeyboardEvent;
      console.log(`${elem.id} ${elem.value}`);
      if (
        elem.id == this.dataTracker.binder.field('quantity') ||
        elem.id == this.dataTracker.binder.field('singleUnitCost')
      )
        if (event.code === 'Enter') {
          triggerEvent(); //this.timedTrigger.triggerEarly(e);
        }
    });
    $(this.ui).on('blur', 'input', (e: Event) => {
      const elem = e.target as HTMLInputElement;
      if (
        elem.id == this.dataTracker.binder.field('quantity') ||
        elem.id == this.dataTracker.binder.field('singleUnitCost')
      )
        triggerEvent(); //this.timedTrigger.triggerEarly(e);
    });
  }

  protected getTitle(): Snippet {
    return tlang`%%freehand%%`;
  }

  protected async internalSave() {
    updateDealerFreehandPrice(this.quoteItemContainer.item.quantity, this.quoteItemContainer.price);
    this.ok = true;
  }

  protected async getValidationErrors(): Promise<string[]> {
    const errors = await super.getValidationErrors();

    const quantity = this.dataBinding.getFloat('quantity') ?? 0;
    if (quantity <= 0) {
      errors.push(tlang`Please provide a quantity greater than 0`);
    }

    return errors;
  }

  protected bodyTemplate(): EventTemplate {
    const forms = new FormInputAssistant(this.dataTracker, this.forceReadonly);
    const toggleFreehandItemTax = (e: CustomEvent<boolean>) => {
      e.stopImmediatePropagation();
      this.dataBinding.setValue('isTaxableItem', `${e.detail}`);
    };
    const taxableItemHtml = userDataStore.getFranchiseeConfig().franchiseeConfiguration.allowNonTaxableItems
      ? html` <bs-form-checkbox
          data-id=${this.dataBinding.field('isTaxableItem')}
          data-label=${'Is Taxable Item'}
          .checked=${this.quoteItemContainer.price.isTaxableItem}
          @checkbox-changed=${toggleFreehandItemTax}
          .display=${'checkbox'}
        >
        </bs-form-checkbox>`
      : html``;

    return html`
      <form class="freehand-item-editor-container form-one-col">
        <div class="row">
          <div class="form-column">
            ${forms.textRequired('title', tlang`Title`, 120)} ${forms.text('description', tlang`Description`, 120)}
            ${forms.float('quantity')} ${forms.money('singleUnitCost', tlang`Unit Cost`, 2)}
            ${forms.moneyReadonly('quantityCost', tlang`Total Cost`)} ${taxableItemHtml}
            ${forms.note('comment', 'Notes', 500)}
          </div>
        </div>
      </form>
    `;
  }
}

//base class to manage the intricacies of quoteitems that may be v6 or otherwise
@customElement('wm-freehandlineitemview')
export class FreehandLineItemView extends LineItemView {
  //this should be called immediately after the constructor and if the return result is not
  //true then this page is not valid, and should be assumed to be cancelled
  public async prepareEditor(): Promise<void> {
    if (this.quoteItemContainer) {
      this._readyToEdit = true;
    } else {
      this._readyToEdit = await this.createQuoteItem();
    }
  }

  async createQuoteItem(): Promise<boolean> {
    const result = await this.quoteManager.createQuoteItem({
      isRestrictedToPowerUser: false,
      id: newGuid(),
      title: tlang`New %%freehand%%`,
      description: '',
      quantity: 1,
      comment: '',
      quoteItemContentType: quoteItemContentType.freehand,
      externalProvider: null,
      buyInData: null,
      price: {
        sourceData: {},
        singleUnitCost: 0,
        supplierGrossSingleUnitCost: 0,
        supplierNettSingleUnitCost: 0,
        supplierPriceAdjustment: 0
      },
      thumbnail: null,
      isTaxableItem: true
    });
    if (result) {
      this.quoteItemContainer = result;
      return true;
    }
    return false;
  }

  public async executeModalEditDialog(): Promise<void> {
    if (!this.quoteItemContainer) return;
    const dlg = new FreehandLineItemDialog(this.quoteManager, this.quoteItemContainer, this.quoteManager.isReadonly());
    await dlg.showModal();
    if (dlg.ok) {
      this.quoteItemContainer = await this.quoteManager.saveAndUpdateQuoteItemWithIndicator(
        this.quoteItemContainer,
        ''
      );
    }
  }
}
