import { DevelopmentError } from '../../development-error';

export type InformationListener = (information: string) => Promise<void>;

export class InformationDispatcher {
  private _information = '';
  private listeners: InformationListener[] = [];
  public async setInformation(information: string) {
    this._information = information;
    for (let i = 0; i < this.listeners.length; i++) await this.listeners[i](this._information);
  }
  get information(): string {
    return this._information;
  }
  clear() {
    this.setInformation('');
  }
  public addListener(aListener: InformationListener) {
    const index = this.listeners.findIndex(x => x === aListener);
    if (index !== -1) throw new DevelopmentError('Cannot add listener twice');
    this.listeners.push(aListener);
  }
  public removeListener(aListener: InformationListener) {
    const index = this.listeners.findIndex(x => x === aListener);
    if (index === -1) throw new DevelopmentError('Cannot remove listener that is not in the list');
  }
}
