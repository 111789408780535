import { customElement, property } from 'lit/decorators.js';
import { LitElementBase } from '../../../components/litelement-base';
import { TemplateResult, html } from 'lit-html';
import { emptyGuid } from '../../../api/guid';
import { isEmptyOrSpace } from '../../../components/ui/helper-functions';
import { Branch } from '../../../api/dealer-api-interface-franchisee';
import { TenantLoginPublicInfo } from '../../../api/dealer-api-interface-user';

export interface DateRange {
  id: string;
  label: string;
  startDate: Date;
  endDate: Date;
}

@customElement('wm-dashboard-filter')
export class DashboardFilter extends LitElementBase {
  @property() public dates?: DateRange[];
  @property() public branches?: Branch[];
  @property() public users?: TenantLoginPublicInfo[];

  @property() public selectedDate?: DateRange;
  @property() public selectedBranchId?: string;
  @property() public selectedUserId?: string;

  protected dateChangeEvent(e: Event) {
    const dateRangeId = (e.currentTarget as HTMLSelectElement).value;
    const dateRange = this.dates?.find(dr => dr.id == dateRangeId);
    this.dispatchCustom('date-changed', {
      date: dateRange
    });
  }

  protected branchChangeEvent(e: Event) {
    const branchId = (e.currentTarget as HTMLSelectElement).value;
    this.dispatchCustom('branch-changed', {
      branchId: branchId
    });
  }

  protected userChangeEvent(e: Event) {
    const userId = (e.currentTarget as HTMLSelectElement).value;
    this.dispatchCustom('user-changed', {
      userId: userId
    });
  }

  protected sendResetFilterEvent(_e: Event) {
    this.dispatchCustom('reset-filters', {});
  }

  protected render(): TemplateResult {
    const dateRangeTemplate = (dateRangeId: string) =>
      this.dates?.map(d => {
        return html`<option ?selected=${d.id === dateRangeId} value=${d.id}>${d.label}</option>`;
      });

    const branchTemplate = (branchId: string) =>
      this.branches?.map(b => {
        return html`<option ?selected=${b.id === branchId} value=${b.id}>${b.name}</option>`;
      });
    const allBranchesOption =
      this.branches && this.branches.length > 1
        ? html`<option value=${emptyGuid} ?selected=${this.selectedBranchId === emptyGuid}>All Branches</option>`
        : html``;

    const userTemplate = (userId: string) =>
      this.users?.map(u => {
        return html`<option ?selected=${u.id === userId} value=${u.id}>${u.friendlyName}</option>`;
      });
    const isUserDisabled = isEmptyOrSpace(this.selectedBranchId);

    return html`
      <div class="page-dashboard-filter">
        <label>Filter Data</label>
        <select @change=${this.dateChangeEvent} id="selectedDateId" title="selectedDateTitle" class="form-select">
          ${dateRangeTemplate(this.selectedDate?.id ?? emptyGuid)}
        </select>
        <select @change=${this.branchChangeEvent} id="selectedBranchId" title="selectedBranchTitle" class="form-select">
          ${allBranchesOption} ${branchTemplate(this.selectedBranchId ?? emptyGuid)}
        </select>
        <select @change=${this.userChangeEvent} id="selectedUserId" ?disabled=${isUserDisabled} class="form-select">
          <option value=${emptyGuid}>All Employees</option>
          ${userTemplate(this.selectedUserId ?? emptyGuid)}
        </select>
        <button class="btn-primary filter-reset" @click=${this.sendResetFilterEvent}>
          <icon-refresh />
        </button>
      </div>
    `;
  }
}
