import { money } from '../../components/currency-formatter';

//replicated from the server
export interface IDefaultPricingStrategy {
  GetMarginPriceWithAdjustment(basePrice: number, marginPercentage: number, priceAdjustment: number | null): number;
}

export class DefaultPricingStrategy implements IDefaultPricingStrategy {
  /// <summary>
  /// this is a reusable core financial calculation system that can be used by all systems that will follow this form of
  /// financial rules
  /// the purpose of this routine is to calculate a sale price as a basePrice with a margin added.
  /// so if the margin is 20% and the base price is $80 then the MarginPrice is 100
  /// if a price adjustment is passed in then this is added as an on top value
  /// </summary>
  /// <param name="basePrice">the cost price</param>
  /// <param name="marginPercentage">the percentage as a number ie 20 for 20%</param>
  /// <param name="priceAdjustment">optional fixed figure to add (may be negative)</param>
  /// <returns></returns>
  public GetMarginPriceWithAdjustment(
    basePrice: number,
    marginPercentage: number,
    priceAdjustment: number | null = null
  ): number {
    return money(money((basePrice / (100 - marginPercentage)) * 100) + (priceAdjustment ?? 0), 2);
  }
}

export class PricingStrategy {
  static default: IDefaultPricingStrategy = new DefaultPricingStrategy();
}
