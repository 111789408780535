import { tlang } from '@softtech/webmodule-components';
import { EventSnippet } from './events';

import { WaitPatiently } from './modal-spinner';
import { TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';

export function waitPatientlySaving(title?: EventSnippet, message?: EventSnippet, footerTemplate?: TemplateResult) {
  const modal = new WaitPatientlySaving();
  modal.eventTitle = title ?? (() => tlang`Saving`);
  modal.message = message ?? (() => tlang`please wait while we update the data`);
  modal.footer = footerTemplate;
  modal.delayedShow();
  return modal;
}
//a progress window default to run will doing a save to the server
@customElement('wm-waitpatientlysaving')
export class WaitPatientlySaving extends WaitPatiently {
  @property({ type: Object })
  footer?: TemplateResult;

  borderType() {
    return 'basic';
  }
  protected footerTemplate(): TemplateResult | null {
    if (!this.footer) return null;
    return this.footer;
  }
}

export interface ISaveNotifier {
  done: () => Promise<void>;
}

export function saveIndicator(title?: EventSnippet, message?: EventSnippet): ISaveNotifier {
  const saving = waitPatientlySaving(title, message);
  return {
    done: async () => await saving.hideModal()
  };
}
