import { getApiFactory } from '../../api/api-injector';
import { constructAsync } from '../../async-constructor';
import { DevelopmentError } from '../../development-error';
import { QuoteContainer, QuoteContainerManager } from '../../quotes/data/quote-container';
import { currentQuoteSupplierProvider } from '../../quotes/data/quoteSupplierProvider';
import { QuoteView, QuoteViewOptions } from '../../quotes/views/quote-view';
import { FranchiseeQuoteContainer } from './data/franchisee-quote-container';
import { FranchiseeQuoteContainerManager } from './data/franchisee-quote-manager';
import { FranchiseeV6QuoteView } from './views/v6/franchisee-v6-quote-view';

import { waitPatiently } from '../../components/ui/modal-spinner';
import { tlang } from '@softtech/webmodule-components';
import { cache } from '../cache/cache-registry';
import { userDataStore } from '../common/current-user-data-store';
import { getQuoteTitle } from '../../quotes/data/quote-helper-functions';
import { ResourceLocker } from '../common/resource-lock';
import { LockResourceResult } from '../../components/resource-lock-info';

export class QuoteLock extends ResourceLocker {
  constructor(quoteId: string, lockRevokedEvent: () => Promise<void>) {
    super(quoteId, 'quote', 'quote', lockRevokedEvent);
  }
}
export function getQuoteContainer(id: string): QuoteContainer {
  return new FranchiseeQuoteContainer(id, null, null, null, null, null, null, null, null, null);
}
export function getQuoteContainerManager(
  id: string | QuoteContainer,
  lockInfo?: LockResourceResult
): QuoteContainerManager {
  const container = id instanceof QuoteContainer ? id : getQuoteContainer(id);
  const manager = new FranchiseeQuoteContainerManager(
    container,
    getApiFactory().quote(),
    getApiFactory().blob(),
    getApiFactory().franchisee()
  );

  manager.lockInfo = lockInfo;
  return manager;
}

export async function quoteViewFactory(options: QuoteViewOptions): Promise<QuoteView> {
  await options.quoteContainerManager.needsQuote();
  if (options.quoteContainerManager.isV6) return await constructAsync(new FranchiseeV6QuoteView(options));
  else throw new DevelopmentError(`unsupported provider ${currentQuoteSupplierProvider}`);
}
export async function createQuoteView(containerManager: QuoteContainerManager): Promise<QuoteView> {
  let quoteEditor: QuoteView | null = null;

  const waiting = waitPatiently(
    () => tlang`Opening %%quote%%`,
    () => tlang`please wait while we initialize the %%quote%%`
  );
  try {
    quoteEditor = await quoteViewFactory({
      title: getQuoteTitle, // not used in subclasses
      quoteContainerManager: containerManager,
      customerCache: cache().client,
      userCache: cache().userProfile,
      ownerId: async () => userDataStore.defaultBranch.id
    });
    await waiting.hideModal();
    return quoteEditor;
  } finally {
    await waiting.hideModal();
  }
}
