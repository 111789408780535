import { PropertyValueMap, html } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { getApiFactory } from '../../api/api-injector';
import { getApiToken, tlang } from '@softtech/webmodule-components';
import { information } from '../../components/ui/modal-option';
import { LitElementBase } from '../../components/litelement-base';
import { dealerTokenProvider } from '../../api/dealer-api-communications';
import {
  ResultConversationUploadAttachment,
  ViewConversationAttachment
} from '../../api/dealer-api-interface-franchisee';
import { clone, compare } from '../../components/clone';
import { getInternalId } from '../../components/ui/databinding/databinding';

interface UploadInfo {
  uuid: string;
  filename: string;
  chunked?: boolean;
}
interface DropZoneFileObject {
  publicUrl?: string;
  size?: number;
  accepted?: boolean;
  uuid?: string;
  name: string;
  xhr?: XMLHttpRequest;
  previewElement: HTMLElement;
  previewTemplate: HTMLElement;
  status?: string;
  upload?: UploadInfo;
}
@customElement('wmview-conversation-attachments')
export class WebModuleConversationEntryDropZone extends LitElementBase {
  removeDropZoneFile(name: string) {
    const file = this.dropzoneFileObjects.find(x => x.name === name);
    if (file) {
      this.dropzone?.removeFile(file);
    }
  }
  private cleanUpProcessActivated = false;
  dropzone: any;
  @property() caption = tlang`Attachments`;
  @property() conversationId = '';
  @property()
  private _conversationEntryId = '';
  private _existingFileProcess = 0;
  private get isInternalUpdate() {
    return this._existingFileProcess > 0;
  }
  public get conversationEntryId() {
    return this._conversationEntryId;
  }
  public set conversationEntryId(value) {
    if (value !== this._conversationEntryId) {
      this._conversationEntryId = value;
      this.resetAttachments([]);
    }
  }
  private log(s: any) {
    console.log(`ATTACHMENT_DROP_${this._internalId}:${s}`);
  }
  private _internalId = getInternalId();

  @property()
  private _existingFiles: ViewConversationAttachment[] = [];
  public get existingFiles(): ViewConversationAttachment[] {
    return this._existingFiles;
  }
  public set existingFiles(value: ViewConversationAttachment[]) {
    this.log('existingFiles called');
    if (!compare(value, this.existingFiles)) {
      this.log('existingFiles modified');
      this.resetAttachments(value);
    }
  }
  @property() maxFiles = 20;
  @property() trimTopWhenFileExceeded = true;
  @property() allowDownloadLinks = false;
  @query('#form-upload')
  form?: HTMLFormElement;
  @query('#clickable')
  clickable?: HTMLDivElement;
  @query('#previews')
  previews?: HTMLDivElement;
  chunkRetryCount: number;
  chunkSizeInBytes: number;
  uploadUrl: string;
  uploadLimitInMB: any;
  parallelUploadCount: any;

  private dropzoneFileObjects: DropZoneFileObject[] = [];
  async activateCleanupProcess() {
    try {
      if (!this.cleanUpProcessActivated) {
        this.cleanUpProcessActivated = true;
        await getApiFactory().franchisee().queueCleanupConversationAttachments({
          conversationEntryId: this.conversationEntryId,
          conversationId: this.conversationId
        });
      }
    } catch (e) {
      this.cleanUpProcessActivated = false;
      console.log(e);
    }
  }

  public get allDropZoneFiles() {
    return this.dropzoneFileObjects;
  }
  public get allFileNames() {
    return this.existingFiles.map(x => x.filename);
  }
  public get allFilesAsInputs() {
    return this.existingFiles.map(x => {
      return { filename: x.filename };
    });
  }
  constructor() {
    super();

    this.log('created');

    this.chunkRetryCount = 3;
    this.chunkSizeInBytes = 4 * 1024 * 1024;
    this.uploadUrl = getApiFactory().franchisee().getConversationAttachmentUploadURL;
    this.uploadLimitInMB = 200;
    this.parallelUploadCount = 3;
  }
  protected eventClick = (e: Event) => {
    if (!this.allowDownloadLinks) {
      //e.preventDefault();
      if ((e.target as HTMLElement).hasAttribute('data-dz-name')) {
        const filename = (e.target as HTMLElement).innerText;
        this.dispatchCustom('attachmentclicked', {
          filename: filename
        });
      }
    }
  };
  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('click', this.eventClick);
    if (this.dropzone) {
      this.log('enable dropzone');
      this.setDropZoneState();
    }
  }
  private setDropZoneState() {
    if (!this.isReadonly) this.dropzone?.enable();
    else this.dropzone?.disable();
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();

    if (this.dropzone) {
      this.dropzone.disable();
      this.log('disable dropzone');
    }
  }
  protected previewTemplate() {
    const removeLink = this.isReadonly
      ? `<span class="d-none" data-dz-remove>&times;</span>`
      : `<span data-dz-remove>&times;</span>`;
    if (this.allowDownloadLinks)
      return `
                <div class="dz-preview dz-file-preview">
                    <div class="dz-details">
                        <div class="dz-filename">
                            ${removeLink}
                            <a href="#" target="_blank" class="file-link">
                                <i class="fa-regular fa-file-lines bi"></i>
                                <span data-dz-name class="dz-filename-text"></span
                            ></a>
                        </div>
                    </div>
                    <div
                        class="dz-progress progress progress-striped active d-none"
                        role="progressbar"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        aria-valuenow="0"
                        style="width:100%;"
                    >
                        <span
                            class="dz-upload progress-bar progress-bar-success"
                            style="width:0%;"
                            data-dz-uploadprogress
                        ></span>
                    </div>
                    <div class="dz-error-message">
                        <span data-dz-errormessage></span>
                    </div>
                </div>
            `;
    //add html to read and edit.. ie html`
    return `
            <div class="dz-preview dz-file-preview">
                <div class="dz-details">
                    <div class="dz-filename">
                        ${removeLink}
                        <i class="fa-regular fa-file-lines bi"></i>
                        <span data-dz-name class="dz-filename-text"></span>
                    </div>
                </div>
                <div
                    class="dz-progress progress progress-striped active d-none"
                    role="progressbar"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    aria-valuenow="0"
                    style="width:100%;"
                >
                    <span
                        class="dz-upload progress-bar progress-bar-success"
                        style="width:0%;"
                        data-dz-uploadprogress
                    ></span>
                </div>
                <div class="dz-error-message">
                    <span data-dz-errormessage></span>
                </div>
            </div>
        `;
  }

  protected eventChunksUploadComplete = (file: DropZoneFileObject, done) => {
    if (file && file.xhr && file.xhr.response) {
      const response = JSON.parse(file.xhr.response) as ResultConversationUploadAttachment;
      if (response && response.success) {
        file.publicUrl = response.files[0].publicUrl;
        this.addDropZoneFileObject(file, response.files[0]);
      }
      this.log('chunks completed ' + file.name);
    }
    done(); // calls uploadSuccess, but response = null
  };
  protected eventUploadError = (file, response, xhr) => {
    this.log('uploaderror');
    this.dropzone.removeFile(file);
    this.internalUploadError(file, response, xhr);
  };
  protected async handleUploadCancelled(message) {
    if (message !== 'Upload cancelled.') await information(message);
    else return;
  }

  protected async internalUploadError(file, response, _xhr) {
    file.previewElement?.remove();
    if (response && response.message) {
      await information(response.message);
    } else if (response) {
      await this.handleUploadCancelled(response);
    } else {
      await information(tlang`Could not upload file`);
    }
  }
  protected eventUploadSuccess = (file: DropZoneFileObject, response: ResultConversationUploadAttachment) => {
    this.eventUploadFinished(file);
    if (response && response.files && response.files.length > 0) {
      // non-chunked uploads have a response
      this.bindHref(file, response.files[0]);
      this.log('fileupload success' + file.name);
    }
  };

  protected eventFileRemoved = (file: DropZoneFileObject, _response) => {
    this._existingFiles = this._existingFiles.filter(x => x.filename !== file.name);
    this.dropzoneFileObjects = this.dropzoneFileObjects.filter(x => x.name !== file.name);
    $(file.previewElement).remove();
    this.dispatchUpdateEvent(); //
  };

  dispatchUpdateEvent() {
    if (this.isInternalUpdate) return;
    this.log('dispatch-changed');
    this.dispatchCustom('changed', {
      isUploading: this.isUploading,
      files: this.existingFiles
    });
  }
  protected eventUploadFinished = file => {
    this.log(`File Upload Complete`);

    if (file && file.previewElement) {
      (file.previewElement.querySelector('.dz-progress') as HTMLDivElement)?.classList.add('d-none');

      (file.previewElement as HTMLDivElement)
        .querySelectorAll('[data-dz-remove]')
        .forEach(x => x.classList.remove('d-none'));
    }
  };

  protected eventAcceptFile = (file, done) => {
    if (!this.trimTopWhenFileExceeded)
      if (this.allFileNames.length >= this.maxFiles)
        return done(
          tlang`Max upload count ${this.maxFiles} has been reached. 
                
                Please remove a file before adding a new file.`
        );

    const filelist = this.allFileNames;
    for (let i = 0; i < filelist.length; i++) {
      if (filelist[i] === file.name) {
        this.log(`duplicate upload ${file.name}`);
        this.removeDropZoneFile(file.name);

        //                return done(tlang`You have already uploaded this file.`); // reject
      }
    }
    (file.previewElement.querySelector('.dz-progress') as HTMLDivElement).classList.remove('d-none');
    return done(); // accept
  };
  @state()
  private _isUploading = false;
  public get isUploading() {
    return this._isUploading;
  }
  public set isUploading(value) {
    if (value !== this._isUploading) {
      this._isUploading = value;
      if (!this.isInternalUpdate) {
        if (value) this.activateCleanupProcess();
      }
    }
  }
  protected onReadonlyChanged() {
    this.querySelectorAll('[data-dz-remove]').forEach((x: Element) => {
      if (!this.isReadonly) (x as HTMLElement).classList.remove('d-none');
      else (x as HTMLElement).classList.add('d-none');
    });
    this.setDropZoneState();
  }
  protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    try {
      this.log('first updating');
      const urlCreator = () =>
        this.uploadUrl + `?conversationId=${this.conversationId}&conversationEntryId=${this.conversationEntryId}`;
      this.log('created dropzone');
      this.dropzone = new globalThis.Dropzone(this, {
        url: urlCreator(),
        maxFilesize: this.uploadLimitInMB,
        previewTemplate: this.previewTemplate(),
        clickable: this.clickable,
        previewsContainer: this.previews,
        maxFiles: 30,
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          Authorization: `bearer ${getApiToken()}`,
          'Authorization-Client': dealerTokenProvider()
        },
        //chunked uploads disabled
        chunking: true,
        chunkSize: this.chunkSizeInBytes,
        chunksUploaded: this.eventChunksUploadComplete,
        //events
        error: this.eventUploadError,
        processing: function () {
          this.options.url = urlCreator();
        },
        accept: this.eventAcceptFile,
        success: this.eventUploadSuccess,
        removedfile: this.eventFileRemoved
      });

      this.dropzone.on('addedfile', (file: DropZoneFileObject) => {
        if (file.upload) return;
        const filename = file.name;
        const attachment = this.existingFiles?.find(x => x.filename === filename);
        if (attachment) {
          this.bindHref(file, attachment);
          this.addDropZoneFileObject(file, attachment);
        } else this.addDropZoneFileObject(file);
      });

      this.dropzone.on('queuecomplete', () => {
        this.log('queue completed');
        const accepted = this.allFileNames;
        const count = accepted.length - this.maxFiles;
        if (count > 0 && this.trimTopWhenFileExceeded) {
          for (let i = 0; i < count; i++) {
            this.removeDropZoneFile(accepted[i]);
          }
        }

        this.isUploading = false;
        this.dispatchUpdateEvent();
        this.log('dropzone all complete');
      });
      this.dropzone.on('sending', () => {
        this.isUploading = true;

        this.log('dropzone sending');
      });
      this.dropzone.on('sendingmultiple', () => {
        this.isUploading = true;
        this.log('dropzone sendingmultiple');
      });
      this.dropzone.on('processing', () => {
        this.isUploading = true;
        this.log('dropzone processing');
      });
      this.dropzone.on('success', (file: DropZoneFileObject) => {
        if (file && file.xhr && file.xhr.response) {
          const response = JSON.parse(file.xhr.response) as ResultConversationUploadAttachment;
          if (response && response.success) {
            file.publicUrl = response.files[0].publicUrl;
            this.addDropZoneFileObject(file, response.files[0]);
          }
          this.log('chunks completed ' + file.name);
        }
        //this.addDropZoneFileObject(file);

        this.log('dropzone uploaded ' + file.name);
      });

      this.log('rebuilding existing file list');
      this.rebuildExistingFilesList();
      this.setDropZoneState();
    } catch (e) {
      console.log(e);
    }
  }

  bindHref(file: DropZoneFileObject, attachment?: ViewConversationAttachment) {
    const elem = file.previewElement.querySelector('.file-link');
    const href = elem as HTMLAnchorElement;

    if (attachment) {
      file.publicUrl = attachment.publicUrl;
      if (href) href.href = attachment.publicUrl;
    }
  }
  private addDropZoneFileObject(file: DropZoneFileObject, attachment?: ViewConversationAttachment) {
    this.dropzoneFileObjects = [
      ...this.dropzoneFileObjects.filter(x => x.name !== file.name),
      file as DropZoneFileObject
    ];
    if (!this.existingFiles.find(x => x.filename === file.name)) {
      this._existingFiles.push(
        attachment ?? {
          embedded: false,
          filename: file.name,
          publicUrl: file.publicUrl ?? '',
          size: file.size ?? 0,
          virtualPath: ''
        }
      );
    }
  }

  private rebuildExistingFilesList() {
    this._existingFileProcess++;
    try {
      this.dropzoneFileObjects = [];
      this.existingFiles?.forEach(attachment => {
        this.dropzone?.displayExistingFile(
          {
            name: attachment.filename
          },
          '/img/undraw_rocket.svg'
        );
      });
    } finally {
      this._existingFileProcess--;
    }
  }
  public resetAttachments(attachments: ViewConversationAttachment[]) {
    if (compare(attachments, this.existingFiles)) return;
    this._existingFileProcess++;
    try {
      this.dropzone?.removeAllFiles();
      if (this.previews) this.previews.innerHTML = '';
      this._existingFiles = clone(attachments);
      this.dropzoneFileObjects = [];
      this.rebuildExistingFilesList();
      this.cleanUpProcessActivated = false;
    } finally {
      this._existingFileProcess--;
    }
  }

  protected dropZoneTemplate() {
    const uploadingTemplate = this.isUploading
      ? html`<span class="ms-3 fw-bold text-danger "><i class="fa-solid fa-spinner fa-spin"></i></span>`
      : html``;
    //class="border p-2 conversation-attachments-section border-top-0"
    const clickableClasses = `dz-clickable `;

    return html`<div class="conversation-attachments-container">
      <div class="row conversation-attachmments m-0">
        <div id="clickable" class=${clickableClasses}>
          <h2 class="m-2">${this.caption}${uploadingTemplate}</h2>
          <div class="image-upload-icon m-3">
            <i class="fa fa-solid fa-file-arrow-up"></i>
          </div>
          <div class="upload-button m-2" dz-clickable tabindex="18">Choose Files</div>
        </div>
        <div id="previews" class="dropzone-previews"></div>
      </div>
      <div class="form-group row m-0">
        <div class="text-danger validation-msg">
          <div></div>
        </div>
      </div>
    </div>`;
  }
  protected render(): unknown {
    return this.dropZoneTemplate();
  }
  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }
}
