import { html } from 'lit';
import { EventTemplate, Snippet } from '../../components/ui/events';
import { DevelopmentError } from '../../development-error';
import { tlang } from '@softtech/webmodule-components';
import { QuoteSupplierGlassView } from '../views/quote-supplier-glass-view';
import { isValidV6ConfigVersion, v6Editors } from '../../v6config/v6config';
import { QuoteIGUDataProvider, V6QuoteIGUEditor } from '@softtech/webmodule-data-contracts';
import { customElement } from 'lit/decorators.js';

@customElement('wm-v6quotesupplierglassview')
export class V6QuoteSupplierGlassView extends QuoteSupplierGlassView implements QuoteIGUDataProvider {
  get quoteIGUs(): unknown {
    return this.getQuoteIGUs();
  }
  set quoteIGUs(value: unknown) {
    this.setQuoteIGUs(value);
  }
  v6editor: V6QuoteIGUEditor | null = isValidV6ConfigVersion() ? v6Editors().getQuoteIGUEditorService(this) : null;

  protected get isReadonly() {
    return super.isReadonly || (this.v6editor?.busy ?? true);
  }

  async iguServiceUpdate(): Promise<void> {
    this.requestUpdate();
  }
  get readonly(): boolean {
    return super.readonly || this.v6editor === null || this.forceReadonly?.() || this.quoteManager.isReadonly();
  }
  protected getQuoteIGUs(): unknown {
    return [];
  }
  protected setQuoteIGUs(_items: unknown) {
    throw new DevelopmentError('V6QuoteSupplierGlassView.setQuoteIGUs must be overridden');
  }
  getValidationErrors(): string[] {
    return [];
  }
  async dispose(): Promise<void> {
    await super.dispose();
    this.v6editor?.releaseResources();
    this.v6editor?.ui.remove();
  }
  async prepareForSave(): Promise<void> {
    this.v6editor?.prepareForSave();
  }

  protected template(): EventTemplate {
    const readonlyTemplate = this.forceReadonly()
      ? html` <div class="alert alert-danger" role="alert">
          ${tlang`!!quote-igu!! are readonly when !!quote-item!! are being edited`}
        </div>`
      : html``;
    return this.v6editor !== null
      ? html` ${readonlyTemplate} ${this.v6editor.ui} `
      : html`${tlang`%%supplier%% service not available`}`;
  }
  buttonMenu(): Snippet {
    const addIGU = async (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      await this.v6editor?.addIGU();
    };
    return this.v6editor !== null
      ? html`
          <button class="btn secondary-btn" @click=${addIGU} ?disabled=${this.readonly}>
            ${tlang`Add %%quote-igu%%`}
          </button>
        `
      : html``;
  }
}
