import { tlang } from '@softtech/webmodule-components';
import { Address } from '@softtech/webmodule-data-contracts';
import { html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { googleMapApiKey } from '../../api/google-api';
import { Place } from './maps/place';
import {
  emptyAddress,
  mapTemplate,
  singleLineAddress,
  updateAddress,
  updateAddressWithAddress
} from './maps/map-helpers';
import { DataBinding } from './databinding/databinding';
import { DataTracker, FieldType } from './databinding/data-tracker';
import { FormInputAssistant } from './templateresult/form-input-assistant';
import { tryParseFloat } from '../number-utilities';
import { clone, compare } from '../clone';
import { bsFormRadioGroupYesNoOptions, displayYesNo, isEmptyOrSpace } from './helper-functions';
import { newGuid } from '../../api/guid';

export interface ShippingNotesContainer {
  shippingNotes?: string;
  backupShippingNotes?: string;
}

export interface AddressOption extends Address {
  id: string;
  display?: string;
}

@customElement('wm-addresseditor')
export class AddressEditor extends LitElement {
  private _backupAddress?: Address;

  @property({ type: Boolean })
  displayMap = true;

  @property({ type: Boolean })
  displayLookup = true;

  @property({ type: Boolean })
  isSameAsOtherAddressVisible = false;

  @state()
  private _isSameAsOtherAddress = false;
  public get isSameAsOtherAddress() {
    return this._isSameAsOtherAddress;
  }

  @property({ type: Boolean })
  public set isSameAsOtherAddress(value) {
    this._isSameAsOtherAddress = value;
    if (value) this._address = undefined;
    else if (!this._address) this._address = emptyAddress();
    this.addressChangedEvent();
  }

  @state()
  private _address?: Address | undefined;
  public get address(): Address | undefined {
    return this._address;
  }

  @property({ type: Object })
  public set address(value: Address | undefined) {
    this._address = clone(value);
    this._backupAddress = clone(value);
  }

  private _notesContainer: ShippingNotesContainer = {};
  public get shippingNotes(): string | undefined {
    return this._notesContainer.shippingNotes;
  }

  @property()
  public set shippingNotes(value: string | undefined) {
    this._notesContainer.shippingNotes = value;
    this._notesContainer.backupShippingNotes = value;
  }

  @property({ type: Object })
  subheader?: object;

  @property()
  eventTitle = 'Address';

  @property({ type: Boolean })
  readonly = false;

  @property({ type: Boolean })
  isDefaultShippingVisible = false;

  @state()
  private _isDefaultShipping = false;
  public get isDefaultShipping() {
    return this._isDefaultShipping;
  }

  @property({ type: Boolean })
  public set isDefaultShipping(value) {
    this._isDefaultShipping = value;
    this.addressChangedEvent();
  }

  private _preSelectAddressList?: AddressOption[];
  public get preSelectAddressList() {
    return this._preSelectAddressList;
  }

  @property({ type: Array })
  public set preSelectAddressList(value: AddressOption[] | undefined) {
    this._preSelectAddressList = value?.map(x => {
      return { ...x, id: x.id ?? newGuid() };
    });
  }

  dataTracker: DataTracker = new DataTracker(new DataBinding(this, null));
  constructor() {
    super();
    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.address),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };
    addField('line1', FieldType.string, false);
    addField('locality', FieldType.string, true);
    addField('region', FieldType.string, true);
    addField('postcode', FieldType.string, false);
    addField('country', FieldType.string, true);
    addField('shippingNotes', FieldType.string, true, undefined, () => this._notesContainer);
  }

  dispatchCustom(name: string, values: object) {
    const options = {
      detail: { ...values },
      bubbles: true,
      composed: true
    };
    this.dispatchEvent(new CustomEvent(`wm-ae-${name}`, options));
  }

  private addressChangedEvent() {
    this.dispatchCustom('changed', {
      address: this.address,
      shippingNotes: this.shippingNotes,
      isDefaultShipping: this.isDefaultShippingVisible ? this.isDefaultShipping : undefined,
      isSameAsOtherAddress: this.isSameAsOtherAddress
    });
  }

  private get addressDetailsVisible(): boolean {
    return (this.isSameAsOtherAddressVisible && !this.isSameAsOtherAddress) || !this.isSameAsOtherAddressVisible;
  }

  private changeEvent = (e: Event) => {
    e.stopImmediatePropagation();
    if (this.addressDetailsVisible) this.dataTracker.applyChangeToValue();
    if (
      !compare(this._address, this._backupAddress) ||
      !compare(this._notesContainer.shippingNotes, this._notesContainer.backupShippingNotes)
    ) {
      this._backupAddress = clone(this._address);
      this._notesContainer.backupShippingNotes = this._notesContainer.shippingNotes;
      this.requestUpdate();
      this.addressChangedEvent();
    }
  };

  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('change', this.changeEvent);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.removeEventListener('change', this.changeEvent);
  }

  render() {
    const formsPhysical = new FormInputAssistant(this.dataTracker, this.readonly);

    const updateAddressEvent = _ => this.updateAddressFromMapHelper(_.detail.place);
    const defaultShippingEvent = (e: CustomEvent<boolean>) => {
      e.stopImmediatePropagation();
      this.isDefaultShipping = e.detail;
    };
    const defaultShippingTemplate = this.isDefaultShippingVisible
      ? html`<bs-form-checkbox
          data-label=${tlang`Default Shipping Address`}
          ?readonly=${this.isDefaultShippingReadonly()}
          data-id=${this.dataTracker.binder.field('physicalAddressAsDefaultShipping')}
          .checked=${this.isDefaultShipping}
          @checkbox-changed=${defaultShippingEvent}
          .display="${'switch'}"
        >
        </bs-form-checkbox>`
      : html``;

    const sameAsOtherChangeEvent = (e: CustomEvent<string>) => {
      e.stopImmediatePropagation();
      this.isSameAsOtherAddress = e.detail === 'Yes';
    };
    const sameAsOtherAddressTemplate = this.isSameAsOtherAddressVisible
      ? html`<div>
          <bs-form-radio-group
            data-label=${tlang`Same as Physical Address`}
            data-id=${this.dataTracker.binder.field('sameAsOtherAddress')}
            value=${displayYesNo(this.isSameAsOtherAddress)}
            options=${bsFormRadioGroupYesNoOptions()}
            @radio-changed=${sameAsOtherChangeEvent}
          >
          </bs-form-radio-group>
        </div>`
      : html``;

    const getAddressOptions = this._preSelectAddressList?.map(x => new Option(x.display ?? singleLineAddress(x), x.id));

    const wmSelectChangedEvent = (e: CustomEvent<string | undefined>) => {
      e.stopImmediatePropagation();
      if (!e.detail || !this._preSelectAddressList) {
        return;
      }

      const selectedAddress = this._preSelectAddressList.findIndex(x => x.id === e.detail);

      if (selectedAddress < 0 || !this.address) {
        return;
      }

      updateAddressWithAddress(this.address, this._preSelectAddressList[selectedAddress]);
      this.addressChangedEvent();
      this.requestUpdate();
    };
    const lookupTemplate = this.displayLookup
      ? this.preSelectAddressList
        ? html`
            <bs-form-select
              data-placeholder="Select Address"
              data-label="Lookup:"
              .options="${getAddressOptions}"
              @wm-select-changed="${wmSelectChangedEvent}"
            >
            </bs-form-select>
          `
        : html`
            <google-place-autocomplete
              class="left right"
              data-id="clientauto"
              outline
              ?disabled=${this.readonly}
              searchType=${'address'}
              .apiKey=${googleMapApiKey}
              label=${tlang`Lookup:`}
              @place-changed=${updateAddressEvent}
            >
            </google-place-autocomplete>
          `
      : html``;
    const notesTemplate = html` <bs-form-area
      data-id=${this.dataTracker.binder.field('shippingNotes')}
      type=${'area'}
      .value=${this.shippingNotes}
      data-label=${tlang`!!shipping-note!!`}
      ?readonly=${this.readonly}
      maxlength="500"
    ></bs-form-area>`;
    const mapViewTemplate = this.displayMap
      ? mapTemplate(tryParseFloat(this.address?.latitude), tryParseFloat(this.address?.longitude))
      : html``;
    const subheader = this.subheader ?? html``;
    const primaryDetailsTemplate = this.addressDetailsVisible
      ? html`
          <div class="row">
            <div>
              ${subheader} ${sameAsOtherAddressTemplate} ${lookupTemplate}
              ${formsPhysical.textRequired('line1', tlang`Street Address`, 120)}
              ${formsPhysical.text('locality', tlang`City`, 120)} ${formsPhysical.text('region', tlang`State`, 120)}
              ${formsPhysical.textRequired('postcode', tlang`Zip Code`, 20)}
              ${formsPhysical.text('country', tlang`Country`, 80)} ${notesTemplate} ${defaultShippingTemplate}
            </div>
            <div class="address-map">${mapViewTemplate}</div>
          </div>
        `
      : html`
          <div class="row">
            <div>${sameAsOtherAddressTemplate}</div>
            <div class=""></div>
          </div>
        `;

    return html`
      <div class="form-two-col">
        <h2>${this.eventTitle}</h2>

        ${primaryDetailsTemplate}
      </div>
    `;
  }
  updateAddressFromMapHelper(place: Place) {
    if (!this.address || !place) return;
    updateAddress(place, this.address);
    this.addressChangedEvent();
    //manually trigger as we modified the content of a property, but not the property itself.
    this.requestUpdate();
  }
  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  protected isDefaultShippingReadonly(): boolean {
    // Attempt to get the current value of the form during a render.
    // This will fail initially when loading so fall back to the project object.
    try {
      const line1 = this.dataTracker.binder.getValue('line1');
      const postcode = this.dataTracker.binder.getValue('postcode');
      return isEmptyOrSpace(line1) || isEmptyOrSpace(postcode);
    } catch {
      if (this.address) {
        return isEmptyOrSpace(this.address.line1) || isEmptyOrSpace(this.address.postcode);
      }
      return true;
    }
  }
}
