import { html, TemplateResult } from 'lit';
import { tlang } from '@softtech/webmodule-components';
import { EventTemplate, Snippet } from './events';
import { isEmptyOrSpace } from './helper-functions';
import { ModalDialog } from './modal-base';
import { showValidations } from './modal-validationhandler';
import { asMarkdownTemplate } from '../markdown';
import { DataBinding } from './databinding/databinding';
import { DataTracker, FieldType } from './databinding/data-tracker';
import { FormInputAssistant } from './templateresult/form-input-assistant';
import { customElement } from 'lit/decorators.js';

export interface ConfirmationReasonResult {
  result: boolean;
  reason?: ReasonOption;
  comment?: string;
}

export interface ReasonOption {
  id: string;
  reason: string;
}

export async function getConfirmationReasonFor(
  options: ReasonOption[],
  modalTitle?: string,
  modalMessage?: string,
  selectReasonLabel?: string,
  confirmButtonText?: string
): Promise<ConfirmationReasonResult> {
  const modal: ConfirmationReasonSelectDialog = new ConfirmationReasonSelectDialog(
    options,
    undefined,
    modalTitle,
    modalMessage,
    selectReasonLabel,
    confirmButtonText
  );
  await modal.showModal();
  return {
    result: modal.result,
    reason: modal.selectedReason,
    comment: modal.userComment
  };
}
@customElement('wm-confirmationreasonselectdialog')
class ConfirmationReasonSelectDialog extends ModalDialog {
  reasons: ReasonOption[];
  selectedReason?: ReasonOption;
  selectedReasonId?: string;
  userComment?: string;
  result = false;
  private modalMessage?: string;
  private selectReasonLabel?: string;
  private confirmButtonText?: string;
  protected _modalSize = 'modal-lg';
  protected _modalTitle = 'Confirm';

  private maxCommentLength: number = 1000;

  private _dataBinding: DataBinding;
  private _dataTracker: DataTracker;

  constructor(
    values: ReasonOption[],
    modalSize?: string,
    modalTitle?: string,
    modalMessage?: string,
    selectReasonLabel?: string,
    confirmButtonText?: string
  ) {
    super();
    this.reasons = values;
    this.modalMessage = modalMessage ?? tlang`Available Reasons`;
    this.selectReasonLabel = selectReasonLabel ?? tlang`Reason`;
    this.confirmButtonText = confirmButtonText ?? tlang`Confirm`;

    this._dataBinding = new DataBinding(this.ui, this.elementId, input => {
      return `${input}-${this.elementId}`;
    });
    this._dataTracker = new DataTracker(this._dataBinding);

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this._dataTracker.addObjectBinding(
        data ?? (() => this),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('selectedReasonId', FieldType.string, false);
    addField('userComment', FieldType.string, true);

    if (modalSize) this._modalSize = modalSize;

    if (modalTitle) this._modalTitle = modalTitle;
  }

  bodyTemplate(): EventTemplate {
    const message = asMarkdownTemplate(this.modalMessage);
    const reasonMap = (c: ReasonOption) => {
      return {
        text: tlang`${c.reason}`,
        value: c.id,
        disabled: false
      };
    };

    const forms = new FormInputAssistant(this._dataTracker);

    const reasons: ReasonOption[] = [];
    reasons.push({ id: '', reason: '' });
    reasons.push(...this.reasons);

    return html`
      <form class="modal-confirmation-reason form-one-col">
        <div class="row">
          <div class="form-column">
            ${message} ${forms.arraySelect('selectedReasonId', reasons, reasonMap, this.selectReasonLabel)}
            ${forms.text('userComment', tlang`Comments`, this.maxCommentLength)}
          </div>
        </div>
      </form>
    `;
  }

  footerTemplate(): TemplateResult {
    const okEvent = () => {
      this.prepareForSave();
      const errors = this.getValidationErrors();
      if (errors.length == 0) {
        this.result = true;
        this.hideModal();
      } else {
        showValidations(errors);
      }
    };
    return this.createConfirmCancelButtons(this.confirmButtonText ?? 'Select', okEvent, tlang`Cancel`);
  }

  protected prepareForSave() {
    this._dataTracker.applyChangeToValue();
    this.selectedReason = this.reasons.find(reason => reason.id == this.selectedReasonId);
  }

  protected getTitle(): Snippet {
    return this._modalTitle;
  }

  protected getValidationErrors(): string[] {
    const errors: string[] = [];

    if (this.selectedReason == null) {
      errors.push(tlang`Please select a valid reason`);
    } else if (this.selectedReason.reason == 'Other') {
      if (isEmptyOrSpace(this.userComment)) {
        errors.push(tlang`Please provide a comment when selecting '${tlang`Other`}' as the reason.`);
      }
    }
    if (this.userComment && this.userComment.length > this.maxCommentLength) {
      errors.push(
        tlang`Comment is too long. Maximum length is ${this.maxCommentLength} (${this.userComment.length} currently)`
      );
    }

    return errors;
  }
}
