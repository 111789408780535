// eslint-disable-next-line import/named
import { html, render, TemplateResult } from 'lit';
import { getInternalId } from './databinding/databinding';
import { PromiseSnippet, PromiseTemplate } from './events';
import { hideModalDialog, showModalDialogEx } from './modal-helper';
import { EventVoid, EventVoidAsync, ModalPromise } from '@softtech/webmodule-data-contracts';
import { tlang } from '@softtech/webmodule-components';
import { AsyncConstructor } from '../../async-constructor';
import { IDispose } from '../dispose';
import { InformationDispatcher } from './information-dispatcher';
import { showDevelopmentError } from '../../development-error';

export class ViewBaseOriginal implements AsyncConstructor, IDispose {
  _ui: HTMLDivElement | null = document.createElement('div') as HTMLDivElement;
  informationDispatcher: InformationDispatcher = new InformationDispatcher();
  get ui(): HTMLDivElement {
    if (!this._ui) this._ui = document.createElement('div') as HTMLDivElement;
    return this._ui;
  }
  constructor() {
    this.ui.addEventListener('ui-changed', this.eventChildUIChanged);
  }

  protected eventChildUIChanged = (_e: Event) => this.render();

  async dispatchInformation(info: string) {
    await this.informationDispatcher.setInformation(info);
  }
  eventPrefix() {
    return 'wm-event';
  }

  dispatchCustom<T>(name: string, values: T) {
    const options = {
      detail: values,
      bubbles: true,
      composed: true
    };
    const eventName = name.startsWith('!') ? name.substring(1) : `${this.eventPrefix()}-${name}`;
    this.ui.dispatchEvent(new CustomEvent(eventName, options));
    return values;
  }
  async afterConstruction(): Promise<void> {
    //Nothing to do
  }
  protected async template(): PromiseTemplate {
    return html``;
  }
  private _rendering = false;
  private _renderRequestCount = 0;

  //TODO- Make a LitComponent
  public async render(): Promise<void> {
    this._renderRequestCount++;
    if (this._rendering) {
      // console.log('Unexpected Nested Or SideBySide Render Call');
      // push the render to the end of the queue
      // it is likely that the current render cycle is rendering stale data.

      if (this._renderRequestCount > 10) showDevelopmentError('Recursive Render Cycle Error');
      return new Promise(resolve => {
        setTimeout(async () => {
          console.log('DUAL RENDER');
          await this.internalRender();
          resolve();
        }, 50);
      });
    }
    await this.internalRender();
  }
  private async internalRender() {
    this._rendering = true;
    try {
      render(await this.template(), this.ui);
      this._renderRequestCount--;
      if (this._renderRequestCount === 0) await this.postRender();
    } finally {
      this._rendering = false;
    }
  }
  protected async postRender(): Promise<void> {
    //override if needed
  }
  dispatchUiChanged() {
    this.dispatchCustom('!ui-changed', { ui: this.ui });
  }
  async dispose() {
    this.ui.removeEventListener('ui-changed', this.eventChildUIChanged);
    this._ui?.remove();
    this._ui = null;
    //override if needed
  }
}

export class ModalDialogOld extends ViewBaseOriginal {
  protected _hiding = false;
  protected _modalSize = 'modal-lg';
  private _showing = false;
  private _modalPromise?: ModalPromise;
  protected _elementId = getInternalId();

  get elementId() {
    return `${this.name()}-${this._elementId}`;
  }

  async afterConstruction(): Promise<void> {
    //override to do something
  }

  public async showModal(): Promise<void> {
    await (
      await this.show()
    ).onClose;
  }

  public async hideModal(): Promise<void> {
    this._hiding = true;
    if (this._showing) {
      this._showing = false;
      await this.onHideModal();
      await hideModalDialog(this.ui, this._modalPromise?.onClose);
    }
  }
  public async show(): Promise<ModalPromise> {
    await this.onShowModal();
    document.body.appendChild(this.ui);
    await this.render();
    this._showing = true;
    this._hiding = false;
    this._modalPromise = showModalDialogEx(this.ui, this.ZIndex());
    return this._modalPromise;
  }
  borderType() {
    return 'basic';
  }

  /**
   *
   * @returns the name of this class, to be used to form a unique element id for building children in templates
   */
  public name() {
    return 'modal-dialog';
  }

  protected async onHideModal() {
    //override to do stuff
  }

  protected async onShowModal(): Promise<void> {
    //override to do stuff
  }

  protected modalSize(): string {
    return this._modalSize;
  }

  protected ZIndex(): number | undefined {
    //Override as needed
    return undefined;
  }

  protected closeButtonText(): string {
    return '';
  }

  protected closeButtonCss(): string {
    return 'btn-close';
  }

  protected renderCloseButtonTemplate(): boolean {
    return false;
  }

  protected closeButtonTemplate(): TemplateResult {
    const eventClose = async (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      await this.closeIfAllowed();
    };

    return html` <button type="button" class=${this.closeButtonCss()} @click=${eventClose} aria-label="Close">
      ${this.closeButtonText()}
    </button>`;
  }

  async canClose(): Promise<boolean> {
    return true;
  }

  protected async closeIfAllowed(): Promise<boolean> {
    if (await this.canClose()) {
      await this.hideModal();
      return true;
    }
    return false;
  }

  protected renderFooterTemplate(): boolean {
    return this.footerTemplate() != null;
  }

  protected modalClasses(): string {
    return 'modal-dialog';
  }

  protected async template(): PromiseTemplate {
    const modalContentClasses = `modal-content shadow-lg border border-${this.borderType()}`;
    const modalClasses = `${this.modalClasses()} ${this.modalSize()}`;
    const closeButtonToBeRendered = !this.renderCloseButtonTemplate() ? html`` : this.closeButtonTemplate();
    const footerToBeRendered = !this.renderFooterTemplate()
      ? html``
      : html` <div class="modal-footer">${this.footerTemplate()}</div>`;

    return html` <div class="modal " data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class=${modalClasses}>
        <div class=${modalContentClasses}>
          <div class="modal-header">
            <h2 class="modal-title">${await this.getTitle()}</h2>
            ${closeButtonToBeRendered}
          </div>
          <div class="modal-body"><div class="modal-body-content">${await this.bodyTemplate()}</div></div>
          ${footerToBeRendered}
        </div>
      </div>
    </div>`;
  }

  protected footerTemplate(): TemplateResult | null {
    return null;
  }

  protected async bodyTemplate(): PromiseTemplate {
    return html``;
  }

  protected async getTitle(): PromiseSnippet {
    return 'Title';
  }

  /**
   * function which creates a confirm/cancel button pair for use in the footer of the modal.
   * @param confirmText text to be shown on the confirm button.
   * @param confirmEvent event to be run when clicking on the confirm button.
   * @param cancelText text to be shown on the cancel button.
   * @param cancelEvent event to be run when clicking on the cancel button. If undefined uses the default close event.
   * @returns TemplateResult
   */
  protected createConfirmCancelButtons(
    confirmText: string,
    confirmEvent: EventVoid | EventVoidAsync,
    cancelText: string,
    cancelEvent?: EventVoid | EventVoidAsync
  ): TemplateResult {
    const defaultCancelEvent = async () => {
      await this.hideModal();
    };

    return html`
      <button @click=${cancelEvent ?? defaultCancelEvent} class="btn btn-secondary">${cancelText}</button>
      <button @click=${confirmEvent} class="btn btn-primary">${confirmText}</button>
    `;
  }

  protected createCancelButton(cancelText?: string, cancelEvent?: EventVoid | EventVoidAsync): TemplateResult {
    const defaultCancelEvent = async () => {
      await this.hideModal();
    };
    return html`<button @click=${cancelEvent ?? defaultCancelEvent} class="btn btn-secondary">
      ${cancelText ?? tlang`Close`}
    </button>`;
  }
}
