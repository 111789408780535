import { tlang } from '@softtech/webmodule-components';

export const quoteSupplierProvider = {
  v6: 'v6'
};

// eslint-disable-next-line prefer-const
export let currentQuoteSupplierProvider = quoteSupplierProvider.v6;

export interface ISupplierInformationCache {
  getSupplierDisplayName: (supplierId: string) => Promise<string>;
}

let _cache: ISupplierInformationCache = {
  async getSupplierDisplayName(_supplierId: string) {
    return tlang`%%supplier%%`;
  }
};
export function setSupplierInformationCache(cache: ISupplierInformationCache) {
  _cache = cache;
}

export async function getQuoteSupplierDisplayName(supplierId): Promise<string> {
  return await _cache.getSupplierDisplayName(supplierId);
}
