// eslint-disable-next-line import/named
import { ServiceResponseInvalid, ServiceResponseType } from '@softtech/webmodule-data-contracts';
import { html, TemplateResult } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { tlang } from '@softtech/webmodule-components';
import { MultiPromise } from '../../multicast-promise';
import { EventSnippet, EventTemplate, Snippet } from './events';
import { ModalDialog } from './modal-base';
import { customElement, state } from 'lit/decorators.js';

const formatText = (text?: string) => {
  if (!text) return;

  const lines = text.split('\n');

  const linesFormatted = lines
    .map(line => {
      // If there is a URL in the text we need to distinguish between single and double slashes
      // Split the URL into an array to distinguish double slashes from single slashes
      const doubleSlash = line.split('//');

      // Format the strings on either side of double slashes separately
      const formatted = doubleSlash
        .map(
          str =>
            // Insert a word break opportunity after a colon
            str
              .replace(/(?<after>:)/giu, '$1<wbr>')
              // Before a single slash (either direction), tilde, period, comma, hyphen, underline,
              // question mark, number sign, or percent symbol
              .replace(/(?<before>[/~.,\-_?#%])/giu, '<wbr>$1')
              // Before and after an equals sign or ampersand
              .replace(/(?<beforeAndAfter>[=&])/giu, '<wbr>$1<wbr>')
          // Reconnect the strings with word break opportunities after double slashes
        )
        .join('//<wbr>');

      return formatted;
    })
    .join('<br />');

  return linesFormatted;
};

let _modal: ErrorDialog | null = null;
let _title: EventSnippet = () => '';
let _message: ServiceResponseInvalid | Error = new Error();

const _multicast = new MultiPromise<void>(async () => {
  _modal = new ErrorDialog();
  _modal.addError(_title, _message);
  await _modal.showModal();
  _modal = null;
});

export async function displayAllError(title: EventSnippet, message: ServiceResponseInvalid | Error) {
  _title = title;
  _message = message;
  if (_modal) {
    _modal.addError(title, message);
  }
  return _multicast.run();
}

interface DialogError {
  title: EventSnippet;
  error: ServiceResponseInvalid;
}
@customElement('wm-errordialog')
export class ErrorDialog extends ModalDialog {
  @state()
  errors: DialogError[] = [];
  errorCount = 0;
  @state()
  displayCopiedButton = false;

  get eventTitle(): EventSnippet {
    return this.errors[0].title ?? '';
  }
  get error(): ServiceResponseInvalid {
    return this.errors[0].error;
  }
  async addError(title: EventSnippet, message: ServiceResponseInvalid | Error) {
    this.errorCount++;
    const msg: DialogError =
      message instanceof Error
        ? {
            title,
            error: {
              responseType: ServiceResponseType.Error,
              responseTypeCaption: 'Local UI Error',
              responseError: {
                message: `${message.name} - ${message.cause} - ${message.message}`,
                stackTrace: message.stack
              }
            }
          }
        : { title, error: message };

    if (!this.hasMatchingError(msg)) this.errors = [...this.errors, msg];
  }

  hasMatchingError(msg: DialogError) {
    return (
      this.errors.find(
        x => x.title == msg.title && x.error.responseError?.message == msg.error.responseError?.message
      ) != undefined
    );
  }

  protected footerTemplate(): TemplateResult {
    const copyEvent = () => {
      const text = tlang`${'ref:error_clipboard'}Dealer Error:
${this.error.responseError?.message ?? ''}
-------------------------------------------------
${this.error.responseError?.stackTrace ?? ''}
        `;

      navigator.clipboard.writeText(text);
      this.displayCopiedButton = true;

      setTimeout(() => {
        this.displayCopiedButton = false;
      }, 2000);
    };
    if (this.displayCopiedButton)
      return html` <button @click=${() => this.hideModal()} class="btn btn-secondary">${tlang`Close`}</button>
        <button class="btn btn-success">${tlang`Copied to Clipboard`}</button>`;
    else return this.createConfirmCancelButtons(tlang`Copy to Clipboard`, copyEvent, tlang`Close`);
  }

  protected modalClasses(): string {
    return 'modal-dialog modal-dialog-scrollable';
  }

  protected modalSize(): string {
    return 'modal-xl';
  }

  protected ZIndex(): number | undefined {
    return 99999;
  }

  protected getTitle(): Snippet {
    return this.eventTitle();
  }

  protected bodyTemplate(): EventTemplate {
    const _template = (e: DialogError, i: number): TemplateResult => {
      const stack = formatText(this.error.responseError?.stackTrace);
      const stackTrace = e.error.responseError?.stackTrace
        ? html` <div class="alert alert-info" role="alert">${unsafeHTML(stack)}</div>`
        : html``;

      return html`
        <div class="alert alert-danger" role="alert">
          ${i > 0 ? e.title : ''} ${e.error.responseTypeCaption}
          ${unsafeHTML(formatText(e.error.responseError?.message))}
        </div>
        ${stackTrace}
      `;
    };

    return html` <h3>${tlang`Please submit an IT Ticket to your support with the following details`}</h3>
      <h4>${tlang`Note that continuing what you are doing may result in corruption or loss of data`}</h4>
      <h4>Error Count = ${this.errors.length}</h4>
      ${this.errors.map((e, i) => _template(e, i))}`;
  }
}
