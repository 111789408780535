// eslint-disable-next-line import/named
import { html, TemplateResult } from 'lit';
import { emptyGuid } from '../../api/guid';
import { ClientApi } from '../../api/client-api';
import { DataBinding, getInternalId } from '../../components/ui/databinding/databinding';
import { Client, ClientState } from '../../api/dealer-api-interface-client';
import '../../components/ui/maps/google-place-autocomplete';
import { DataTracker, FieldType } from '../../components/ui/databinding/data-tracker';
import { FormInputAssistant } from '../../components/ui/templateresult/form-input-assistant';
import { Place } from '../../components/ui/maps/place';
import { tlang } from '@softtech/webmodule-components';
import { isEmptyOrSpace, isValidEmail, validId } from '../../components/ui/helper-functions';
import { getApiFactory } from '../../api/api-injector';
import { emptyAddress, updateAddressDataBinding } from '../../components/ui/maps/map-helpers';
import { clone, compare } from '../../components/clone';
import { ClientContainerManager } from '../data/client-container-manager';
import { DataEntryPageControlView, PageControlChildTab } from '../../components/ui/data-entry-screen-base';
import { EventTemplate, Snippet } from '../../components/ui/events';
import { cache } from '../../dealer-franchisee/cache/cache-registry';
import { AddressEditor } from '../../components/ui/address-editor';
import { customElement, state } from 'lit/decorators.js';

export interface ClientDetailViewOptions {
  clientContainerManager: ClientContainerManager;
}
@customElement('wm-clientdetailview')
export class ClientDetailView extends PageControlChildTab {
  clientContainerManager: ClientContainerManager;
  clientApi: ClientApi = getApiFactory().client();
  clientCache = cache().client;
  internalId: string;
  @state()
  billingAsPhysicalAddress = true;
  dataBinding: DataBinding;
  dataTracker: DataTracker;
  //Def sure this is not the intention
  dataBindingBilling: DataBinding;
  dataTrackerBilling: DataTracker;
  @state()
  protected lngPhysical?: number;
  @state()
  protected latPhysical?: number;
  @state()
  protected lngBilling?: number;
  @state()
  protected latBilling?: number;

  constructor(owner: DataEntryPageControlView, options: ClientDetailViewOptions) {
    super(owner);
    this.internalId = getInternalId();

    this.clientContainerManager = options.clientContainerManager;

    this.dataBinding = new DataBinding(
      this.ui,
      undefined,
      (input: string, internalId: string) => `client-${input}-${internalId}`
    );
    this.dataTracker = new DataTracker(this.dataBinding);

    this.dataBindingBilling = new DataBinding(
      this.ui,
      undefined,
      (input: string, internalId: string) => `client-billing-${input}-${internalId}`
    );
    this.dataTrackerBilling = new DataTracker(this.dataBindingBilling);

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.client),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('name');
    addField('businessPhoneNumber');
    addField('primaryContactName', FieldType.string, false, undefined, () => this.clientContainerManager.container);
    addField('primaryContactEmail', FieldType.string, false, undefined, () => this.clientContainerManager.container);
    addField('state', FieldType.int, false);
  }

  public async afterConstruction() {
    this.clientContainerManager.afterSave.push(async () => await this.afterSave());
  }

  async onEnter(): Promise<void> {
    await this.loadOrRefresh();
  }

  protected getCaption(): Snippet {
    return tlang`%%client%% Info`;
  }

  public allowDeletePage(): boolean {
    return false;
  }

  protected get client(): Client {
    return this.clientContainerManager.client;
  }

  public async loadOrRefresh(): Promise<void> {
    await this.clientContainerManager.needsClient();

    const client = this.clientContainerManager.client;
    if (client.billingAddress) {
      this.billingAsPhysicalAddress = compare(client.physicalAddress, client.billingAddress);
    }

    this.latPhysical = client.physicalAddress.latitude ? parseFloat(client.physicalAddress.latitude) : undefined;
    this.lngPhysical = client.physicalAddress.longitude ? parseFloat(client.physicalAddress.longitude) : undefined;

    this.latBilling = client.billingAddress?.latitude ? parseFloat(client.billingAddress.latitude) : undefined;
    this.lngBilling = client.billingAddress?.longitude ? parseFloat(client.billingAddress.longitude) : undefined;
  }

  public async prepareForSave() {
    if (this.dataTracker.modified) this.dataTracker.applyChangeToValue();

    if (!this.billingAsPhysicalAddress && this.dataTrackerBilling.modified) {
      this.dataTrackerBilling.applyChangeToValue();
    } else if (this.billingAsPhysicalAddress && this.client) {
      this.client.billingAddress = clone(this.client.physicalAddress);
    }
  }

  public getValidationErrors() {
    const errors: string[] = [];

    const companyName = this.client.name;

    if (isEmptyOrSpace(companyName)) errors.push(tlang`Please provide a Company Name`);

    if (this.client?.id == emptyGuid) {
      const contactName = this.clientContainerManager.container.primaryContactName;
      const contactEmail = this.clientContainerManager.container.primaryContactEmail ?? '';

      if (isEmptyOrSpace(contactName)) errors.push(tlang`Please provide a Primary %%contact%%`);

      if (isEmptyOrSpace(contactEmail)) errors.push(tlang`Please provide a Company Email`);
      else if (!isValidEmail(contactEmail)) errors.push(tlang`Please provide a valid Company Email`);
    }

    return errors;
  }

  protected async afterSave() {
    if (this.client.physicalAddress && this.client.billingAddress) {
      this.billingAsPhysicalAddress = compare(this.client.physicalAddress, this.client.billingAddress);
    }

    await this.clientCache.updateLocal(this.client.id);
  }

  protected updateAddress(address: Place) {
    const latlng = updateAddressDataBinding(address, this.dataBinding);

    if (latlng) {
      if (this.client) {
        //Updating the client values so it gets saved
        this.client.physicalAddress.latitude = latlng.lat.toString();
        this.client.physicalAddress.longitude = latlng.lng.toString();

        //Update the render values
        this.latPhysical = latlng.lat;
        this.lngPhysical = latlng.lng;
      }

      this.requestUpdate(); //no wait
    }
  }

  protected updateBillingAddress(address: Place) {
    const latlng = updateAddressDataBinding(address, this.dataBindingBilling);

    if (latlng) {
      if (this.client && this.client.billingAddress) {
        //Updating the client values so it gets saved
        this.client.billingAddress.latitude = latlng.lat.toString();
        this.client.billingAddress.longitude = latlng.lng.toString();

        //Update the render values
        this.latBilling = latlng.lat;
        this.lngBilling = latlng.lng;
      }

      this.requestUpdate(); //no wait
    }
  }

  protected primaryContactTemplate(forms: FormInputAssistant): TemplateResult {
    return !validId(this.client?.id)
      ? html` ${forms.textRequired('primaryContactName', tlang`Primary %%contact%%`, 100)}
        ${forms.textRequired('primaryContactEmail', tlang`Email`, 100)}`
      : html` ${forms.textReadonly('primaryContactName', tlang`Primary %%contact%%`)}
        ${forms.textReadonly('primaryContactEmail', tlang`Email`)}`;
  }

  protected bodyTemplate(): EventTemplate {
    const forms = new FormInputAssistant(this.dataTracker, this.clientContainerManager.isReadonly());
    const readonly = this.clientContainerManager.isReadonly();

    return html`
      <form class="frm-client-details form-two-col">
        <div class="row">
          <h2>${tlang`%%client%% Details`}</h2>
          <div>
            ${forms.textRequired('name', tlang`%%client%% Name`, 100)}
            ${forms.text('businessPhoneNumber', tlang`%%client%% Phone`, 30)}
          </div>

          <div>
            ${this.primaryContactTemplate(forms)} ${forms.radioGroup('state', ClientState, tlang`%%client%% Status`)}
          </div>
        </div>

        <wm-addresseditor
          @wm-ae-changed=${(e: Event) => this.physicalAddressModified(e.currentTarget as AddressEditor)}
          id="physicalAddressEditor"
          .address=${this.client.physicalAddress}
          .shippingNotes=${this.client.shippingNotes}
          .title=${tlang`Physical Address`}
          .readonly=${this.clientContainerManager.isReadonly()}
          .isDefaultShippingVisible=${true}
          .isDefaultShipping=${this.client.shipToPhysicalAddress}
        >
        </wm-addresseditor>
        <wm-addresseditor
          @wm-ae-changed=${(e: Event) => this.billingAddressModified(e.currentTarget as AddressEditor)}
          id="billingAddressEditor"
          .address=${this.client.billingAddress}
          .title=${tlang`Billing Address`}
          .readonly=${readonly}
          .isDefaultShippingVisible=${false}
          .isSameAsOtherAddressVisible=${true}
          .isSameAsOtherAddress=${!this.client.billingAddress}
        ></wm-addresseditor>
      </form>
    `;
  }

  physicalAddressModified(addressEditor: AddressEditor) {
    this.client.physicalAddress = addressEditor.address ? clone(addressEditor.address) : emptyAddress();
    this.client.shipToPhysicalAddress = addressEditor.isDefaultShipping;
    this.client.shippingNotes = addressEditor.shippingNotes ?? '';
  }
  billingAddressModified(addressEditor: AddressEditor) {
    this.client.billingAddress = addressEditor.address ? clone(addressEditor.address) : emptyAddress();
  }
}
