import { html, LitElement } from 'lit';
import { state } from 'lit/decorators.js';

import { customElement } from 'lit/decorators.js';
import { tlang, WebmoduleIcon } from '@softtech/webmodule-components';

declare global {
  interface DocumentEventMap {
    'webmodule-auto-save': CustomEvent<AutoSaveNotification>;
  }
}

export class AutoSaveNotifier {
  private static instance: AutoSaveNotifier;

  private constructor() {}

  public static getInstance(): AutoSaveNotifier {
    if (!AutoSaveNotifier.instance) {
      AutoSaveNotifier.instance = new AutoSaveNotifier();
    }
    return AutoSaveNotifier.instance;
  }

  public triggerAutoSave(type: 'busy' | 'success' | 'error', timeout = 2000) {
    const event = new CustomEvent<AutoSaveNotification>('webmodule-auto-save', {
      detail: {
        type: type,
        timeout: timeout
      },
      bubbles: true,
      composed: true
    });
    document.dispatchEvent(event);
  }

  public triggerAutoSaveBusy() {
    this.triggerAutoSave('busy', -1);
  }

  public triggerAutoSaveSuccess() {
    this.triggerAutoSave('success');
  }

  public triggerAutoSaveError() {
    this.triggerAutoSave('error', 2000);
  }
}

export const autoSaveNotifier = AutoSaveNotifier.getInstance();

export interface AutoSaveNotification {
  type: 'busy' | 'success' | 'error' | null;
  timeout?: number;
}

//TODO: Once styling is done, move to lit components repo
@customElement('wm-webmoduleautosaveindicator')
export class WebmoduleAutoSaveIndicator extends LitElement {
  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  static dependencies = {
    'webmodule-icon': WebmoduleIcon
  };

  @state()
  private _type: 'busy' | 'success' | 'error' | 'idle' | null;
  private _timer?: NodeJS.Timeout;

  constructor() {
    super();

    this._type = 'idle';
  }

  //TODO: Should we respond to the event here, or rather have the parent element set properties to render.
  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('webmodule-auto-save', this.eventHandleAutoSave);
  }

  disconnectedCallback() {
    document.removeEventListener('webmodule-auto-save', this.eventHandleAutoSave);
    super.disconnectedCallback();
  }

  eventHandleAutoSave = (event: CustomEvent<AutoSaveNotification>) => {
    this._type = event.detail.type;

    const timeout = event.detail.timeout ?? 2000;
    if (this._timer) clearTimeout(this._timer);
    if (timeout > -1)
      this._timer = setTimeout(() => {
        this._type = 'idle';
        this._timer = undefined;
      }, timeout);
  };

  private _idle = html`<webmodule-icon library="system" name="saving-idle"></webmodule-icon>`;
  private _busy = html`<webmodule-icon library="system" name="saving-in-progress"></webmodule-icon>`;
  private _success = html`<webmodule-icon library="system" name="saved"></webmodule-icon>`;
  private _error = html`<webmodule-icon library="system" name="error-save"></webmodule-icon>`;
  render() {
    let icon = html``;

    switch (this._type) {
      case 'idle':
        icon = this._idle;
        break;
      case 'busy':
        icon = this._busy;
        break;
      case 'success':
        icon = this._success;
        break;
      case 'error':
        icon = this._error;
        break;
    }
    const legend = html`<p>
        <span>${this._idle}</span>
        <span>${tlang`Viewing/Editing`}</span>
      </p>
      <p>
        <span class="me-2">${this._busy}</span>
        <span>${tlang`Save in progress`}</span>
      </p>
      <p>
        <span class="me-2">${this._success}</span>
        <span class="me-2">${tlang`Saved`}</span>
      </p>
      <p>
        <span class="me-2">${this._error}</span>
        <span class="me-2">${tlang`Error saving`}</span>
      </p> `;
    return html`<webmodule-tooltip placement="left" class="table-header-tooltip" hoist>
      <div slot="content">
        <p>${legend}</p>
      </div>
      <div style="font-size:20px; padding-top:4px;">${icon}</div>
    </webmodule-tooltip>`;
  }
}
