import { ProjectContainer, ProjectContainerManager } from '../../projects/data/project-container';
import { EventBoolean } from '../../components/ui/events';
import { getApiFactory } from '../../api/api-injector';

export function getProjectContainer(id: string): ProjectContainer {
  return new ProjectContainer(id, null, null, null);
}

export function getProjectContainerManager(
  id: string | ProjectContainer,
  forceLocked?: EventBoolean
): ProjectContainerManager {
  const container = id instanceof ProjectContainer ? id : getProjectContainer(id);

  const manager = new ProjectContainerManager(container, getApiFactory().project());
  manager.forceLocked = forceLocked;
  return manager;
}
