import { showValidations } from './modal-validationhandler';

export async function checkValidations(errors: string[]): Promise<boolean> {
  if (errors.length != 0) {
    await showValidations(errors);
    return false;
  }
  return true;
}

/**
 *
 * @param value value to compare. if null, true is always returned
 * @param min
 * @param max
 */
export function inRange(value: number | null, min: number, max: number): boolean {
  if (value === null) return true;
  return min <= value && value <= max;
}
