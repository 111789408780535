import { ServiceResponseInvalid } from '@softtech/webmodule-data-contracts';
export const strInvalidBuildNumber = 'INVALID BUILD NUMBER';
export type ServiceResponseHandler = (response: ServiceResponseInvalid) => void;

let _error: ServiceResponseInvalid | null = null;
export function getLastApiError(): ServiceResponseInvalid | null {
  return _error;
}
export function setLastApiError(error: ServiceResponseInvalid | null) {
  _error = error;
}
