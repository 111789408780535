import { LitElement, html } from 'lit';
import { property } from 'lit/decorators.js';

export class InputBoolean extends LitElement {
  @property() checked = false;
  @property() readonly = false;
  @property() disabled = false;
  render() {
    const clickEvent = (e: Event) => {
      e.stopPropagation();
      e.preventDefault();
      if (this.readonly || this.disabled) return;
      this.checked = !this.checked;
      this.click();
    };
    const textColor = this.disabled || !this.checked ? 'text-secondary' : 'text-primary';
    return this.checked
      ? html`<i @click=${clickEvent} class="fa-solid fa-circle-dot ${textColor}"></i>`
      : html`<i @click=${clickEvent} class="fa-regular  fa-circle ${textColor}"></i>`;
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }
}
