import {
  InputUpdatePurchaseOrderStateChangeReason,
  PurchaseOrder,
  PurchaseOrderItem,
  PurchaseOrderState,
  ResultGetPurchaseOrder
} from '../../api/dealer-api-interface-franchisee';
import { getApiFactory } from '../../api/api-injector';
import { clone, compare } from '../../components/clone';
import { tlang } from '@softtech/webmodule-components';
import { fireQuickSuccessToast } from '../../toast-away';
import { cache } from '../../dealer-franchisee/cache/cache-registry';
import { getQuoteContainerManager } from '../../dealer-franchisee/quotes/quote-ui-adapter';
import { EventBoolean, EventNotify } from '../../components/ui/events';
import { NullPromise } from '../../null-promise';
import { DevelopmentError } from '../../development-error';
import { QuoteContainerManager } from '../../quotes/data/quote-container';
import { runEventNotify } from '../../components/array-helper';

export class PurchaseOrderContainer {
  purchaseOrderId: string;
  purchaseOrder: PurchaseOrder | null;
  items: PurchaseOrderItem[] | null;

  constructor(purchaseOrderId: string, purchaseOrder: PurchaseOrder | null, items: PurchaseOrderItem[] | null) {
    this.purchaseOrder = purchaseOrder;
    this.items = items;
    this.purchaseOrderId = purchaseOrderId;
  }
}

export class PurchaseOrderContainerManager {
  backup: PurchaseOrderContainer;
  container: PurchaseOrderContainer;
  api = getApiFactory().purchaseOrder();
  afterSave: EventNotify[] = [];
  forceLocked?: EventBoolean;
  stateChangeReason: InputUpdatePurchaseOrderStateChangeReason | null = null;
  private _quoteOwnerId?: string | undefined;
  private _quoteMananger?: QuoteContainerManager;

  public get quoteManager(): QuoteContainerManager {
    if (!this._quoteMananger) throw new DevelopmentError("quotemanager doesn't exist. not loaded");
    return this._quoteMananger;
  }
  public get quoteOwnerId(): string {
    if (!this._quoteOwnerId) throw new DevelopmentError('cannot call quoteOwnerId on Order Container before loading');
    return this._quoteOwnerId;
  }

  constructor(original: PurchaseOrderContainer) {
    if (original.purchaseOrder && original.purchaseOrder.id !== original.purchaseOrderId)
      throw new Error('Invalid container configuration. PurchaseOrderId must match purchaseOrderId');
    this.container = original;
    this.backup = clone(this.container);
  }

  get purchaseOrderId(): string {
    return this.container.purchaseOrderId;
  }

  protected async doAfterSave(): Promise<void> {
    await runEventNotify(this.afterSave);
  }

  public get purchaseOrder(): PurchaseOrder {
    if (!this.container.purchaseOrder) {
      throw new Error('Purchase order is empty');
    }

    return this.container.purchaseOrder;
  }

  public get purchaseOrderTitle(): string {
    return tlang`#${this.purchaseOrder.purchaseOrderNumber} - ${this.purchaseOrder.title}`;
  }
  isLockedFromUse(): boolean {
    return this.forceLocked?.() ?? false;
  }

  public isReadOnly() {
    return this.isLockedFromUse() || !(this.purchaseOrder.state == PurchaseOrderState.Draft);
  }
  async getFreshOrderCopy(): NullPromise<ResultGetPurchaseOrder> {
    return await this.api.getPurchaseOrder({
      purchaseOrderId: this.purchaseOrderId
    });
  }
  public async needsPurchaseOrder(): Promise<boolean> {
    const sortPurchaseOrderItems = async (result: ResultGetPurchaseOrder) => {
      const quoteManager = getQuoteContainerManager(result.purchaseOrder.branchQuoteId);
      await quoteManager.needsQuote();
      await quoteManager.needsQuoteItems();
      this._quoteMananger = quoteManager;
      this._quoteOwnerId = quoteManager.quote.quoteOwnerId;
      const sortedItems: PurchaseOrderItem[] = [];
      quoteManager.container.items?.forEach(item => {
        const orderItem = result.purchaseOrderItems.find(poi => poi.quoteItemId == item.id);
        if (orderItem) {
          sortedItems.push(orderItem);
        }
      });
      return sortedItems;
    };

    if (!this.container.purchaseOrder) {
      const result = await this.api.getPurchaseOrder({
        purchaseOrderId: this.purchaseOrderId
      });

      if (result) {
        const sortedItems = await sortPurchaseOrderItems(result);
        this.resetPurchaseOrder(result.purchaseOrder as unknown as PurchaseOrder, sortedItems);
        this.fetchCache();
      } else return false;
    }

    return true;
  }

  protected flushCaches() {
    cache().purchaseOrder.flush([this.purchaseOrderId]);
    cache().projectResourceLink.flush([this.purchaseOrderId]);
  }

  async fetchCache() {
    await Promise.allSettled([
      cache().purchaseOrder.preFetch([this.purchaseOrderId]),
      cache().projectResourceLink.preFetch([this.purchaseOrderId])
    ]);
  }

  public async savePurchaseOrder(silentSave = true): Promise<boolean> {
    this.flushCaches();
    const result = await this.api.updatePurchaseOrder({
      purchaseOrder: this.purchaseOrder,
      stateChangeReason: this.stateChangeReason
    });

    if (result) {
      this.resetPurchaseOrder(result.purchaseOrder, result.purchaseOrderItems);
      this.fetchCache();

      if (!silentSave) fireQuickSuccessToast(tlang`%%purchase-order%% saved`);

      await this.doAfterSave();

      return true;
    }

    return false;
  }

  public purchaseOrderChanged(): boolean {
    return !compare(this.backup.purchaseOrder, this.container.purchaseOrder);
  }

  public changed(): boolean {
    return !compare(this.backup, this.container);
  }

  protected resetPurchaseOrder(purchaseOrder: PurchaseOrder, purchaseOrderItems: PurchaseOrderItem[]) {
    this.container.purchaseOrder = purchaseOrder;
    this.container.items = purchaseOrderItems;

    this.backup.purchaseOrder = clone(purchaseOrder);
    this.backup.items = clone(purchaseOrderItems);
  }
}
