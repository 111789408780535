import { getCurrentUser } from '@softtech/webmodule-components';
import { getApiFactory } from '../../api/api-injector';
import { InputLockResource, InputUnLockResource, ResourceLock } from '../../api/dealer-api-interface-franchisee';
import { newGuid } from '../../api/guid';
import { validId } from '../../components/ui/helper-functions';
import { NullPromise } from '../../null-promise';

const sessionLockKey = 'dealer:sessionlock';
export function getSessionLock(): string {
  let lock = sessionStorage.getItem(sessionLockKey) ?? '';
  if (!validId(lock)) {
    lock = newGuid();
    console.log('SessionLockCreated ' + lock);
    sessionStorage.setItem(sessionLockKey, lock);
  }
  return lock;
}
export function isOptimisticLockOwner(lock: ResourceLock | null): boolean {
  return lock !== null && lock?.sessionLock === getSessionLock();
}

export async function optimisticLock(resourceType: string, resourceId: string): NullPromise<ResourceLock> {
  //we should never get here without a user, but lets just refuse the lock;
  if (!getCurrentUser()) return null;
  //we dont need this as its done in application startup
  //await userDataStore.loadCoreDetails();

  const input: InputLockResource = {
    resourceId,
    resourceType,
    sessionLock: getSessionLock()
  };
  const result = await getApiFactory().franchisee().lockResource(input);
  return result?.lock ?? null;
}

export async function optimisticLockRelease(resourceId: string): NullPromise<ResourceLock> {
  //if the user logged off, then their lock will hold for the duration
  if (!getCurrentUser()) return null;
  //we dont need this as its done in application startup
  //await userDataStore.loadCoreDetails();

  const input: InputUnLockResource = {
    resourceId,
    sessionLock: getSessionLock()
  };
  const result = await getApiFactory().franchisee().unlockResource(input);
  return result?.lock ?? null;
}
