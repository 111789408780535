import {
  Quote,
  QuoteItem,
  QuoteItemType,
  QuoteState,
  QuoteType,
  ViewQuoteSummary
} from '../../api/dealer-api-interface-quote';
import { lang, tlang } from '@softtech/webmodule-components';
import { html, TemplateResult } from 'lit';
import { isEmptyOrSpace } from '../../components/ui/helper-functions';
import { quoteItemContentType } from './default-quote-item-content-type';
import { supplierQuoteItemContentType } from './supplier-quote-item-content-type';

export const processQuoteItemState = {
  updating: 'updating',
  validating: 'validating',
  completed: 'completed',
  finalizing: 'finalizing',
  errors: 'errors'
};

export const getQuoteNumberSuffix = (q: ViewQuoteSummary | Quote | null, includeState = true) => {
  if (!q) return '';
  const suffix = isEmptyOrSpace(q.quoteSuffix) ? '    ' : `-${q.quoteSuffix.padStart(3, ' ')}`;

  return q.quoteNumber == 0
    ? includeState
      ? tlang`Draft`
      : ''
    : `${q.quoteNumber.toString().padStart(6, '0')}${suffix}`;
};
export const getQuoteNumberFormatted = (q: ViewQuoteSummary | Quote | null | undefined, includeState = true) => {
  if (!q) return '';
  const suffix = isEmptyOrSpace(q.quoteSuffix) ? '    ' : `-${q.quoteSuffix.padStart(3, ' ')}`;

  const prefix =
    q.quoteType === QuoteType.Estimate
      ? tlang`%%estimate-abbrev%%`
      : q.quoteType === QuoteType.Quote
        ? tlang`%%quote-abbrev%%`
        : q.quoteType === QuoteType.Display
          ? tlang`%%display-sample-abbrev%%`
          : tlang`%%warranty-item-abbrev%%`;
  return q.quoteNumber == 0
    ? includeState
      ? tlang`Draft`
      : ''
    : `${prefix}: ${q.quoteNumber.toString().padStart(6, '0')}${suffix}`;
};

export const internalExtendedListPriceField = 'extendedListPrice';
export const internalMultiplierField = 'multiplier';
export const internalPriceAdjustmentSupplierField = 'priceAdjustmentSupplier';
export const internalDealerCostField = 'dealerCost';
export const internalMarginField = 'margin';
export const internalPriceAdjustmentField = 'priceAdjustment';

export const internalQuoteItemFields = [
  internalExtendedListPriceField,
  internalMultiplierField,
  internalPriceAdjustmentSupplierField,
  internalDealerCostField,
  internalMarginField,
  internalPriceAdjustmentField
];

export const getQuoteTypeMap = () => {
  return {
    '1': tlang`%%estimate%%`,
    '2': tlang`%%quote%%`,
    '4': tlang`%%display-sample%%`,
    '8': tlang`%%warranty-item%%`
  };
};

export function getQuoteItemTab(title: string, itemPosition?: number): TemplateResult {
  if (itemPosition === undefined)
    return html` <div class="tab-caption-block"><span class="tab-caption">${title}</span></div>`;
  return html` <div class="tab-caption-block">
    <span class="tab-pos">#${itemPosition}</span><span class="tab-caption">${title}</span>
  </div>`;
}

export const getQuoteTitle = (q: ViewQuoteSummary | Quote | null | undefined, state?: QuoteState) => {
  if (!q) return html``;
  const quote = q as Quote;
  let quoteNumber = getQuoteNumberFormatted(quote, false);
  if (!isEmptyOrSpace(quoteNumber)) quoteNumber += ' ';
  return html`${quoteNumber}${quote.title} ${getQuoteStatus(state ?? quote.state, true)}`;
};

export const getQuoteTitleStr = (q: ViewQuoteSummary | Quote | null | undefined, state?: QuoteState) => {
  if (!q) return html``;
  const quote = q as Quote;
  let quoteNumber = getQuoteNumberFormatted(quote, false);
  if (!isEmptyOrSpace(quoteNumber)) quoteNumber += ' ';
  return `${quoteNumber}${quote.title} ${getQuoteStatusStr(state ?? quote.state)}`;
};
export const getQuoteStatusStr = (state: number) => {
  const value = QuoteState[state].toLowerCase();
  const text = `%%quote-state-${value}%%`;

  return lang(text);
};

export const getQuoteStatus = (state: number, asBadge = false, asRaw = false) => {
  if (!asBadge) return html`${QuoteState[state]}`;

  const value = QuoteState[state].toLowerCase();
  const text = `%%quote-state-${value}%%`;

  return asRaw
    ? `<span class="badge badge-${value}">${lang(text)}</span>`
    : html`<span class="badge badge-${value}">${lang(text)}</span>`;
};

export function htmlEncode(value: string): string {
  return value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
}

export function quoteItemThumbSrc(item: QuoteItem): string {
  let src = '';
  if (isShipping(item)) {
    src = './assets/images/delivery.svg';
  } else if (isFreehand(item)) {
    src = './assets/images/freehand.svg';
  } else if (isSpecialItem(item)) {
    src = './assets/images/special-item.svg';
  } else if (isSSI(item)) {
    src = './assets/images/freehand-ssi.svg';
  } else src = './assets/images/freehand.svg';
  return src;
}

export function isShipping(quoteItem: QuoteItem) {
  return (
    (quoteItem.itemType === QuoteItemType.Basic && quoteItem.quoteItemContentType === quoteItemContentType.shipping) ||
    isSSI(quoteItem, quoteItemContentType.Reserved_ServerSideShippingItem)
  );
}

export function isDefaultShipping(quoteItem: QuoteItem) {
  return quoteItem.itemType === QuoteItemType.Basic && quoteItem.quoteItemContentType === quoteItemContentType.shipping;
}

export function isFreehand(quoteItem: QuoteItem) {
  return quoteItem.itemType === QuoteItemType.Basic && quoteItem.quoteItemContentType === quoteItemContentType.freehand;
}

export function isSpecialItem(quoteItem: QuoteItem) {
  return (
    quoteItem.itemType === QuoteItemType.Basic && quoteItem.quoteItemContentType === quoteItemContentType.specialItem
  );
}

export const serverSideContentTypeOffset = quoteItemContentType.ServerSideContentTypeOffset;
export const serverSideShippingItem = quoteItemContentType.Reserved_ServerSideShippingItem;
export const serverSideReservedMax = quoteItemContentType.ServerSideReservedMax;
export const serverSideUserReservedMax = quoteItemContentType.ServerSideUserReservedMax;

export function isContentTypeSSI(aType: number) {
  return aType >= serverSideContentTypeOffset && aType <= serverSideUserReservedMax;
}

export function isSSI(quoteItem?: QuoteItem | null, ofSpecificType?: number) {
  if (!quoteItem) return false;
  const valid =
    quoteItem.itemType === QuoteItemType.Basic &&
    quoteItem.quoteItemContentType >= serverSideContentTypeOffset &&
    quoteItem.quoteItemContentType <= serverSideUserReservedMax;
  return ofSpecificType ? valid && quoteItem.quoteItemContentType === ofSpecificType : valid;
}

export function isFrame(quoteItem: QuoteItem) {
  return (
    quoteItem.itemType === QuoteItemType.Provider &&
    quoteItem.quoteItemContentType === supplierQuoteItemContentType.CID_FRAM
  );
}

export function isSupplierItem(quoteItem: QuoteItem) {
  return isFrame(quoteItem) || isSpecialItem(quoteItem);
}

export function mustAppearOnPurchaseOrder(quoteItem: QuoteItem) {
  return !isFreehand(quoteItem) && !isShipping(quoteItem);
}

export function canAppearOnSupplierOrder(quoteItem: QuoteItem) {
  //return isSupplierItem(quoteItem) || isSSI(quoteItem) || isShipping(quoteItem);
  return !isFreehand(quoteItem);
}
