import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import { getApiFactory } from '../../../api/api-injector';
import { QuoteState, ViewQuoteTotalByState } from '../../../api/dealer-api-interface-quote';
import { emptyGuid } from '../../../api/guid';
import { moneyToTemplateResult } from '../../../components/currency-formatter';
import { Snippet } from '../../../components/ui/events';
import { tlang } from '@softtech/webmodule-components';
import { dashboard } from '../data/dashboard-cache';
import { DashboardWidget } from './dashboard-widget';
import { PromiseSnippet } from '@softtech/webmodule-data-contracts';
import { awaitableTemplate } from '../../../components/ui/template-processor';

@customElement('dashboard-branch-monthly-revenue-bystate')
export class BranchMonthlyRevenueByState extends DashboardWidget {
  api = getApiFactory().quote();
  @property() branchId? = emptyGuid;
  @property() assignedToUserId?: string;
  @property() state = 0;

  protected async getData(): Promise<ViewQuoteTotalByState[]> {
    const state = this.state;
    await dashboard().needsMonthlyQuoteFinancials(this.branchId);
    return dashboard().getMonthlyQuoteFinancials(state, this.assignedToUserId);
  }

  protected getHeader(): Snippet {
    return tlang`Monthly Generated Revenue ${QuoteState[this.state]}`;
  }
  protected getQuoteLabel(): Snippet {
    if (this.state >= QuoteState.Approved) return tlang`!!order!!`.toUpperCase();
    return tlang`!!quote!!`.toUpperCase();
  }

  protected getBody(): Snippet {
    return html`${awaitableTemplate(this.getTemplate(), 'Loading...')}`;
  }

  protected async getTemplate(): PromiseSnippet {
    const data = await this.getData();
    let quoteCount = 0;
    const total = data?.reduce((prev, x) => prev + x.total, 0) ?? 0;
    data?.forEach(x => (quoteCount += x.quoteCount));
    return html`
      <div class="card-subtitle text-secondary">${quoteCount} ${this.getQuoteLabel()}</div>
      <div class="display-5 text-primary">${moneyToTemplateResult(total)}</div>
    `;
  }
}
