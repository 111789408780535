// eslint-disable-next-line import/named
import { html, TemplateResult } from 'lit';
import { DataBinding } from '../databinding/databinding';
import { DataTracker } from '../databinding/data-tracker';
import { ifDefined } from 'lit/directives/if-defined.js';

export type FormDisplayFormat = (value: any) => string;

//TODO It might be safer to use an Enum for the type (will show dev the options then and limit spelling mistakes)
export function formInput(
  fieldName: string,
  title: string,
  dataBinding: DataBinding,
  dataTracker: DataTracker,
  type = 'text',
  readOnly = false,
  required = false,
  maxLength?: number,
  decimalPlace?: number,
  classes?: string,
  toolTip?: string,
  min?: number,
  max?: number,
  placeholder?: string
): TemplateResult {
  const dateTimeMax =
    type == 'date'
      ? '9999-12-30'
      : type == 'datetime-local'
        ? '9999-12-30T23:59'
        : type == 'time'
          ? '23:59'
          : undefined;
  let dateTimeMin: string | undefined;
  if (min) {
    dateTimeMin = new Date(min).toISOString();
    if (type == 'date') {
      dateTimeMin = dateTimeMin.split('T')[0];
    } else if (type == 'time') {
      dateTimeMin = dateTimeMin.split('T')[1];
    }
  }
  let value: string | null = null;
  switch (type) {
    case 'area':
      return html` <bs-form-area
        class=${classes ?? ''}
        data-id=${dataBinding.field(fieldName)}
        type=${type}
        .value=${dataTracker.getObjectDisplayValue(fieldName)}
        ?required=${required}
        data-label=${title}
        ?readonly=${readOnly}
        maxlength="${ifDefined(maxLength)}"
        toolTip="${ifDefined(toolTip)}"
        data-placeholder="${ifDefined(placeholder)}"
      >
      </bs-form-area>`;
    case 'checkbox':
      return html` <bs-form-checkbox
        class=${classes ?? ''}
        data-id=${dataBinding.field(fieldName)}
        ?readonly=${readOnly}
        ?checked=${dataTracker.getObjectValue(fieldName)}
        ?required=${required}
        data-label=${title}
        toolTip="${ifDefined(toolTip)}"
      >
      </bs-form-checkbox>`;
    case 'hidden':
      return html`<input
        class=${classes ?? ''}
        type="hidden"
        id=${dataBinding.field(fieldName)}
        value="${dataTracker.getObjectDisplayValue(fieldName)}"
      />`;
    case 'money':
      return html` <bs-form-money
        class=${classes ?? ''}
        data-id=${dataBinding.field(fieldName)}
        dp=${decimalPlace ?? 2}
        maxlength="${ifDefined(maxLength)}"
        ?readonly=${readOnly}
        .value=${dataTracker.getObjectDisplayValue(fieldName)}
        ?required=${required}
        data-label=${title}
        toolTip="${ifDefined(toolTip)}"
      >
      </bs-form-money>`;
    case 'date':
    case 'datetime-local':
    case 'time':
      value = dataTracker.getObjectDisplayValue(fieldName);
      if (readOnly && value === null) {
        type = 'text';
        value = '';
      }
      return html` <bs-form-input
        class=${classes ?? ''}
        data-id=${dataBinding.field(fieldName)}
        min="${ifDefined(dateTimeMin)}"
        max="${ifDefined(dateTimeMax)}"
        type=${type}
        ?readonly=${readOnly}
        .value=${dataTracker.getObjectDisplayValue(fieldName)}
        ?required=${required}
        data-label=${title}
        toolTip="${ifDefined(toolTip)}"
      >
      </bs-form-input>`;
    default:
      return html` <bs-form-input
        class=${classes ?? ''}
        data-id=${dataBinding.field(fieldName)}
        maxlength="${ifDefined(maxLength)}"
        min="${ifDefined(min)}"
        max="${ifDefined(max)}"
        type=${type}
        ?readonly=${readOnly}
        .value=${dataTracker.getObjectDisplayValue(fieldName)}
        ?required=${required}
        data-label=${title}
        toolTip="${ifDefined(toolTip)}"
      >
      </bs-form-input>`;
  }
}
