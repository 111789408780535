// eslint-disable-next-line import/named
import { html } from 'lit';
import { BranchQuote, BranchQuoteSupportItemType } from '../../../api/dealer-api-interface-franchisee';
import { EventTemplate, Snippet } from '../../../components/ui/events';
import { FranchiseeQuoteContainerManager } from '../data/franchisee-quote-manager';
import { ViewBase } from '../../../components/ui/view-base';
import { tlang } from '@softtech/webmodule-components';
import { flagInSet } from '../../../components/ui/helper-functions';
import { QuoteState } from '../../../api/dealer-api-interface-quote';

import { OpenTicketHandler, WebModuleQuoteSupportInboxView } from '../../conversation/wm-branchquote-support';
import { WebModuleBranchQuoteConversationEditor } from '../../conversation/wm-branchquote-support-editor';
import { customElement } from 'lit/decorators.js';

export interface CreateTicketHandler {
  handled: boolean;
  type: BranchQuoteSupportItemType;
}

export interface FranchiseeQuoteSupportViewOptions {
  quoteManager: FranchiseeQuoteContainerManager;
  openTicketHandler: (event: OpenTicketHandler) => void;
  createTicketHandler: (event: CreateTicketHandler) => void;
}
@customElement('wm-franchiseequotesupportview')
export class FranchiseeQuoteSupportView extends ViewBase {
  private _addRequestType: BranchQuoteSupportItemType = BranchQuoteSupportItemType.All;
  protected get addRequestType(): BranchQuoteSupportItemType {
    return this._addRequestType;
  }
  protected set addRequestType(value: BranchQuoteSupportItemType) {
    if (value !== this._addRequestType) {
      this._addRequestType = value;
      this.requestUpdate();
      this.dispatchUiChanged();
    }
  }
  get supportElement() {
    return this.ui.querySelector('#support') as WebModuleQuoteSupportInboxView;
  }

  get newRequestEditor() {
    return this.ui.querySelector('#newRequest') as WebModuleQuoteSupportInboxView;
  }

  get activeTicketEditor() {
    return this.ui.querySelector('#ticketeditor') as WebModuleBranchQuoteConversationEditor;
  }

  refreshTable() {
    this.supportElement?.table?.fetchEvent();
  }

  async addAssistanceRequest() {
    const result: CreateTicketHandler = {
      handled: false,
      type: BranchQuoteSupportItemType.QuoteAssistanceRequest
    };
    this.options.createTicketHandler?.(result);

    if (!result.handled) this.addRequestType = BranchQuoteSupportItemType.QuoteAssistanceRequest;
  }
  buttonMenu(): Snippet {
    if (this.isReadonly) return html``;

    if (this.addRequestType !== BranchQuoteSupportItemType.All)
      return html`
        <button @click=${() => (this.addRequestType = BranchQuoteSupportItemType.All)} class="btn secondary-btn">
          ${tlang`Discard`}
        </button>
      `;

    const supportElem = this.supportElement;
    if (supportElem && supportElem.activeTicket)
      return html`
        <button @click=${() => (supportElem.activeTicket = undefined)} class="btn secondary-btn">
          ${tlang`Discard`}
        </button>

        ${this.activeTicketEditor.branchSupportActionsTemplate()}
      `;

    return html``;
  }
  options: FranchiseeQuoteSupportViewOptions;
  get quoteManager(): FranchiseeQuoteContainerManager {
    return this.options.quoteManager as FranchiseeQuoteContainerManager;
  }

  constructor(options: FranchiseeQuoteSupportViewOptions) {
    super();
    this.options = options;
  }
  protected get branchQuote(): BranchQuote | null {
    return this.quoteManager.branchQuote;
  }
  protected get isReadonly() {
    //this is a different readonly from normal, as this relates specifically to
    //support inbox, which is not the same as other things.
    const readonly = flagInSet(
      this.quoteManager.quote.state,
      QuoteState.Cancelled | QuoteState.Rejected | QuoteState.Approved
    );
    return readonly;
  }
  protected normalTemplate() {
    const eventOpenTicket = (e: CustomEvent<OpenTicketHandler>) => {
      this.options.openTicketHandler?.(e.detail);
    };
    const eventActivateTicket = (_e: CustomEvent<OpenTicketHandler>) => {
      this.dispatchUiChanged(); //trigger page control buttons
    };

    const eventRefreshParent = () => {
      this.dispatchUiChanged();
    };

    return html`<wmview-branchquote-support
      id="support"
      .readonly=${this.isReadonly}
      .quoteManager=${this.quoteManager}
      .supplierName=${this.quoteManager.supplierName}
      @wm-event-open-ticket=${eventOpenTicket}
      @wm-event-activateticket=${eventActivateTicket}
      @wm-event-changed=${eventRefreshParent}
    ></wmview-branchquote-support>`;
  }
  protected addRequestTemplate() {
    const eventCreated = () => {
      this.addRequestType = BranchQuoteSupportItemType.All;
    };
    const eventCancelled = () => {
      this.addRequestType = BranchQuoteSupportItemType.All;
    };
    return html`<wmview-branchquote-support-new
      id="newrequest"
      .quoteManager=${this.quoteManager}
      .supportType=${this.addRequestType}
      .showButtons=${true}
      .showCancelButton=${false}
      .supplierName=${this.quoteManager.supplierName}
      @wm-event-created=${eventCreated}
      @wm-event-cancel=${eventCancelled}
    ></wmview-branchquote-support-new>`;
  }
  protected template(): EventTemplate {
    return this.addRequestType === BranchQuoteSupportItemType.All ? this.normalTemplate() : this.addRequestTemplate();
  }
}
