import { sameText } from './ui/helper-functions';
import { goStaticURL } from './ui/resource-resolver';

// eslint-disable-next-line no-var -- var declared as it is replaced by
declare var __localdebug__: string;
const usedebugv6 = __localdebug__;
const isv6Debugging = usedebugv6 == 'true';
const localBuildNumber = __webmodulebuildnumber__;
const isDebugging = sameText('local', localBuildNumber);

declare var __webmodulebuildnumber__: string;

export function isDebugMode(): boolean {
  return isDebugging;
}
export function isv6DebugMode(): boolean {
  return isv6Debugging;
}

export function getLocalBuildNumber(): string {
  return localBuildNumber;
}

let debugOutofdate = false;
export function isDebugForcedOutOfDate(): boolean {
  return debugOutofdate;
}
export function appOutOfDate(buildNumber?: string): boolean {
  if (debugOutofdate) {
    return true;
  }
  return (
    !sameText(getLocalBuildNumber(), buildNumber ?? globalThis.dealerConfiguration?.apiBuildNumberDisplay) &&
    !isDebugMode()
  );
}

export function getServerBuildNumberDisplay() {
  return globalThis.dealerConfiguration?.apiBuildNumberDisplay ?? '';
}

export async function checkAppOutOfDate() {
  await globalThis.dealerRefreshApiDetails?.();
}
export const outOfDateURL = '/outofdate';
globalThis.forceOutOfDate = (value = true, reRoute = true) => {
  debugOutofdate = value;
  if (reRoute) goStaticURL(outOfDateURL);
};

globalThis.isAppOutOfDate = (buildNumber?: string) => {
  return appOutOfDate(buildNumber);
};
