import { ProjectDetailView, ProjectDetailViewOptions } from '../../../projects/views/project-detail-view';
import { ClientApi } from '../../../api/client-api';
import { FieldType } from '../../../components/ui/databinding/data-tracker';
// eslint-disable-next-line import/named
import { html } from 'lit';
import { ClientPicker } from '../../../clients/views/client-picker';
import { emptyGuid } from '../../../api/guid';
import { ContactPicker } from '../../../clients/views/contact-picker';
import { fireQuickInformationToast, fireQuickSuccessToast, fireQuickWarningToast } from '../../../toast-away';
import { tlang } from '@softtech/webmodule-components';
import { PaymentProfilePicker } from '../../../franchisee/view/payment-profile-picker';
import { PaymentProfileApi } from '../../../api/payment-profile-api';
import { isEmptyOrSpace, validId } from '../../../components/ui/helper-functions';
import { FranchiseeClient } from '../../../api/dealer-api-interface-franchisee';
import { getApiFactory } from '../../../api/api-injector';
import '../../../components/ui/maps/google-place-autocomplete';
import '../../../components/ui/maps/google-map';
import { emptyAddress } from '../../../components/ui/maps/map-helpers';
import { cache } from '../../cache/cache-registry';
import { EventTemplate } from '../../../components/ui/events';
import { FormInputAssistant } from '../../../components/ui/templateresult/form-input-assistant';
import { DevelopmentError, showDevelopmentError } from '../../../development-error';
import { ProjectState, ResourceType } from '../../../api/dealer-api-interface-project';
import { userDataStore } from '../../common/current-user-data-store';
import { constructAsync } from '../../../async-constructor';
import { FranchiseeClientView } from '../../clients/views/franchisee-client-view';
import { createNewClient } from '../../clients/data/franchisee-client-container';
import { Client } from '../../../api/dealer-api-interface-client';
import { lockUIandExecute } from '../../../ui-lock';
import { determineProjectShippingAddress } from '../data/project-helper-functions';
import { clientContainerManagerFactory } from '../../clients/client-ui-adaptor';
import { AddressEditor } from '../../../components/ui/address-editor';
import { clone } from '../../../components/clone';
import { TenantLoginPublicInfo } from '../../../api/dealer-api-interface-user';
import { getBranchUserCache } from '../../cache/dealer-branch-user-cache';
import { AskConfirmation, buttonsYesNo } from '../../../components/ui/modal-confirmation';
import { QuoteLock, getQuoteContainerManager } from '../../quotes/quote-ui-adapter';
import { isAutoSaving, saveWithIndicator } from '../../../components/save-workflow';
import { showValidations } from '../../../components/ui/modal-validationhandler';
import { showError } from '../../../components/ui/show-error';
import { QuoteState } from '../../../api/dealer-api-interface-quote';
import { customElement, state } from 'lit/decorators.js';
import { awaitableTemplate } from '../../../components/ui/template-processor';

@customElement('wm-franchiseeprojectdetailview')
export class FranchiseeProjectDetailView extends ProjectDetailView {
  clientApi: ClientApi = getApiFactory().client();
  paymentProfileApi: PaymentProfileApi = getApiFactory().paymentProfile();

  branchUserCache = getBranchUserCache();

  clientCache = cache().client;
  contactCache = cache().contact;
  primaryContactCache = cache().primaryContact;
  paymentProfileCache = cache().paymentProfile;

  clientPickerDisplay = '';
  contactPickerDisplay = '';
  clientTypePickerDisplay = '';

  currentAuthorId = emptyGuid;
  @state()
  private _canCreateClient: boolean = false;

  constructor(options: ProjectDetailViewOptions) {
    super(options);

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.project),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('clientId');
    addField('contactId');
    addField('clientTypeId');
    addField('assignedToUserId');
  }

  async resetEditControls() {
    await super.resetEditControls();
    await this.loadPickerDisplaysFromCache();

    this.requestUpdate();
  }

  async afterConstruction(): Promise<void> {
    await super.afterConstruction();
    await this.branchUserCache.prefetch();
    await this.projectManager.needsProject();
    await this.branchUserCache.addUsersToBranch(userDataStore.defaultBranch.id, [
      this.project.assignedToUserId,
      this.project.creationUserId,
      this.project.lastModifiedUserId
    ]);
  }

  async loadOrRefresh(): Promise<void> {
    await super.loadOrRefresh();

    await this.loadPickerDisplaysFromCache();

    this.currentAuthorId = this.project.assignedToUserId;
    this._canCreateClient = await this.projectManager.canChangeClient();
    this.requestUpdate();
  }

  getValidationErrors(): string[] {
    const errors = super.getValidationErrors();

    if (!this.project) {
      throw new DevelopmentError('Project is null');
    }
    if (this.project.state == ProjectState.Active) {
      const client = this.project.clientId;
      const contact = this.project.contactId;
      const clientType = this.project.clientTypeId;

      if (isEmptyOrSpace(client)) errors.push(tlang`Please select a %%client%%`);

      if (isEmptyOrSpace(contact)) errors.push(tlang`Please select a %%contact%%`);

      if (isEmptyOrSpace(clientType)) errors.push(tlang`Please select a %%payment-profile%%`);
    }

    return errors;
  }

  async createNewClient() {
    const title = tlang`Add %%client%%`;
    const container = await createNewClient(userDataStore.clientOwnerId);
    if (!container) {
      await showDevelopmentError('Client could not be created');
      return;
    }
    const qm = clientContainerManagerFactory(container);

    const qv = await constructAsync(
      new FranchiseeClientView({
        viewTitle: () => title,
        clientContainerManager: qm
      })
    );
    await qv.showModal();
    const client = qv.view?.clientContainer.client;
    if (client && validId(client.id)) {
      this.selectClient(client);
    }
  }

  protected template(): EventTemplate {
    const forms = new FormInputAssistant(this.dataTracker, this.projectManager.isReadonly());
    const allowCreateClient = !this.projectManager.isReadonly() && this._canCreateClient;
    if (allowCreateClient) forms.classes.push({ id: 'clientId', classes: 'shortstop-edit' });

    const clientPicker = awaitableTemplate(async () => {
      return (await this.projectManager.canChangeClient())
        ? forms.pickerRequired(
            'clientId',
            this.clientPickerDisplay,
            async () => await this.selectClient(),
            tlang`%%client%%`
          )
        : forms.pickerReadonly('clientId', this.clientPickerDisplay, tlang`%%client%%`);
    });
    const contactPicker = forms.pickerRequired(
      'contactId',
      this.contactPickerDisplay,
      async () => await this.selectContact(),
      tlang`%%contact%%`
    );

    const clientTypePicker = forms.pickerRequired(
      'clientTypeId',
      this.clientTypePickerDisplay,
      async () => await this.selectClientType(),
      tlang`%%payment-profile%%`
    );

    const createClientEvent = async () => lockUIandExecute(async () => await this.createNewClient());
    const createClientTemplate = awaitableTemplate(async () => {
      return allowCreateClient
        ? html` <button
            @click=${createClientEvent}
            class="btn btn-primary shortstop-edit"
            type="button"
            id=${forms.id('create-client')}
          >
            ${tlang`New`}
          </button>`
        : html``;
    });
    const addressTemplate1 = html`<wm-addresseditor
      @wm-ae-changed=${(e: Event) => this.addressModified(e.currentTarget as AddressEditor)}
      id="physicalAddressEditor"
      .address=${this.project.defaultAddress}
      .shippingNotes=${this.project.shippingNotes}
      .title=${tlang`%%project%% Address`}
      .readonly=${this.projectManager.isReadonly()}
      .isDefaultShippingVisible=${true}
      .isDefaultShipping=${this.project.shipToDefaultAddress}
    >
    </wm-addresseditor>`;

    const users = this.branchUserCache.getBranchUsers(userDataStore.defaultBranch.id);
    const userToOption = (u: TenantLoginPublicInfo) => ({ text: u.friendlyName ?? '', value: u.id, disabled: false });

    return html`
      <form class="form-two-col">
        <div class="row">
          <div>
            ${forms.textRequired('title', tlang`Title`, 100)} ${clientPicker} ${createClientTemplate} ${contactPicker}
            ${clientTypePicker}
          </div>
          <div>
            <bs-form-select
              data-id=${this.dataBinding.field('assignedToUserId')}
              data-placeholder=${tlang`Author`}
              data-label=${tlang`Author`}
              ?readonly=${this.projectManager.isReadonly()}
              ?disabled=${this.projectManager.isReadonly()}
              .value=${this.project.assignedToUserId}
              .options=${users.map(userToOption)}
              @wm-select-changed=${_ => this.onAssignedToUserSelected(_.detail)}
            >
            </bs-form-select>
            ${forms.money('budget', tlang`Budget`, 2)} ${forms.note('description', tlang`Description`, 2950)}
          </div>
        </div>
        ${addressTemplate1}
      </form>
    `;
  }

  protected async onAssignedToUserSelected(assignedToUserId: string) {
    const projectAuthor = this.project.assignedToUserId;
    if (assignedToUserId === projectAuthor) {
      return;
    }
    const quoteResources = this.projectManager.container.resources?.filter(r => r.typeOf === ResourceType.Quote);
    if (!quoteResources || quoteResources.length == 0) {
      return;
    }

    this.dataBinding.setValue('assignedToUserId', assignedToUserId);

    const saveResult = await this.internalSave();
    if (!saveResult) {
      return;
    }
    const quoteIds = quoteResources.map(qr => qr.resourceId);
    if (quoteIds.length > 0) {
      const existingQuotesNeedAuthorChange = await AskConfirmation(
        tlang`Would you like the new Author of the %%project%% to inherit the ownership of all the Active !!quote!! in this Project?`,
        buttonsYesNo(),
        undefined,
        tlang`New %%project%% Author`
      );
      if (existingQuotesNeedAuthorChange) {
        const quoteIdsToUpdate: string[] = [];
        const quoteLocks: QuoteLock[] = [];
        const lockedQuotes: { quoteTitle: string; username: string }[] = [];

        if (quoteIds.length > 0) {
          const notifyLockedQuotes = () => {
            const lockedQuoteText = lockedQuotes
              .map(x => tlang`<b>${x.quoteTitle}</b> is currently being edited by <b>${x.username}</b>.`)
              .join('<br/>');
            fireQuickWarningToast(
              tlang`The system cannot change authorship of locked quotes.<br/>${lockedQuoteText}`,
              7000
            );
          };

          try {
            for (const quoteId of quoteIds) {
              const qcm = getQuoteContainerManager(quoteId);
              await qcm.needsQuote();
              if (qcm.quoteState == QuoteState.Active) {
                const quoteLock = new QuoteLock(quoteId, async () => {});
                await quoteLock.lock();
                if (quoteLock.canUseResource && quoteLock.isLockOwner) {
                  quoteLocks.push(quoteLock);
                  quoteIdsToUpdate.push(quoteId);
                } else {
                  lockedQuotes.push({ quoteTitle: qcm.quoteTitle, username: quoteLock.lockOwnerName });
                }
              }
            }
            if (quoteIdsToUpdate.length > 0) {
              const result = await getApiFactory()
                .quote()
                .setAuthorForQuotes({ userId: assignedToUserId, quoteIds: quoteIdsToUpdate });
              if (result) {
                fireQuickSuccessToast(tlang`%%quote%% authors have been changed`, 5000);
                if (lockedQuotes.length > 0) {
                  notifyLockedQuotes();
                }
              } else {
                fireQuickWarningToast(tlang`Unable to change %%quote%% authors`);
              }
            } else {
              if (lockedQuotes.length > 0) {
                notifyLockedQuotes();
              } else {
                fireQuickInformationToast(tlang`No !!quote!! require updating`);
              }
            }
          } catch (e) {
            showError(e as Error);
          } finally {
            for (const quoteLock of quoteLocks) {
              await quoteLock.release();
            }
          }
        }
      }
    }

    this.requestUpdate();
  }

  protected async internalSave(): Promise<boolean> {
    await this.prepareForSave();
    const validations = this.getValidationErrors();
    if (validations.length > 0) {
      await showValidations(validations);
      return false;
    }
    const result = await saveWithIndicator(async () => await this.projectManager.saveProject(isAutoSaving()));
    return result;
  }

  addressModified(addressEditor: AddressEditor) {
    this.project.defaultAddress = addressEditor.address ? clone(addressEditor.address) : emptyAddress();

    this.project.shipToDefaultAddress = addressEditor.isDefaultShipping;

    this.project.shippingNotes = addressEditor.shippingNotes ? addressEditor.shippingNotes : '';
  }

  private async loadPickerDisplaysFromCache() {
    this.clientPickerDisplay = (await this.clientCache.get(this.project.clientId))?.displayValue ?? '';
    this.contactPickerDisplay = (await this.contactCache.get(this.project.contactId))?.displayValue ?? '';
    this.clientTypePickerDisplay = (await this.paymentProfileCache.get(this.project.clientTypeId))?.displayValue ?? '';
  }

  private async selectClient(client?: Client) {
    const selectedClient = client?.id ?? (await ClientPicker())?.id;

    if (!selectedClient) {
      return;
    }

    this.dataBinding.setValue('clientId', selectedClient);
    const clientCacheItem = await this.clientCache.get(selectedClient);
    const clientData = clientCacheItem?.data;
    if (!clientData) return;

    //Getting here: we have a client selected and loaded from the cache
    this.clientPickerDisplay = clientCacheItem?.displayValue ?? '';
    const franchiseeClient = clientData.franchiseeClient as FranchiseeClient;
    if (!userDataStore.defaultBranch) await userDataStore.loadCoreDetails();

    //TODO: don't default project address to franchisee for now, we'll revisit this later in task 213557
    const addressToUse = determineProjectShippingAddress(
      undefined,
      false,
      clientData.client.physicalAddress,
      clientData.client.shipToPhysicalAddress
    );
    console.log('Getting address', addressToUse);

    this.project.defaultAddress = addressToUse;

    const primaryContact = await this.primaryContactCache.getData(selectedClient);
    this.dataBinding.setValue('contactId', primaryContact?.contactId ?? emptyGuid);
    const contact = await this.contactCache.get(primaryContact?.contactId ?? emptyGuid);
    this.contactPickerDisplay = contact?.displayValue ?? '';

    const defaultPaymentProfile = await this.paymentProfileCache.get(franchiseeClient.paymentProfileId);

    if (defaultPaymentProfile && defaultPaymentProfile.id !== this.dataBinding.getValue('clientTypeId')) {
      await this.updateClientTypeAndPickerDisplay(defaultPaymentProfile.id);
    }

    this.requestUpdate();
  }

  private async updateClientTypeAndPickerDisplay(id: string) {
    const paymentProfile = await this.paymentProfileCache.get(id);

    if (paymentProfile && paymentProfile.id !== this.dataBinding.getValue('clientTypeId')) {
      this.dataBinding.setValue('clientTypeId', paymentProfile.id);
      this.clientTypePickerDisplay = paymentProfile.displayValue;
    }
  }

  private async selectContact() {
    const selectedClient = this.dataBinding.getValue('clientId');

    if (selectedClient == emptyGuid) {
      this.dataBinding.setValue('contactId', emptyGuid);
      this.contactPickerDisplay = '';

      fireQuickWarningToast(tlang`Please select a %%client%% first.`, 1000);
    } else {
      const selectedContact = await ContactPicker(selectedClient);

      if (selectedContact) {
        this.dataBinding.setValue('contactId', selectedContact.id);
        this.contactPickerDisplay = selectedContact.name ?? '';
      }
    }

    this.requestUpdate();
  }

  private async selectClientType() {
    const selectedClientType = await PaymentProfilePicker(this.paymentProfileApi);

    if (selectedClientType) {
      this.dataBinding.setValue('clientTypeId', selectedClientType.id);
      this.clientTypePickerDisplay = selectedClientType.name ?? '';
      this.requestUpdate();
    }
  }

  protected changeDefaultShipping(value: boolean) {
    this.projectManager.project.shipToDefaultAddress = value;
    this.requestUpdate(); //no wait
  }

  protected isShippingReadonly(): boolean {
    // Attempt to get the current value of the form during a render.
    // This will fail initially when loading so fall back to the project object.
    try {
      const line1 = this.dataBinding.getValue('line1');
      const postcode = this.dataBinding.getValue('postcode');
      return isEmptyOrSpace(line1) || isEmptyOrSpace(postcode);
    } catch {
      const address = this.projectManager.project.defaultAddress;
      return isEmptyOrSpace(address.line1) || isEmptyOrSpace(address.postcode);
    }
  }

  protected async requiredAddressFieldFocusOut(fieldName: string) {
    const fieldValue = this.dataBinding.getValue(fieldName);
    if (isEmptyOrSpace(fieldValue)) {
      this.projectManager.project.shipToDefaultAddress = false;
    }
    this.requestUpdate();
  }
}
