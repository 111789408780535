//NFRC is basically window thermals
import { sameText } from '../components/ui/helper-functions';
import { DevelopmentError } from '../development-error';
import { NFRCRowResult, NFRCRowValues, NFRCValue } from '@softtech/webmodule-data-contracts';

export const nfrc_Standard_v1 = 'NFRCStandard_v1';

//what to use to process the data.
export function nfrcCalculator(calculationName: string): NFRCCalculation {
  const calc = nfrcCalculations.find(x => sameText(x.name, calculationName));
  if (!calc) throw new DevelopmentError(`missing NFRC Calculation "${calculationName}" which is not registered`);
  return calc;
}

const nfrcCalculations: NFRCCalculation[] = [];
export function addOrReplaceNFRCCalculation(nfrcCalc: NFRCCalculation) {
  const i = nfrcCalculations.findIndex(x => sameText(x.name, nfrcCalc.name));
  if (i >= 0) nfrcCalculations[i] = nfrcCalc;
  else nfrcCalculations.push(nfrcCalc);
}

/**
 * a set of result values from a calculation allowing for one or many various named values
 */
export interface NFRCCalculationResult {
  values: NFRCValue[];
}

/**
 * a calculation event which takes all inputs from all row items, where each row can hold many values from nesting
 */
export type NFRCCalculationEvent = (items: NFRCRowValues[]) => NFRCCalculationResult;
/**
 *  convert the input values of a single row item into the column outputs for those rows
 *  where each result value is named by the column name as defined in the options
 */
export type NFRCColumnCalculationEvent = (items: NFRCRowValues) => NFRCRowResult;

export interface NFRCCalculation {
  name: string;
  requiredPublishedCodes: string[];
  outputFieldNames: string[];
  nfrcCalculation: NFRCCalculationEvent;
  nfrcColumnCalculation: NFRCColumnCalculationEvent;
}
