import { tlang } from '@softtech/webmodule-components';
import { Address, Project, ProjectState, ViewProjectSummary } from '../../../api/dealer-api-interface-project';
import { html } from 'lit';
import { DataBinding } from '../../../components/ui/databinding/databinding';
import { emptyAddress } from '../../../components/ui/maps/map-helpers';

export const getProjectNumberFormatted = (p: ViewProjectSummary | Project) => {
  const prefix = tlang`%%project-abbrev%%`;
  return `${prefix}: ${p.number.toString().padStart(6, '0')}`;
};

export const getProjectTitle = (p: any) => {
  const project = p as Project;
  const projectNumber = getProjectNumberFormatted(project);
  return html`${projectNumber} ${project.title}
    <span class="badge badge-${ProjectState[project.state].toLowerCase()}">${ProjectState[project.state]}</span>`;
};

/**
 * Attempts to pick a valid address for the project from two given addresses, or defaults to an empty address.
 * @param firstAddress
 * @param isDefaultShippingFirst
 * @param secondAddress
 * @param isDefaultShippingSecond
 */
export function determineProjectShippingAddress(
  firstAddress?: Address | null,
  isDefaultShippingFirst = false,
  secondAddress?: Address | null,
  isDefaultShippingSecond = false
): Address {
  //Already tested if address(es) are empty
  return firstAddress && isDefaultShippingFirst
    ? firstAddress
    : secondAddress && isDefaultShippingSecond
      ? secondAddress
      : emptyAddress(); // use consistent empty address throughout application
}

export function updateProjectAddressInBinder(databinding: DataBinding, address?: Address) {
  if (!address) {
    return false;
  }

  databinding.setValue('line1', address.line1);
  databinding.setValue('locality', address.locality);
  databinding.setValue('region', address.region);
  databinding.setValue('postcode', address.postcode);
  databinding.setValue('country', address.country);
  return true;
}
