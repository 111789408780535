// eslint-disable-next-line import/named
import { html } from 'lit';
import { emptyGuid } from '../../api/guid';
import { Project } from '../../api/dealer-api-interface-project';
import { DataBinding, getInternalId } from '../../components/ui/databinding/databinding';
import { ProjectContainerManager } from '../data/project-container';
import {
  DataTracker,
  DynamicValueBinder,
  EventValue,
  EventValueGetter,
  EventValueSetter,
  FieldType
} from '../../components/ui/databinding/data-tracker';
import { FormInputAssistant } from '../../components/ui/templateresult/form-input-assistant';
import { isEmptyOrSpace } from '../../components/ui/helper-functions';
import { tlang } from '@softtech/webmodule-components';
import { EventTemplate } from '../../components/ui/events';
import { ViewBase } from '../../components/ui/view-base';
import { customElement } from 'lit/decorators.js';

export interface ProjectDetailViewOptions {
  projectManager: ProjectContainerManager;
}
@customElement('wm-projectdetailview')
export class ProjectDetailView extends ViewBase {
  projectId: string = emptyGuid;
  projectManager: ProjectContainerManager;
  internalId: string;
  dataBinding: DataBinding;
  dataTracker: DataTracker;

  constructor(options: ProjectDetailViewOptions) {
    super();
    this.internalId = getInternalId();
    this.projectManager = options.projectManager;

    this.dataBinding = new DataBinding(
      this.ui,
      undefined,
      (input: string, internalId: string) => `project-${input}-${internalId}`
    );
    this.dataTracker = new DataTracker(this.dataBinding);

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.project),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    const addDynamicMoney = (
      fieldName: string,
      getter: EventValueGetter,
      setter: EventValueSetter,
      readonly?: () => boolean
    ) => {
      this.dataTracker.addBinding(
        new DynamicValueBinder(FieldType.money, true, getter, setter, readonly),
        fieldName,
        fieldName,
        FieldType.money,
        true
      );
    };

    addField('title', FieldType.string);
    addField('description', FieldType.string, true);

    addDynamicMoney(
      'budget',
      () => this.project.budget,
      (_value: EventValue) => (this.project.budget = _value as number),
      () => true
    );
  }

  get project(): Project {
    return this.projectManager.project;
  }

  public async resetEditControls() {
    if (this.dataTracker.modified) this.dataTracker.resetEditorValue();
  }

  public async prepareForSave(): Promise<void> {
    if (this.dataTracker.modified) this.dataTracker.applyChangeToValue();
  }

  public hasUnsavedChanges(): boolean {
    return this.dataTracker.modified;
  }

  public async loadOrRefresh(): Promise<void> {
    await this.projectManager.needsProject();

    this.requestUpdate();
  }

  public getValidationErrors() {
    const errors: string[] = [];

    const title = this.dataBinding.getValue('title');

    if (isEmptyOrSpace(title)) errors.push(tlang`Please provide a %%project%% Title`);

    return errors;
  }

  protected template(): EventTemplate {
    const forms = new FormInputAssistant(this.dataTracker, this.projectManager.isReadonly());
    return html`
      <form class="frm-quote-details form-two-col">
        <div class="row">
          <div>
            ${forms.textRequired('title', tlang`%%project%% Title`, 100)}
            ${forms.money('budget', tlang`%%project%% Budget`, 2)}
            ${forms.note('description', tlang`%%project%% Description`, 3000)}
          </div>
          <div>
            ${forms.dateReadonly('dateCreated', tlang`Created`)}
            ${forms.dateReadonly('lastModifiedDate', tlang`Last Modified`)}
          </div>
        </div>
      </form>
    `;
  }
}
