import { html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { PageBase } from './helpers/page-base';
import { FranchiseeQuoteListView } from '../quotes/views/franchisee-quote-list-view';
import { userDataStore } from '../common/current-user-data-store';
import { tlang } from '@softtech/webmodule-components';
import { QuoteListViewOptions } from '../../quotes/views/quote-list-view';
import { getInternalId } from '../../components/ui/databinding/databinding';
import { cache } from '../cache/cache-registry';
import { disposeOf } from '../../components/dispose';
import { NullPromise } from '@softtech/webmodule-data-contracts';

@customElement('app-quotes')
export class AppQuotesPage extends PageBase {
  @state()
  quoteListView: FranchiseeQuoteListView | null = null;

  internalId: string = getInternalId();

  constructor() {
    super();
  }
  protected async awaken() {
    await this.setActiveTabByHash();
    await super.awaken();
  }
  private async setActiveTabByHash() {
    await this.quoteListView?.setActiveTabByHash();
  }

  private get elementId(): string {
    return `quotepage-${this.internalId}`;
  }
  protected async allowEnter(): Promise<boolean> {
    return true;
  }
  async firstUpdated() {
    console.log('Quote Summary Page');
    //first thing we need to do is load the summary information from the service.
  }

  render() {
    if (!this.loggedIn) return html``;
    return html`
      <div id=${this.elementId} class="page-content">
        <div class="page-container">${this.quoteListView}</div>
      </div>
    `;
  }

  meta() {
    return {
      title: tlang`%%quote%% Summary`,
      description: 'SoftTech dealer CPQ'
    };
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  protected async afterUserConnected() {
    const quoteListView = this.quoteListViewFactory();
    await quoteListView.init();
    await quoteListView.setActiveTabByHash();
    this.quoteListView = quoteListView;
  }
  async dispose() {
    await super.dispose();
    await disposeOf(this.quoteListView);
    this.quoteListView = null;
  }
  private quoteListViewFactory(): FranchiseeQuoteListView {
    const options: QuoteListViewOptions = {
      customerCache: cache().client,
      userProfileCache: cache().userProfile,
      quoteOwnerId: async () => {
        await userDataStore.loadCoreDetails();
        //this will need to be changed to a franchisee branch that the user belongs to
        if (!userDataStore.defaultBranch) return '';
        return userDataStore.defaultBranch.id;
      } //Change Quote Owner to a Branch
    };
    //change this to a subclass which has branch details, or otherwise.. ie FranchiseeBranchQuoteListView

    const view = new FranchiseeQuoteListView(options);
    return view;
  }

  async redirectPath(): NullPromise<string> {
    return '/';
  }
}
