import { getApiFactory } from '../../../api/api-injector';
import {
  InputCreateTempPurchaseOrderQuote,
  InputIssuePurchaseOrder,
  InputIssueQuote,
  InputPreviewQuoteShopDrawing
} from '../../../api/dealer-api-interface-clientagent';
import { information } from '../../../components/ui/modal-option';
import { waitPatientlySaving } from '../../../components/ui/modal-saving';
import { tlang } from '@softtech/webmodule-components';
import { v6SupportsVersion, v6Util, v6VersionMap } from '../../../v6config/v6config';
import { sleep } from '../../../components/time';
import { V6QuoteBrief } from '@softtech/webmodule-data-contracts';
import { html } from 'lit';

export class V6SupplierQuoteBase {
  static async generateShopDrawing(branchId, quoteId, projectId: string): Promise<boolean> {
    const waiting = waitPatientlySaving(() => tlang`Requesting %%shopdrawing%%`);
    try {
      const input: InputPreviewQuoteShopDrawing = {
        branchId: branchId,
        projectId: projectId,
        quoteId: quoteId
      };
      const result = await getApiFactory().clientAgent().previewQuoteShopDrawing(input);
      return result !== null;
    } finally {
      await waiting.hideModal();
    }
  }
  static async generateTempQuote(branchId: string, quoteId: string, supplierId: string): Promise<boolean> {
    let waiting = waitPatientlySaving(() => tlang`Requesting %%supplier%% %%quote%%`);
    let v6brief: V6QuoteBrief | null = null;
    try {
      const input: InputCreateTempPurchaseOrderQuote = {
        branchId: branchId,
        quoteId: quoteId
      };
      const result = await getApiFactory().clientAgent().createSupplierQuote(input);
      if (result !== null) {
        const quoteCommonId = result.quoteCommonId;

        if (v6SupportsVersion(v6VersionMap.hasCheckSupplierQuote)) {
          let complete = false;
          const exitEvent = _e => {
            complete = true;
          };
          await waiting.hideModal();
          waiting = waitPatientlySaving(
            () => tlang`Create %%supplier%% %%quote%%`,
            () => tlang`Waiting for %%supplier%% %%quote%% creation. This could take time.
            Cancelling will not stop the process, but you will not find out the V6 %%quote%% number
            unless you wait`,
            html`<div><button class="btn btn-secondary" @click=${exitEvent}>${tlang`Exit`}</button></div>`
          );

          let loop = 0;
          do {
            if (loop === 0) v6brief = await v6Util().getV6SupplierQuoteInfo(supplierId, quoteCommonId);

            complete = v6brief != null;
            if (!complete) await sleep(50);
            loop += 50;
            if (loop === 10000) loop = 0;
          } while (!complete);
        }
        return true;
      } else {
        await information(tlang`Unknown error. could not produce V6 Quote`);
        return false;
      }
    } finally {
      await waiting.hideModal();
      if (v6brief) {
        await information(
          tlang`
            V6 %%quote%%: ${v6brief.fullQuoteNumber}
            `,
          tlang`%%supplier%% %%quote%% created`
        );
      }
    }
  }

  static async issueQuote(branchId, quoteId, projectId): Promise<boolean> {
    const waiting = waitPatientlySaving(() => tlang`Issuing %%quote%%`);
    try {
      const input: InputIssueQuote = {
        branchId: branchId,
        projectId: projectId,
        quoteId: quoteId
      };
      const result = await getApiFactory().clientAgent().issueQuote(input);
      return result !== null;
    } finally {
      await waiting.hideModal();
    }
  }

  static async issuePurchaseOrder(branchId, quoteId, projectId, purchaseOrderId: string): Promise<boolean> {
    const waiting = waitPatientlySaving(() => tlang`Issuing %%quote%%`);
    try {
      const input: InputIssuePurchaseOrder = {
        branchId: branchId,
        projectId: projectId,
        quoteId: quoteId,
        purchaseOrderId: purchaseOrderId
      };
      const result = await getApiFactory().clientAgent().issuePurchaseOrder(input);
      return result !== null;
    } finally {
      await waiting.hideModal();
    }
  }
}
