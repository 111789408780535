import { QuoteItem, QuoteItemPrice } from '../../../../api/dealer-api-interface-quote';
import { applyV6PricesToQuoteItemPrice, updateDealerFreehandPrice } from '../../../../quotes/data/v6/helper-functions';
import { activeV6FrameConfigVersion, v6Editors } from '../../../../v6config/v6config';
import { versionGreaterThanOrEqual } from '../../../common/version-match';

export async function updateFreehandPrice(
  supplierId: string,
  supplierReferenceOverrideKey: string,
  quoteItem: QuoteItem,
  price: QuoteItemPrice
) {
  const ver = activeV6FrameConfigVersion() ?? 'v0.0.0';

  if (versionGreaterThanOrEqual('v1.0.41', ver)) {
    const resultPrice = await v6Editors().getQuoteItemFreehandPrice({
      itemSubType: quoteItem.quoteItemContentType ?? null,
      quoteItemCommonId: quoteItem.commonId ?? null,
      quantity: quoteItem.quantity,
      singleUnitGrossSellPrice: price.supplierGrossSingleUnitCost,
      supplierId: supplierId,
      supplierReferenceOverrideKey: supplierReferenceOverrideKey
    });
    if (!resultPrice) {
      return false;
    }

    applyV6PricesToQuoteItemPrice(quoteItem.quantity, resultPrice, price);
  } else {
    //if we get here we need singleUnitCost populated, but we have supplierGrossSingleUnitCost
    price.singleUnitCost = price.supplierGrossSingleUnitCost;
    updateDealerFreehandPrice(quoteItem.quantity, price);
  }
  return true;
}
