import { ModalPickerDialog, ModalPickerDialogOptions } from '../../components/ui/modal-picker-base';
// eslint-disable-next-line import/named
import { TemplateResult, html } from 'lit';
import { NullPromise } from '../../null-promise';
import { ViewClientSummary } from '../../api/dealer-api-interface-client';
import { tlang } from '@softtech/webmodule-components';
import { ClientSummaryPickerBrowser } from './client-summary-picker';
import { EventTemplate } from '../../components/ui/events';
import { customElement } from 'lit/decorators.js';

export async function ClientPicker(): NullPromise<ViewClientSummary> {
  return new Promise<ViewClientSummary | null>((resolve, reject) => {
    const picker = new ClientBrowserPicker({
      onSelect: (selected: ViewClientSummary | null) => {
        resolve(selected);
      },
      onCancel: () => reject(),
      title: tlang`Select a %%client%%`
    });
    picker.showModal();
  });
}
@customElement('wm-clientbrowserpicker')
class ClientBrowserPicker extends ModalPickerDialog<ViewClientSummary> {
  private comms: ClientSummaryPickerBrowser;

  constructor(options: ModalPickerDialogOptions<ViewClientSummary>) {
    super(options);

    this.comms = new ClientSummaryPickerBrowser({
      title: () => options.title ?? '',
      onClientSelect: clientSummary => this.select(clientSummary)
    });
  }

  protected bodyTemplate(): EventTemplate {
    return html`${this.comms}`;
  }

  protected footerTemplate(): TemplateResult | null {
    return html`<button @click=${() => this.hideModal()} class="btn btn-secondary">${tlang`Close`}</button>`;
  }
}
