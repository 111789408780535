import { ApiCommunications } from '@softtech/webmodule-data-contracts';
import { NullPromise } from '../../null-promise';
import { ItemReference } from '../../cache/definitions/cache-item-reference';
import { DataCacheBase } from '../../cache/data-cache-base';
import { InputQuoteItemConversations, ResultQuoteItemConversations } from '../../api/dealer-api-interface-franchisee';
import { QuoteItemConversations } from './cache-data';

export class QuoteItemConversationCache extends DataCacheBase<QuoteItemConversations> {
  constructor(api: ApiCommunications) {
    super(api);
    //depending on what high level controller api we create we could swap this to ClientApi etc.
  }
  protected replaceCacheItem(
    _index: number,
    item: ItemReference<QuoteItemConversations>,
    existingItem: ItemReference<QuoteItemConversations>
  ) {
    existingItem.data.merge(item.data);
  }

  protected async internalFetch(requestIds: string[]): NullPromise<ItemReference<QuoteItemConversations>[]> {
    const input: InputQuoteItemConversations = {
      quoteItemIds: requestIds
    };
    const results = await this.api.post<ResultQuoteItemConversations>(
      `api/conversation/QuoteItemConversationLinks`,
      input
    );

    if (!results) return null;

    const references: ItemReference<QuoteItemConversations>[] = [];

    results.items.forEach(item => {
      let qic = references.find(x => x.id === item.quoteItemId);
      if (!qic) {
        qic = {
          id: item.quoteItemId,
          displayValue: 'N/A',
          data: new QuoteItemConversations(item.quoteItemId),
          onClick: null
        };
        references.push(qic);
      }
      qic.data.add(item);
    });

    return references;
  }
}
