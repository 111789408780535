import { litModalScreenFactory, tlang } from '@softtech/webmodule-components';
import { LitModalScreenEvents, QuoteItemProvider, V6QuoteFrameBomViewer } from '@softtech/webmodule-data-contracts';
import { html } from 'lit';
import { AsyncConstructor } from '../../../../async-constructor';
import { v6Editors } from '../../../../v6config/v6config';
import { getApiFactory } from '../../../../api/api-injector';
import { CSVProducerData } from '../../../../api/dealer-api-interface-franchisee';
import { information } from '../../../../components/ui/modal-option';
import { nowServer } from '../../../../components/datetime-converter';
import { firstValidString } from '../../../../components/ui/helper-functions';
import { lockUIandExecute } from '../../../../ui-lock';

export class V6BomViewer implements AsyncConstructor, LitModalScreenEvents {
  v6QuoteItemData: QuoteItemProvider;
  bomViewer?: V6QuoteFrameBomViewer | null;
  modal = litModalScreenFactory(this);
  titleStr: string;
  constructor(title: string, v6QuoteItemData: QuoteItemProvider) {
    this.titleStr = title;
    this.v6QuoteItemData = v6QuoteItemData;
  }
  afterConstructionExecuted?: boolean | undefined;
  async afterConstruction(): Promise<void> {
    this.bomViewer = await v6Editors().getBomViewer(this.v6QuoteItemData);
  }
  modalSize = 'modal-fullscreen modal-v6-bom-view';
  bodyTemplate() {
    return html`${this.bomViewer?.ui}`;
  }
  requestUpdate() {
    this.modal.requestUpdate();
  }
  footerTemplate() {
    const eventClose = async () => {
      await this.hideModal();
    };
    const eventExport = async () => {
      await lockUIandExecute(async () => {
        await this.exportCsv();
      });
    };
    return html`<button class="btn btn-secondary" @click=${eventClose}>${tlang`Close`}</button
      ><button class="btn btn-primary" @click=${eventExport}>${tlang`Export`}</button>`;
  }

  private convertRows(rows: any[], metaData: any): { [key: string]: any }[] {
    return rows.map(item => {
      const { nested_data, ...csvData } = item;
      if (nested_data) {
        csvData['_NestedDataRows'] = this.convertNested(nested_data, metaData);
      }
      return csvData;
    });
  }
  private convertNested(nestedData, metaData: any) {
    return this.convertBomUIDataToCSVProducerData(nestedData, metaData);
  }

  private convertBomUIDataToCSVProducerData(data: any, metaData: any): CSVProducerData[] {
    const result: CSVProducerData[] = [];
    for (const key in data) {
      const meta = metaData.find(x => x.resourceName === key);
      const filename = `${firstValidString(meta.tabCaption, meta.TabCaption, meta.resourceName)}.csv`;
      const rows = data[key];
      const csvD: CSVProducerData = {
        columns: meta.fields.map(x => x.fieldName),
        rows: this.convertRows(rows, metaData),
        fileName: filename
      };
      result.push(csvD);
    }
    return result;
  }
  async exportCsv() {
    const filename = `bomview ${this.titleStr} - ${nowServer()}.csv`;
    const bv = globalThis.debug_bomView ?? (this.v6QuoteItemData.quoteItem as any).bomView;
    let inputs: CSVProducerData[] = [];

    const version = bv['version'];
    if (version && version === 'ui') {
      inputs = this.convertBomUIDataToCSVProducerData(bv.data, bv.metaData);
      //todo new export
    } else {
      const types = bv.metaData.map(x => x.resourceName);
      inputs = types.map(rtype => {
        const fields = bv.metaData.find(x => x.resourceName === rtype).fields.map(f => f.fieldName);
        const csvD: CSVProducerData = {
          columns: fields ?? [],
          rows: bv[rtype],
          fileName: `${rtype}.csv`
        };
        return csvD;
      });
      //old export
    }

    const extCsv = await getApiFactory().franchisee().convertToCsv({ fileName: 'bomview.csv', data: inputs });
    if (extCsv?.status !== 200) {
      await information(tlang`Error converting to CSV`);
      return;
    }
    const url = URL.createObjectURL(extCsv.data);
    try {
      const pom = document.createElement('a');
      pom.setAttribute('href', url);
      pom.setAttribute('download', filename);
      const event = new Event('click');
      pom.dispatchEvent(event);
      pom.click();
    } finally {
      URL.revokeObjectURL(url);
    }
  }
  title = () => tlang`BOM View (${this.titleStr})`;
  isFooterVisible = true;
  async showModal() {
    const bv = (this.v6QuoteItemData.quoteItem as any).bomView;
    if (!bv) {
      await information(
        tlang`There is no BOM related data for this %%quote-item%%. Try resaving the item to generate BOM`
      );
      return;
    }
    return await this.modal.showModal();
  }
  async hideModal() {
    return await this.modal.hideModal();
  }
}
