import { TemplateResult } from 'lit';
import { QuoteItem, QuoteItemPrice } from '../../api/dealer-api-interface-quote';
import { isAutoSaving } from '../../components/save-workflow';
import { DataTracker } from '../../components/ui/databinding/data-tracker';
import { DataBinding } from '../../components/ui/databinding/databinding';
import { Snippet } from '../../components/ui/events';
import { isEmptyOrSpace } from '../../components/ui/helper-functions';
import { ModalDialog } from '../../components/ui/modal-base';
import { showValidations } from '../../components/ui/modal-validationhandler';
import { tlang } from '@softtech/webmodule-components';
import { QuoteContainerManager } from '../data/quote-container';
import { QuoteItemContainer } from '../data/quote-item-container';
import { QuoteItemView } from './quote-item-view';
import { buttonsSaveCancel } from '../../components/ui/modal-confirmation';
import { customElement } from 'lit/decorators.js';

@customElement('wm-lineitemdialog')
export abstract class LineItemDialog extends ModalDialog {
  quoteManager: QuoteContainerManager;
  quoteItemContainer: QuoteItemContainer;
  dataBinding: DataBinding;
  dataTracker: DataTracker;
  ok = false;
  forceReadonly: boolean;

  constructor(quoteManager: QuoteContainerManager, quoteItemContainer: QuoteItemContainer, forceReadonly: boolean) {
    super();
    this.quoteManager = quoteManager;
    this.quoteItemContainer = quoteItemContainer;
    this.forceReadonly = forceReadonly;
    this.dataBinding = new DataBinding(this.ui);
    this.dataTracker = new DataTracker(this.dataBinding);
  }

  protected getQuoteItem(): QuoteItem {
    return this.quoteItemContainer.item;
  }

  protected getQuoteItemPrice(): QuoteItemPrice {
    return this.quoteItemContainer.price;
  }

  async save(): Promise<boolean> {
    this.prepareForSave();
    const errors = await this.getValidationErrors();
    if (errors.length != 0) {
      await showValidations(errors);
      this.quoteItemContainer =
        this.quoteManager.restoreQuoteItemFromBackup(this.quoteItemContainer.item.id) ?? this.quoteItemContainer;
      this.dataTracker.resetEditorValue();
      return false;
    } else if (isAutoSaving()) await this.internalSave();
    return true;
  }

  protected abstract getTitle(): Snippet;

  protected modalSize(): string {
    return 'modal-md';
  }

  protected prepareForSave(): void {
    if (this.forceReadonly) return;
    this.dataTracker.applyChangeToValue();
  }

  protected async internalSave() {
    // do nothing here, override in subclass
  }

  protected async getValidationErrors(): Promise<string[]> {
    const errors: string[] = [];
    if (isEmptyOrSpace(this.dataBinding.getValue('title'))) {
      const title = await this.getTitle();
      errors.push(tlang`${title.toString()} cannot have an empty Title`);
    }

    return errors;
  }

  protected footerTemplate(): TemplateResult {
    const saveEvent = async () => {
      if (await this.save()) await this.hideModal();
    };
    const closeEvent = () => {
      this.hideModal();
    };
    const buttons = buttonsSaveCancel();

    const template = this.forceReadonly
      ? this.createCancelButton()
      : this.createConfirmCancelButtons(buttons.ok, saveEvent, buttons.cancel, closeEvent);
    return template;
  }
}

export abstract class LineItemView extends QuoteItemView {
  public get hasPropertyDialog(): boolean {
    return true;
  }

  public get hasModalEditDialog(): boolean {
    return true;
  }
}
