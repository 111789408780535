import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('icon-stale')
export class IconStale extends LitElement {
  @property({ type: Boolean }) active = false;
  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }
  render() {
    const classes = this.active ? 'fa-solid fa-arrows-rotate text-success fa-fade' : 'fa-solid fa-arrows-rotate text-success';
    return html`<i class=${classes}></i>`;
  }
}
