import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { LitElementBase } from '../litelement-base';

@customElement('wm-button-switch')
export class ButtonSwitch extends LitElementBase {
  @property() checked = false;
  @property() caption = 'choose';
  render() {
    const clickEvent = (_e: Event) => {
      this.checked = !this.checked;
      this.dispatchCustom('changed', { checked: this.checked });
    };
    return this.checked
      ? html`<div class="d-flex align-items-center" @click=${clickEvent}
                          ><i class="fa-solid fa-toggle-on text-primary me-1 fa-2x"></i>
                          ${this.caption}</span
                      >`
      : html`<div class="d-flex align-items-center" @click=${clickEvent}>
          <i class="fa-solid fa-toggle-off text-secondary me-1 fa-2x"></i>
          ${this.caption}
        </div>`;
  }
}
