import { ClientApi } from '../../api/client-api';
import { Contact } from '../../api/dealer-api-interface-client';
import { emptyGuid, newGuid } from '../../api/guid';
import { clone } from '../../components/clone';
import { ContainerManager } from '../../components/container-manager';
import { localDateToServer, today } from '../../components/datetime-converter';
import { EventSnippet } from '../../components/ui/events';
import { cache } from '../../dealer-franchisee/cache/cache-registry';
import { DevelopmentError } from '../../development-error';
import { tlang } from '@softtech/webmodule-components';
import { NullPromise } from '../../null-promise';

export class ContactContainer {
  contactId: string;
  contact: Contact | null;
  isPrimary: boolean;

  constructor(contactId: string, contact: Contact | null, isPrimary: boolean) {
    this.contactId = contactId;
    this.contact = contact;
    this.isPrimary = isPrimary;
  }
}

export class ContactContainerManager extends ContainerManager<ContactContainer> {
  protected clientApi: ClientApi;

  constructor(container: ContactContainer, title: EventSnippet, clientApi: ClientApi) {
    super(container, title, '%%contact%%');
    this.clientApi = clientApi;
  }

  /**
   * the id for this managed container
   */
  public get contactId(): string {
    return this.container.contactId;
  }

  /**
   * returns the contact object after needsContact is called, or throws an error if the contact is unavailable.
   */
  public get contact(): Contact {
    if (!this.container.contact) {
      throw new DevelopmentError(`contact-container-manager.ts contact is null`);
    }
    return this.container.contact;
  }

  public async needsContact() {
    if (!this.container.contact) {
      const result = await this.clientApi.getContact({ contactId: this.contactId });
      if (result) {
        const data = await cache().primaryContact.getData(result.contact.clientId);
        const isPrimary = data?.contactId == result.contact.id;
        this.resetContact(result.contact, isPrimary);
      } else return false;
    }
    return true;
  }

  /**
   * Override this to customize the save logic.
   * @returns True if the save operations have succeeded, false otherwise.
   */
  protected async internalSave(): Promise<boolean> {
    // a contact cannot be created with an empty email, so we'll try create it here once we have an email.
    if (this.contactId == emptyGuid) {
      this.contact.id = newGuid();
      const contactResult = await this.clientApi.createContact({
        contact: this.contact,
        isPrimary: this.container.isPrimary
      });
      if (contactResult && contactResult.contact) {
        this.resetContact(contactResult.contact, this.container.isPrimary);
        return true;
      }
    } else {
      const result = await this.clientApi.updateContact({ contact: this.contact, isPrimary: this.container.isPrimary });
      if (result) {
        this.resetContact(result.contact, this.container.isPrimary);
        return true;
      }
    }
    return false;
  }

  private resetContact(contact: Contact, isPrimary: boolean) {
    this.container.contact = contact;
    this.container.contactId = this.container.contact.id;
    this.container.isPrimary = isPrimary;
    this.backup.contact = clone(contact);
    this.backup.contactId = this.backup.contact.id;
    this.backup.isPrimary = isPrimary;
  }
}

export async function createNewContact(): NullPromise<ContactContainer> {
  const contact: Contact = {
    id: emptyGuid,
    email: '',
    dateCreated: localDateToServer(today()),
    name: tlang`New %%contact%%`,
    title: '',
    clientId: emptyGuid,
    mobile: '',
    recordVersion: ''
  };
  // cannot create a contact with an empty email, so we'll create it when we go to save it
  return new ContactContainer(emptyGuid, contact, false);
}
