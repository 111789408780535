// eslint-disable-next-line import/named
import { html, TemplateResult } from 'lit';
import { DataBinding } from '../databinding/databinding';
import { DataTracker } from '../databinding/data-tracker';

export function formSelect(
  fieldName: string,
  placeHolder: string,
  title: string,
  options: string,
  dataBinding: DataBinding,
  dataTracker: DataTracker,
  readOnly = false
): TemplateResult {
  return html` <bs-form-select
    data-id=${dataBinding.field(fieldName)}
    data-placeholder=${placeHolder}
    data-label=${title}
    ?disabled=${readOnly}
    ?readonly=${readOnly}
    .value=${dataTracker.getObjectValue(fieldName)}
    .options=${options}
  >
  </bs-form-select>`;
}
