import { emptyGuid } from '../../../api/guid';
import { PurchaseOrderContainerManager } from '../../../purchase-orders/data/purchase-order-container';
import { FranchiseeQuoteContainerManager } from '../../quotes/data/franchisee-quote-manager';
import { getQuoteContainerManager } from '../../quotes/quote-ui-adapter';

export class FranchiseePurchaseOrderContainerManager extends PurchaseOrderContainerManager {
  private _branchId: string = emptyGuid;

  public get branchId(): string {
    return this._branchId;
  }

  public async needsPurchaseOrder(): Promise<boolean> {
    const result = await super.needsPurchaseOrder();
    const qcm = getQuoteContainerManager(this.purchaseOrder.branchQuoteId) as FranchiseeQuoteContainerManager;
    await qcm.needsQuote();
    this._branchId = qcm.branchQuote.branchId;
    return result;
  }
}
