import { ProjectApi } from './project-api';
import {
  InputCreateProject,
  InputCreateProjectDocumentLink,
  InputCreateProjectResourceReference,
  InputDeleteProject,
  InputDeleteProjectDocument,
  InputDeleteProjectResourceReference,
  InputUpdateProject,
  RequestBrowseProjectSummary,
  RequestGetPermanentProjectDocuments,
  RequestGetProject,
  RequestGetProjectDocument,
  RequestGetProjectDocuments,
  RequestGetProjectResource,
  RequestGetProjectStateChangeReasonsByOwnerIds,
  RequestGetResourceLinks,
  ResultBrowseProjectSummary,
  ResultCreateProject,
  ResultCreateProjectDocumentLink,
  ResultCreateProjectResourceReference,
  ResultDeleteProject,
  ResultDeleteProjectDocument,
  ResultDeleteProjectResourceReference,
  ResultGetPermanentProjectDocuments,
  ResultGetProject,
  ResultGetProjectDocument,
  ResultGetProjectDocuments,
  ResultGetProjectResource,
  ResultGetProjectStateChangeReasonsByOwnerIds,
  ResultGetResourceLinks,
  ResultUpdateProject
} from './dealer-api-interface-project';
import { NullPromise } from '../null-promise';
import { ApiCommunications } from '@softtech/webmodule-data-contracts';

export class ProjectApiImplementation implements ProjectApi {
  api: ApiCommunications;

  constructor(api: ApiCommunications) {
    this.api = api;
  }

  async deleteProjectDocument(body: InputDeleteProjectDocument): NullPromise<ResultDeleteProjectDocument> {
    return await this.api.post<ResultDeleteProjectDocument>('api/Project/DeleteProjectDocument', body);
  }

  async getProjectDocuments(body: RequestGetProjectDocuments): NullPromise<ResultGetProjectDocuments> {
    return await this.api.post<ResultGetProjectDocuments>('api/Project/GetProjectDocuments', body);
  }

  async getPermanentProjectDocuments(
    body: RequestGetPermanentProjectDocuments
  ): NullPromise<ResultGetPermanentProjectDocuments> {
    return await this.api.post<ResultGetPermanentProjectDocuments>('api/Project/GetPermanentProjectDocuments', body);
  }

  async getProjectDocument(body: RequestGetProjectDocument): NullPromise<ResultGetProjectDocument> {
    return await this.api.post<ResultGetProjectDocument>('api/Project/GetProjectDocument', body);
  }

  async browseProjectSummary(body: RequestBrowseProjectSummary): NullPromise<ResultBrowseProjectSummary> {
    return await this.api.post<ResultBrowseProjectSummary>('api/Project/BrowseProjectSummary', body);
  }

  async createProject(body: InputCreateProject): NullPromise<ResultCreateProject> {
    return await this.api.post<ResultCreateProject>('api/Project/CreateProject', body);
  }

  async createProjectResourceReference(
    body: InputCreateProjectResourceReference
  ): NullPromise<ResultCreateProjectResourceReference> {
    return await this.api.post<ResultCreateProjectResourceReference>(
      'api/Project/CreateProjectResourceReference',
      body
    );
  }

  async deleteProject(body: InputDeleteProject): NullPromise<ResultDeleteProject> {
    return await this.api.post<ResultDeleteProject>('api/Project/DeleteProject', body);
  }

  async deleteProjectResourceReference(
    body: InputDeleteProjectResourceReference
  ): NullPromise<ResultDeleteProjectResourceReference> {
    return await this.api.post<ResultDeleteProjectResourceReference>(
      'api/Project/DeleteProjectResourceReference',
      body
    );
  }

  async getProject(body: RequestGetProject): NullPromise<ResultGetProject> {
    return await this.api.post<ResultGetProject>('api/Project/GetProject', body);
  }

  async getProjectResource(body: RequestGetProjectResource): NullPromise<ResultGetProjectResource> {
    return await this.api.post<ResultGetProjectResource>('api/Project/GetProjectResource', body);
  }

  async updateProject(body: InputUpdateProject): NullPromise<ResultUpdateProject> {
    return await this.api.post<ResultUpdateProject>('api/Project/UpdateProject', body);
  }

  async getResourcesLinks(body: RequestGetResourceLinks): NullPromise<ResultGetResourceLinks> {
    return await this.api.post<ResultGetResourceLinks>('api/Project/GetResourcesLinks', body);
  }

  async createProjectDocumentLink(body: InputCreateProjectDocumentLink): NullPromise<ResultCreateProjectDocumentLink> {
    return await this.api.post<ResultCreateProjectDocumentLink>('api/Project/CreateProjectDocumentLink', body);
  }

  async getProjectStateChangeReasons(
    request: RequestGetProjectStateChangeReasonsByOwnerIds
  ): NullPromise<ResultGetProjectStateChangeReasonsByOwnerIds> {
    return await this.api.post<ResultGetProjectStateChangeReasonsByOwnerIds>(
      'api/Project/GetProjectStateChangeReasonsByOwnerIds',
      request
    );
  }
}
