import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('icon-copy')
export class IconCopy extends LitElement {
  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }
  render() {
    return html`<i class="fa-regular fa-copy"></i>`;
  }
}
