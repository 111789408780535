import { validId } from '../components/ui/helper-functions';
import { tlang } from '@softtech/webmodule-components';
import { resourceProject } from '../projects/ui/launcher';
import { resourcePurchaseOrder } from '../purchase-orders/ui/launcher';
import { resourceQuote } from '../quotes/ui/launcher';
import { resourceClient } from './clients/ui/launcher';
import { AppClientPage } from './pages/app-client';
import { AppClientsPage } from './pages/app-clients';
import { AppHome } from './pages/app-home';
import { AppProjectPage } from './pages/app-project';
import { AppProjectsPage } from './pages/app-projects';
import { AppPurchaseOrderPage } from './pages/app-purchase-order';
import { AppPurchaseOrdersPage } from './pages/app-purchase-orders';
import { AppQuotePage } from './pages/app-quote';
import { AppQuotesPage } from './pages/app-quotes';
import { AppSettings } from './pages/app-settings';
import { PageNotFound } from './pages/page-not-found';
import { AppLogin } from './pages/app-login';
import { pages } from './page-constants';
import { AppOutOfDate } from './pages/app-out-of-date';
import { appOutOfDate, outOfDateURL } from '../components/debug';

export function appConfigMenuPageItems() {
  const pageItems = [
    {
      name: pages.home,
      title: tlang`Dashboard`,
      path: '/'
    },
    {
      name: pages.projects,
      title: tlang`!!project!!`,
      path: '/projects'
    },
    {
      name: pages.quotes,
      title: tlang`!!quote!!`,
      path: '/quotes'
    },
    {
      name: pages.purchaseOrders,
      title: tlang`!!purchase-order!!`,
      path: '/purchase-orders'
    },
    {
      name: pages.clients,
      title: tlang`!!client!!`,
      path: '/clients'
    }
  ];
  pageItems.push({
    name: pages.settings,
    title: tlang`Settings`,
    path: '/settings'
  });
  if (appOutOfDate())
    return [
      {
        name: pages.outOfDate,
        title: tlang`Out Of Date`,
        path: outOfDateURL
      }
    ];
  return pageItems;
}
export function appConfig() {
  return {
    routes: [
      {
        path: '/',
        name: pages.home,
        component: 'app-home',
        action: async () => {
          //return home
          return new AppHome(); // await import("./pages/app-home");
        }
      },
      {
        path: '/login',
        name: pages.login,
        component: 'app-login',
        action: async () => {
          return new AppLogin();
        }
      },
      {
        path: outOfDateURL,
        name: pages.outOfDate,
        component: 'app-outofdate',
        action: async () => {
          return new AppOutOfDate();
        }
      },
      {
        path: '/clients',
        name: pages.clients,

        action: async () => {
          return new AppClientsPage();
        }
      },
      {
        path: '/clients/:id',
        name: pages.client,

        action: async () => {
          return new AppClientPage();
        },
        resolveUrl: {
          type: resourceClient,
          resolver: id => (validId(id) ? `/clients/${id}` : '/clients')
        }
      },
      {
        path: '/settings',
        name: pages.settings,
        action: async () => {
          return new AppSettings();
        }
      },
      {
        path: '/quotes',
        name: pages.quotes,
        action: async () => {
          return new AppQuotesPage();
        }
      },
      {
        path: '/quotes/:id',
        name: pages.quote,
        action: async () => {
          return new AppQuotePage();
        },
        resolveUrl: {
          type: resourceQuote,
          resolver: id => (validId(id) ? `/quotes/${id}` : '/quotes')
        }
      },
      {
        path: '/projects',
        name: pages.projects,
        component: 'app-projects',
        action: async () => {
          return new AppProjectsPage();
        }
      },
      {
        path: '/projects/:id',
        name: pages.project,
        action: async () => {
          return new AppProjectPage();
        },
        resolveUrl: {
          type: resourceProject,
          resolver: id => (validId(id) ? `/projects/${id}` : '/projects')
        }
      },
      {
        path: '/purchase-orders/:id',
        name: pages.purchaseOrder,

        action: async () => {
          return new AppPurchaseOrderPage();
        },
        resolveUrl: {
          type: resourcePurchaseOrder,
          resolver: id => (validId(id) ? `/purchase-orders/${id}` : '/purchase-orders')
        }
      },
      {
        path: '/purchase-orders',
        name: pages.purchaseOrders,

        action: async () => {
          return new AppPurchaseOrdersPage();
        }
      },
      {
        path: '(.*)',
        name: 'not-found',
        action: async () => {
          return new PageNotFound();
        }
      }
    ]
  };
}
