import { tlang } from '@softtech/webmodule-components';
import { TemplateResult, html } from 'lit';
import { ModalDialog } from '../../../components/ui/modal-base';
import { EventTemplate, Snippet } from '../../../components/ui/events';
import { DataBinding } from '../../../components/ui/databinding/databinding';
import { DataTracker } from '../../../components/ui/databinding/data-tracker';
import { isEmptyOrSpace } from '../../../components/ui/helper-functions';
import { showValidations } from '../../../components/ui/modal-validationhandler';
import { FormInputAssistant } from '../../../components/ui/templateresult/form-input-assistant';
import { buttonsSaveCancel } from '../../../components/ui/modal-confirmation';
import { customElement } from 'lit/decorators.js';

@customElement('wm-franchiseequotecreateprojectmodal')
export class FranchiseeQuoteCreateProjectModal extends ModalDialog {
  private dataBinding: DataBinding;
  private dataTracker: DataTracker;

  private _projectTitle: string = '';
  public get projectTitle(): string {
    return this._projectTitle;
  }
  protected set projectTitle(value: string) {
    this._projectTitle = value;
  }

  private _ok: boolean = false;
  public get ok(): boolean {
    return this._ok;
  }
  protected set ok(value: boolean) {
    this._ok = value;
  }

  constructor() {
    super();
    this.dataBinding = new DataBinding(this.ui);
    this.dataTracker = new DataTracker(this.dataBinding);
  }

  protected getTitle(): Snippet {
    return tlang`New %%project%%`;
  }

  protected bodyTemplate(): EventTemplate {
    const forms = new FormInputAssistant(this.dataTracker);

    return html`
      <form class="form-one-col">
        <div class="row">
          <div class="form-column">
            <h3>
              ${tlang`Changing the %%quote%% to Active will generate a %%project%% to contain all relevant documentation.`}
            </h3>
            <h6>${tlang`Please add a %%project%% title`}</h6>
            ${forms.textRequired('title', tlang`%%project%% Title`, 120)}
          </div>
        </div>
      </form>
    `;
  }

  protected footerTemplate(): TemplateResult | null {
    const okEvent = async () => {
      this.dataTracker.applyChangeToValue();
      const errors = await this.getValidationErrors();
      if (errors.length > 0) {
        this.ok = false;
        await showValidations(errors);
      } else {
        this.ok = true;
        this.projectTitle = this.dataBinding.getValue('title');
        await this.hideModal();
      }
    };
    const cancelEvent = async () => {
      this.projectTitle = '';
      await this.hideModal();
    };
    const buttons = buttonsSaveCancel();
    return this.createConfirmCancelButtons(buttons.ok, okEvent, buttons.cancel, cancelEvent);
  }

  protected async getValidationErrors(): Promise<string[]> {
    const errors: string[] = [];
    if (isEmptyOrSpace(this.dataBinding.getValue('title'))) {
      errors.push(tlang`Cannot have an empty Title for the %%project%%`);
    }
    return errors;
  }
}
