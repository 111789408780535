import { ApiCommunications } from '@softtech/webmodule-data-contracts';
import { DataCache } from './data-cache';
import { NullPromise } from '../null-promise';
import { isEmptyOrSpace } from '../components/ui/helper-functions';
import { DevelopmentError } from '../development-error';
import { DateTime } from 'luxon';
import { ItemReference } from './definitions/cache-item-reference';

const expiredDate = DateTime.fromJSDate(new Date('2000-01-01'));
export class DataCacheBase<DataType> implements DataCache<DataType> {
  api: ApiCommunications;
  cache: ItemReference<DataType>[] = [];
  expiryMinutes: number;
  constructor(api: ApiCommunications, cacheItemExpiryMinutes = 10) {
    this.api = api;
    this.expiryMinutes = cacheItemExpiryMinutes;
  }
  expireAll() {
    this.cache.forEach(x => (x.cacheInsertionDate = expiredDate));
  }
  async getData(id: string | null): NullPromise<DataType> {
    const item = await this.get(id);
    return item?.data as DataType;
  }
  async getDisplayValue(id: string | null): Promise<string> {
    const v = await this.get(id);
    return v?.displayValue ?? '';
  }
  isExpired(item: ItemReference<DataType>) {
    return (
      !item.cacheInsertionDate || Math.abs(item.cacheInsertionDate?.diffNow().as('minutes') ?? 0) > this.expiryMinutes
    );
  }
  async get(id: string | null): NullPromise<ItemReference<DataType>> {
    if (isEmptyOrSpace(id) || !id) return null;
    //remove double search
    //        const item = this.getValidLocal(id);
    //        if (item) return item;
    const items = await this.getMany([id]);
    if (items && items.length === 1) return items[0];
    return null;
  }
  getLocalData(id: string | null | undefined): DataType | null {
    const item = this.getLocal(id);
    return item?.data as DataType;
  }

  getLocal(id: string | null | undefined): ItemReference<DataType> | null {
    if (!id) return null;
    const result = this.cache.find(x => x.id === id);
    return result ?? null;
  }

  flush(ids: string[] | null) {
    if (!ids) this.cache = [];
    else ids.forEach(id => this.expire(id));
  }

  protected getValidLocal(id: string | null | undefined): ItemReference<DataType> | null {
    if (!id) return null;
    const result = this.cache.find(x => x.id === id);
    if (result && this.isExpired(result)) return null;
    return result ?? null;
  }

  async getMany(ids: string[]): NullPromise<ItemReference<DataType>[]> {
    const result: ItemReference<DataType>[] = [];
    const requestIds: string[] = [];
    ids.forEach(id => {
      if (!isEmptyOrSpace(id)) {
        const item = this.getValidLocal(id);
        if (!item) requestIds.push(id);
        else result.push(item);
      }
    });
    if (requestIds.length > 0) {
      const items = await this.internalFetch(requestIds);
      if (items) {
        this.addToCache(items);
        result.push(...items);
      }
    }
    return result;
  }

  protected replaceCacheItem(index: number, item: ItemReference<DataType>, _existingItem: ItemReference<DataType>) {
    this.cache[index] = item;
  }
  protected addOrUpdate(item: ItemReference<DataType>) {
    const index = this.cache.findIndex(x => x.id === item.id);
    if (index !== -1) this.replaceCacheItem(index, item, this.cache[index]);
    else this.cache.push(item);
  }
  protected addToCache(items: ItemReference<DataType>[] | null | undefined) {
    if (items) {
      const now = DateTime.now();
      items.forEach(x => {
        x.cacheInsertionDate = now;
        this.addOrUpdate(x);
      });
    }
  }
  //override this to get and transform the data
  protected async internalFetch(_requestIds: string[]): NullPromise<ItemReference<DataType>[]> {
    throw new Error('Method not implemented.');
  }

  getManyLocal(ids: string[]): ItemReference<DataType>[] | null {
    const result: ItemReference<DataType>[] = [];
    ids.forEach(id => {
      const item = this.getLocal(id);
      if (item) result.push(item);
      else throw new DevelopmentError('Cache.getManyLocal called and item is missing');
    });
    return result;
  }

  async preFetch(ids: string[]): Promise<void> {
    await this.getMany(ids);
  }

  expire(id: string) {
    const index = this.cache.findIndex(x => x.id == id);
    if (index >= 0) this.cache[index].cacheInsertionDate = expiredDate;
  }

  async updateLocal(...ids: string[]) {
    this.flush(ids);
    await this.getMany(ids);
  }
}
