import { html } from 'lit';
import { BranchQuoteSupportItemType, BranchQuoteSupportStatus } from '../../api/dealer-api-interface-franchisee';
import { DateTime } from 'luxon';
import { tlang } from '@softtech/webmodule-components';
import { DevelopmentError } from '../../development-error';
import { isEmptyOrSpace } from '../../components/ui/helper-functions';

export function getSupportStatusBadge(status?: BranchQuoteSupportStatus) {
  return html`<span class="badge ${getSupportTypeStatusClass(status)}"
    >${getSupportTypeStatus(status ?? BranchQuoteSupportStatus.New)}</span
  >`;
}

export function getSupportTypeStatusClass(status: BranchQuoteSupportStatus | undefined): unknown {
  return `badge-${BranchQuoteSupportStatus[status ?? BranchQuoteSupportStatus.New].toLowerCase()}`;
}
export function buildModifiedTime(modifedDate: DateTime): string {
  const diff = DateTime.now().diff(modifedDate, ['days', 'hours', 'minutes']).toObject();
  const time: string[] = [];
  if (diff.days && diff.days > 0) time.push(`${diff.days.toFixed(0)} days`);
  if (diff.hours && diff.hours > 0) time.push(`${diff.hours.toFixed(0)} hours`);
  if (diff.minutes && diff.minutes > 0) time.push(`${diff.minutes.toFixed(0)} min(s)`);
  const result = time.join(', ');
  return isEmptyOrSpace(result) ? tlang`now` : result;
}
export function getSupportTypeDisplayValue(type: BranchQuoteSupportItemType): string {
  switch (type) {
    case BranchQuoteSupportItemType.All:
      return tlang`${'ref:SupportInboxItemType.All'}All`;
    case BranchQuoteSupportItemType.Basic:
      return tlang`${'ref:SupportInboxItemType.Basic'}Basic`;
    case BranchQuoteSupportItemType.QuoteReview:
      return tlang`${'ref:SupportInboxItemType.QuoteReview'}%%quote%% Review`;
    case BranchQuoteSupportItemType.EngineeredToOrder:
      return tlang`${'ref:SupportInboxItemType.EngineeredToOrder'}%%special-item%%`;
    case BranchQuoteSupportItemType.QuoteAssistanceRequest:
      return tlang`${'ref:SupportInboxItemType.QuoteAssistanceRequest'}%%support-request%%`;
    default:
      throw new DevelopmentError(`Unsupported Type ${type}`);
  }
}
export function getSupportTypeStatus(type: BranchQuoteSupportStatus): string {
  switch (type) {
    case BranchQuoteSupportStatus.Draft:
      return tlang`${'ref:SupportInboxItemStatus.Active'}Draft`;
    case BranchQuoteSupportStatus.Active:
      return tlang`${'ref:SupportInboxItemStatus.Active'}Active`;
    case BranchQuoteSupportStatus.Cancelled:
      return tlang`${'ref:SupportInboxItemStatus.Cancelled'}Cancelled`;
    case BranchQuoteSupportStatus.New:
      return tlang`${'ref:SupportInboxItemStatus.New'}New`;
    case BranchQuoteSupportStatus.Overdue:
      return tlang`${'ref:SupportInboxItemStatus.Overdue'}Overdue`;
    case BranchQuoteSupportStatus.All:
      return tlang`${'ref:SupportInboxItemStatus.All'}All`;
    case BranchQuoteSupportStatus.Resolved:
      return tlang`${'ref:SupportInboxItemStatus.Resolved'}Resolved`;
    case BranchQuoteSupportStatus.ReActivated:
      return tlang`${'ref:SupportInboxItemStatus.Reactived'}Reactivated`;
    default:
      throw new DevelopmentError(`Unsupported Type ${type}`);
  }
}
