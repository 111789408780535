import { html } from 'lit';
import { EventSnippet, EventTemplate, Snippet } from './events';
import { ModalDialog } from './modal-base';
import { customElement, property } from 'lit/decorators.js';

export function waitPatiently(title: EventSnippet, message: EventSnippet) {
  const modal = new WaitPatiently();
  modal.eventTitle = title;
  modal.message = message;
  modal.delayedShow();
  return modal;
}
@customElement('wm-waitpatiently')
export class WaitPatiently extends ModalDialog {
  @property({ type: Object })
  eventTitle?: EventSnippet;
  @property({ type: Object })
  message?: EventSnippet;

  public delayedShow() {
    // we dont want to display things that are fast and flicker.. so wait before showing a dialog.
    setTimeout(() => {
      if (!this._hiding) this.showModal();
    }, 400);
  }
  public async hideModal(): Promise<void> {
    try {
      await super.hideModal();
    } catch {
      //ignore errors here. we might not have a modal to close
    }
  }
  protected modalSize(): string {
    return 'modal-md';
  }

  protected getTitle(): Snippet {
    return this.eventTitle?.() ?? '';
  }

  protected bodyTemplate(): EventTemplate {
    return html`
      <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status" style="width: 3rem; height:3rem;">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="alert alert-light text-center" role="alert">${this.message?.() ?? ''}</div>
    `;
  }
}
