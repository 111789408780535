import { html, render } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import {
  currentModalStackZIndex,
  nextModalStackZIndex,
  popFromModalZIndexStack,
  pushOntoModalZIndexStack
} from './components/ui/modal-helper';
import { NullPromise } from './null-promise';
import { DevelopmentError } from './development-error';
/**
 * file summary:
 * provide a global means of locking the UI to prevent access or change of UI state during certain types
 * of async activity such as tab changes, button clicks etc etc, until that activity has completed.
 * there are many times the ui should act in a responsive sync way.
 *
 * the goal of the global lock is to also conform to the modal dialog stack.
 * locking UI locks the UI at the current modal stack layer.
 *
 * new dialogs opened are not locked, nor are any error dialogs.
 */

export type EventAsync<TResult> = () => Promise<TResult>;

/**
 * wrap an async call and make sure the UI is locked during the call.
 * the called even may call modal dialogs that are created using the modal-helper
 * to present them at a higher level on the UI stack
 *
 * @param event the event to execute inside a locked UI State.
 * @returns returns the result of the passed in promise.
 */
export async function lockUIandExecute<TResult>(event: EventAsync<TResult>): NullPromise<TResult> {
  disableUI();
  try {
    return await event();
  } finally {
    enableUI();
  }
}

/**
 * this is a tracker for a UI locking element and its location on the stack
 */
interface LockStackItem {
  item: any;
  timer?: NodeJS.Timeout;
  zIndex: number;
  counter: number;
  observer?: MutationObserver;
}
/**
 * global container of stacked lockers
 */
let lockStack: LockStackItem[] = [];

/**
 *
 * @param zindex the index to place this item on the document body
 * @returns a new locking element
 */

/**
 *
 * @param zIndex
 * @returns the element which will be added to document body
 */
function getUILockElement(zIndex: number): HTMLDivElement {
  const d = document.createElement('div');
  render(
    html`${unsafeHTML(
      `<div class="readonly-background readonly-backdrop invisible" style="z-index: ${zIndex} !important;"></div>`
    )}`,
    d
  );

  document.body.appendChild(d);
  return d;
}

function lastStackItem(): LockStackItem | null {
  if (lockStack.length === 0) return null;
  return lockStack[lockStack.length - 1];
}

const callback = mutations => {
  mutations.forEach(nodes => {
    nodes.removedNodes.forEach(node => {
      //remove anything from the stack destroyed by a reconstruction of the dom
      const child = node.firstElementChild;
      if (child instanceof HTMLDivElement && child.classList.contains('readonly-background')) {
        const item = lockStack.find(x => x.item === node);

        if (item) {
          clearTimeout(item.timer);
          item.timer = undefined;
          detachItem(item);
        }
      }
    });
  });
};

const observer = new MutationObserver(callback);

/**
 * global call. any call to this must have exactly one call to enableUI in opposite to prevent a freezeover
 */
export function disableUI() {
  const item = lastStackItem();
  const curentZIndex = currentModalStackZIndex();
  // if the item does not exist, or the latest item does not have the highest zindex then it means
  // a new modal was added and we are needing to disable that.
  if (!item || curentZIndex !== item.zIndex) {
    const zIndex = nextModalStackZIndex();
    if (lockStack.length === 0) {
      const config = { childList: true };
      observer.observe(document.body, config);
    }
    const lockUI = getUILockElement(zIndex);
    const timer = setTimeout(() => {
      lockUI.querySelector('.readonly-background')?.classList.remove('invisible');
    }, 350);
    const item: LockStackItem = {
      counter: 1,
      zIndex: zIndex,
      item: lockUI,
      timer: timer
    };

    pushOntoModalZIndexStack(item, zIndex);
    lockStack.push(item);
  } else {
    //just increment the counter on the same item. nested call.
    item.counter++;
  }
}

export function enableUI() {
  const item = lastStackItem();
  if (!item) throw new DevelopmentError('UI Disabler out of sync');
  item.counter--;
  if (item.counter === 0) {
    //pull off the modal stack
    item.item.remove();
    detachItem(item);
  }
}
function detachItem(item: LockStackItem) {
  popFromModalZIndexStack(item);
  //delete from the UI
  //item.item.remove();
  // remove from the locking stack
  lockStack = lockStack.filter(x => x !== item);
  if (lockStack.length === 0) observer.disconnect();
}
