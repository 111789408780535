import { PaymentProfile } from '../../api/dealer-api-interface-franchisee';
import { emptyGuid, newGuid } from '../../api/guid';
import { PaymentProfileApi } from '../../api/payment-profile-api';
import { clone } from '../../components/clone';
import { ContainerManager } from '../../components/container-manager';
import { localDateToServer, today } from '../../components/datetime-converter';
import { EventSnippet } from '../../components/ui/events';
import { userDataStore } from '../../dealer-franchisee/common/current-user-data-store';
import { DevelopmentError } from '../../development-error';
import { tlang } from '@softtech/webmodule-components';
import { NullPromise } from '../../null-promise';

export class PaymentProfileContainer {
  paymentProfileId: string;
  paymentProfile: PaymentProfile | null;

  constructor(paymentProfileId: string, paymentProfile: PaymentProfile | null) {
    this.paymentProfileId = paymentProfileId;
    this.paymentProfile = paymentProfile;
  }
}

export class PaymentProfileContainerManager extends ContainerManager<PaymentProfileContainer> {
  protected paymentProfileApi: PaymentProfileApi;

  constructor(container: PaymentProfileContainer, title: EventSnippet, paymentProfileApi: PaymentProfileApi) {
    super(container, title, '%%payment-profile%%');
    this.paymentProfileApi = paymentProfileApi;
  }

  /**
   * the id for this managed container
   */
  public get paymentProfileId(): string {
    return this.container.paymentProfileId;
  }

  /**
   * returns the paymentProfile object after needsPaymentProfile is called, or throws an error if the paymentProfile is unavailable.
   */
  public get paymentProfile(): PaymentProfile {
    if (!this.container.paymentProfile) {
      throw new DevelopmentError(`payment-profile-container-manager.ts paymentProfile is null`);
    }
    return this.container.paymentProfile;
  }

  public async needsPaymentProfile() {
    if (!this.container.paymentProfile) {
      const result = await this.paymentProfileApi.getPaymentProfile({
        paymentProfileId: this.paymentProfileId
      });
      if (result && result.paymentProfile) {
        this.resetPaymentProfile(result.paymentProfile);
      } else return false;
    }
    return true;
  }

  /**
   * Override this to customize the save logic.
   * @returns True if the save operations have succeeded, false otherwise.
   */
  protected async internalSave(): Promise<boolean> {
    if (this.paymentProfileId == emptyGuid) {
      this.paymentProfile.id = newGuid();
      const result = await this.paymentProfileApi.createPaymentProfile({
        paymentProfile: this.paymentProfile
      });
      if (result && result.paymentProfile) {
        this.resetPaymentProfile(result.paymentProfile);
        return true;
      }
    } else {
      const result = await this.paymentProfileApi.updatePaymentProfile({
        paymentProfile: this.paymentProfile
      });
      if (result && result.paymentProfile) {
        this.resetPaymentProfile(result.paymentProfile);
        return true;
      }
    }
    return false;
  }

  public resetBackup() {
    this.backup.paymentProfile = clone(this.container.paymentProfile);
    this.backup.paymentProfileId = this.container.paymentProfileId;
  }
  private resetPaymentProfile(paymentProfile: PaymentProfile) {
    this.container.paymentProfile = paymentProfile;
    this.container.paymentProfileId = this.container.paymentProfile.id;
    this.backup.paymentProfile = clone(paymentProfile);
    this.backup.paymentProfileId = this.backup.paymentProfile.id;
  }
}

export async function createNewPaymentProfile(): NullPromise<PaymentProfileContainer> {
  const paymentProfile: PaymentProfile = {
    id: emptyGuid,
    dateCreated: localDateToServer(today()),
    name: tlang``,
    recordVersion: '',
    supplierId: emptyGuid,
    franchiseeId: userDataStore.franchisee.id,
    branchId: userDataStore.defaultBranch.id,
    margin: 0,
    discount: 0,
    estimateTerms: '',
    quoteTerms: ''
  };
  return new PaymentProfileContainer(emptyGuid, paymentProfile);
}
