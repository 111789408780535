import { tlang } from '@softtech/webmodule-components';

import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { Branch } from '../../api/dealer-api-interface-franchisee';
import { clone, cloneOrNull, compare } from '../../components/clone';
import { DataTracker, FieldType } from '../../components/ui/databinding/data-tracker';
import { DataBinding } from '../../components/ui/databinding/databinding';
import { FormInputAssistant } from '../../components/ui/templateresult/form-input-assistant';
import { AddressEditor } from '../../components/ui/address-editor';

@customElement('wm-franchisee-branch-editor')
export class WebModuleFranchiseeBranchEditor extends LitElement {
  private _backupBranch?: Branch;
  @property()
  private _branch?: Branch | undefined;

  public get branch(): Branch | undefined {
    return this._branch;
  }
  public set branch(value: Branch | undefined) {
    this._branch = clone(value);
    this._backupBranch = clone(value);
  }
  @property() readonly = false;
  dataTracker: DataTracker = new DataTracker(new DataBinding(this, null));
  constructor() {
    super();
    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.branch),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };
    /*
export interface Branch {
    dateCreated: string;
    id: string;
    recordVersion: string;
    franchiseeId: string;
    abbreviation: string;
    name: string;
    physicalAddress: Address;
    billingAddress: Address | null;
    physicalAddressAsDefaultShipping: boolean;
}

        */
    addField('abbreviation', FieldType.string, false);
    addField('name', FieldType.string, false);
    addField('taxRegistrationNumber', FieldType.string, false);
  }

  dispatchCustom(name: string, values: object) {
    const options = {
      detail: { ...values },
      bubbles: true,
      composed: true
    };
    //wm-be webmodule-brancheditor
    this.dispatchEvent(new CustomEvent(`wm-be-${name}`, options));
  }
  branchChangedEvent() {
    this.dispatchCustom('changed', {
      branch: this.branch
    });
  }
  private changeEvent = (e: Event) => {
    e.stopImmediatePropagation();

    this.dataTracker.applyChangeToValue();
    if (!compare(this._branch, this._backupBranch)) {
      this._backupBranch = clone(this._branch);
      this.requestUpdate();
      this.branchChangedEvent();
    }
  };
  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('change', this.changeEvent);
  }
  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.removeEventListener('change', this.changeEvent);
  }
  render() {
    const forms = new FormInputAssistant(this.dataTracker, this.readonly);
    if (!this.branch) return html``;
    return html`<div class="form-two-col p-3 branch-form">
      <h2>${tlang`%%branch%% Settings`}</h2>
      <div class="row">
        <div>${forms.text('name', tlang`Name`, 100)}</div>
        <div>${forms.text('abbreviation', tlang`Abbreviated Name`, 20)}</div>
      </div>
      <div class="row">
        <div>${forms.text('taxRegistrationNumber', tlang`Tax Registration #:`, 100)}</div>
      </div>
      <wm-addresseditor
        @wm-ae-changed=${(e: Event) => this.addressModified(e.currentTarget as AddressEditor)}
        id="physicalAddressEditor"
        .address=${this.branch.physicalAddress}
        .shippingNotes=${this.branch.shippingNotes}
        .title=${tlang`Physical Address`}
        .readonly=${this.readonly}
        .isDefaultShippingVisible=${true}
        .isDefaultShipping=${this.branch.physicalAddressAsDefaultShipping}
      ></wm-addresseditor>
    </div> `;
  }
  addressModified(editor: AddressEditor) {
    if (!this.branch) return;
    if (editor.address) this.branch.physicalAddress = clone(editor.address);
    this.branch.shippingNotes = editor.shippingNotes ?? '';
    this.branch.physicalAddressAsDefaultShipping = editor.isDefaultShippingVisible && editor.isDefaultShipping;
    this.branchChangedEvent();
  }
  billingAddressModified(editor: AddressEditor) {
    if (!this.branch) return;
    this.branch.billingAddress = cloneOrNull(editor.address);
    this.branchChangedEvent();
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }
}
