import { AddressEditor } from './address-editor';
import { ButtonSwitch } from './button-switch';
import { FranchiseeQuoteItemTable } from '../../dealer-franchisee/quotes/views/franchisee-quote-items-view';
import { WebmoduleAutoSaveIndicator } from '../context/autoSaveNotifier';

export const webcomponentRegistry = {
  addressEditor: AddressEditor,
  buttonSwitch: ButtonSwitch,
  franchiseeQuoteItemTable: FranchiseeQuoteItemTable,
  webmoduleAutoSaveIndicator: WebmoduleAutoSaveIndicator
};
