/// <summary>
/// examples
///   QuoteItemType.Provider (reserved 1000+)
///     1000 = frame
///     1001 = component DataProvider.Data = " { objectreference: "bla", libid, id, classid }
///     1002 = extn
///   QuoteItemType.Basic (reserved 1)
///     1 = Shipping
///     2 = Freehand
/// </summary>

//properties for QuoteItemType on the quote item, when the provider is v6,
//but we will expect other providers to map and use the same values for consistency
import { tlang } from '@softtech/webmodule-components';

export const supplierQuoteItemContentType = {
  CID_FRAM: 7,
  CID_COMP: 2,
  CID_EXTN: 3,
  CID_FILL: 4,
  CID_IGU: 90, //QTE_IGU = 52, but we don't need
  CID_LOUVRE: 100, //TODO - put real value,
  CID_FREEHAND: 10
};

export function supplierItemContentTypeDisplay(contentType: number, plural?: boolean, includeUnit?: boolean) {
  const tag: string = plural ? '!!' : '%%';

  const returnValue: string =
    contentType == supplierQuoteItemContentType.CID_FRAM
      ? `${tag}frame${tag}${includeUnit ? ' (individual)' : ''}`
      : contentType == supplierQuoteItemContentType.CID_COMP
        ? `${tag}component${tag}${includeUnit ? ' (individual)' : ''}`
        : contentType == supplierQuoteItemContentType.CID_EXTN
          ? `${tag}extrusion${tag}${includeUnit ? ' (length)' : ''}`
          : contentType == supplierQuoteItemContentType.CID_FILL
            ? `${tag}fill${tag}${includeUnit ? ' (area squared)' : ''}`
            : contentType == supplierQuoteItemContentType.CID_IGU
              ? `${tag}igu${tag}${includeUnit ? ' (area squared)' : ''}`
              : contentType == supplierQuoteItemContentType.CID_FREEHAND
                ? `${tag}freehand${tag}${includeUnit ? '(individual)' : ''}`
                : contentType == supplierQuoteItemContentType.CID_LOUVRE
                  ? `${tag}louvre${tag}${includeUnit ? ' (area squared)' : ''}`
                  : `Unknown Type`;

  const myEval = new Function('val', 'return val');

  return tlang(myEval(returnValue));
}
