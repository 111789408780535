import { tlang } from '@softtech/webmodule-components';
import { AskConfirmation } from '../../../components/ui/modal-confirmation';
import { userDataStore } from '../../common/current-user-data-store';
import { information } from '../../../components/ui/modal-option';

export async function branchSwitchOnResource(resourceBranchId: string, resourceName: string) {
  if (resourceBranchId !== userDataStore.defaultBranch.id) {
    if (userDataStore.hasBranch(resourceBranchId)) {
      const branch = userDataStore.branchById(resourceBranchId);
      if (
        await AskConfirmation(tlang`the ${resourceName} you are trying to open belongs to %%branch%% **'${branch.name}'**
              
              **Do you want to switch %%branch%% to view the ${resourceName}?**
              
              Press cancel to stay on %%branch%% **'${userDataStore.defaultBranch.name}'**
              `)
      ) {
        //
        userDataStore.defaultBranch = branch;
      } else return false;
    } else {
      await information(tlang`this ${resourceName} is not part of this branch and cannot be accessed`);
      return false;
    }
  }
  return true;
}
