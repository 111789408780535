import { getCurrentUser } from '@softtech/webmodule-components';
import { emptyGuid, newGuid } from '../../api/guid';
import { validId } from '../../components/ui/helper-functions';
import { getSessionLock } from './optimistic-lock';

const sessionLockKey = 'dealer:sessionlock';
function getMachineLock(): string {
  let lock = localStorage.getItem(sessionLockKey) ?? '';
  if (!validId(lock)) {
    lock = newGuid();
    localStorage.setItem(sessionLockKey, lock);
  }
  return lock;
}
//451A0DF3-4D7D-4E9B-863D-28CE5C185FDC:7E653B46-23C1-43B2-BE62-2FCEB0A1939B:1000:BE881FBC-48BC-4364-965F-E5D6DA8CAACD
export function getUserLock(): string {
  const user = getCurrentUser();
  //const lock = `${getMachineLock()}:${getSessionLock()}:${user?.tenantId ?? 0}:${user?.id ?? emptyGuid}`;
  const lock = `${getMachineLock()}:${getSessionLock()}:${user?.tenantId ?? 0}:${user?.id ?? emptyGuid}`;
  return lock;
}
