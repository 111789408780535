import {
  PurchaseOrderDataEntryView,
  PurchaseOrderView,
  PurchaseOrderViewChildFactory,
  PurchaseOrderViewChildFactoryImpl
} from '../../../purchase-orders/views/purchase-order-view';
import {
  PurchaseOrderDetailView,
  PurchaseOrderDetailViewOptions
} from '../../../purchase-orders/views/purchase-order-detail-view';
import { FranchiseePurchaseOrderDetailView } from './franchisee-purchase-order-detail-view';
import { flagInSet, validId } from '../../../components/ui/helper-functions';
import { PropertyValueMap, html } from 'lit';
import { getPurchaseOrderTitle } from '../../../purchase-orders/data/purchase-order-helper-functions';
import { cache } from '../../cache/cache-registry';
import { emptyGuid } from '../../../api/guid';
import { DevelopmentError } from '../../../development-error';
import { SupplierApi } from '../../../api/supplier-api';
import { getApiFactory } from '../../../api/api-injector';
import { PurchaseOrderState, PurchaseOrderStateChangeReason } from '../../../api/dealer-api-interface-franchisee';
import { AskConfirmation } from '../../../components/ui/modal-confirmation';
import { tlang } from '@softtech/webmodule-components';
import { BlobApi } from '../../../api/blob-api';
import { PurchaseOrderApi } from '../../../api/purchase-order-api';
import { V6SupplierQuoteBase } from '../../../quotes/data/v6/v6-supplier-quote';
import { fireQuickInformationToast } from '../../../toast-away';
import { resourceQuote } from '../../../quotes/ui/launcher';
import { goURL, resolveURL } from '../../../components/ui/resource-resolver';
import { resourceProject } from '../../../projects/ui/launcher';
import { NullPromise, Snippet } from '@softtech/webmodule-data-contracts';
import { userDataStore } from '../../common/current-user-data-store';
import { getPurchaseOrderStateChangeReasonsForState } from '../../cache/purchase-order-state-change-reason-cache';
import { getConfirmationReasonFor } from '../../../components/ui/modal-confirmation-reason';
import { validateOutOfDateQuoteItems } from '../../../quotes/views/quote-item-validation-modal';
import { V6OutOfDateQuoteItemHandlerSafeMode } from '../../quotes/data/v6/out-of-date-quote-item-handler-safe-mode';
import { FranchiseeQuoteContainerManager } from '../../quotes/data/franchisee-quote-manager';
import { customElement, state } from 'lit/decorators.js';
import { ProjectCacheData, QuoteCacheData } from '../../cache/cache-data';
import { ItemReference } from '../../../cache/definitions/cache-item-reference';

export class FranchiseePurchaseOrderViewChildFactoryImpl extends PurchaseOrderViewChildFactoryImpl {
  protected get view(): FranchiseePurchaseOrderDEView {
    return this.parent as FranchiseePurchaseOrderDEView;
  }

  override getDetailView(options: PurchaseOrderDetailViewOptions): PurchaseOrderDetailView {
    return new FranchiseePurchaseOrderDetailView({
      purchaseOrderManager: options.purchaseOrderManager,
      purchaseOrderCache: options.purchaseOrderCache
    });
  }
}
@customElement('wm-franchiseepurchaseorderdeview')
export class FranchiseePurchaseOrderDEView extends PurchaseOrderDataEntryView {
  quoteCache = cache().quote;
  projectCache = cache().project;
  prlCache = cache().projectResourceLink;
  supplierApi: SupplierApi = getApiFactory().supplier();
  blobApi: BlobApi = getApiFactory().blob();
  purchaseOrderApi: PurchaseOrderApi = getApiFactory().purchaseOrder();
  branchId: string = emptyGuid;
  @state()
  private _quoteId: string = emptyGuid;
  @state()
  private _projectId: string = emptyGuid;
  @state()
  private _projectCacheItem: ItemReference<ProjectCacheData> | null = null;
  @state()
  private _quoteCacheItem: ItemReference<QuoteCacheData> | null = null;

  public update(changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    super.update(changedProperties);
  }
  public override async afterConstruction(): Promise<void> {
    await super.afterConstruction();

    if (this.purchaseOrderContainer.purchaseOrder) {
      await this.purchaseOrderCache.preFetch([this.purchaseOrderContainerManager.purchaseOrderId]);
      this.branchId =
        this.purchaseOrderCache.getLocalData(this.purchaseOrderContainerManager.purchaseOrderId)?.purchaseOrder
          .branchId ?? emptyGuid;
      await Promise.all([
        this.quoteCache.preFetch([await this.quoteId()]),
        this.projectCache.preFetch([await this.projectId()])
      ]);

      await this.loadExternalRelatedData();
    }
  }
  protected async loadExternalRelatedData() {
    this._quoteId = await this.quoteId();
    this._projectId = await this.projectId();
    this._projectCacheItem = await this.projectCache.get(this._projectId);
    this._quoteCacheItem = await this.quoteCache.get(this._quoteId);
    this.dispatchUiChanged();
  }
  public override getTitle(): Snippet {
    const po = this.purchaseOrderContainer.purchaseOrder;
    if (!po) return html``; // will never happen

    const quoteId = this._quoteId;
    const projectId = this._projectId;

    const projectCacheItem = this._projectCacheItem;
    const quoteCacheItem = this._quoteCacheItem;

    const projectTemplate =
      validId(projectId) && projectCacheItem
        ? html`<a href="${resolveURL(resourceProject, projectId)}" class="btn btn-sm btn-light"
            >${projectCacheItem.displayValue}</a
          >`
        : html``;

    const quoteTemplate =
      validId(quoteId) && quoteCacheItem
        ? html`<a href="${resolveURL(resourceQuote, quoteId)}" class="btn btn-sm btn-light"
            >${quoteCacheItem?.displayValue}</a
          >`
        : html``;

    return html`
      ${getPurchaseOrderTitle(po)}
      <div class="title-links">${projectTemplate} ${quoteTemplate}</div>
    `;
  }

  protected async quoteId(): Promise<string> {
    if (!this.purchaseOrderContainer.purchaseOrder) return emptyGuid;
    const data = await this.prlCache.getData(this.purchaseOrderContainer.purchaseOrder.id);
    return data?.quoteId ?? emptyGuid;
  }

  protected async projectId(): Promise<string> {
    if (!this.purchaseOrderContainer.purchaseOrder) return emptyGuid;
    const data = await this.prlCache.getData(this.purchaseOrderContainer.purchaseOrder.id);
    return data?.projectId ?? emptyGuid;
  }

  protected override getPurchaseOrderViewChildFactory(): PurchaseOrderViewChildFactory {
    return new FranchiseePurchaseOrderViewChildFactoryImpl(this);
  }

  protected override async internalSetState(state: PurchaseOrderState): Promise<boolean> {
    let doStateChange = false;
    const initialState = this.purchaseOrder.state;
    switch (state) {
      case PurchaseOrderState.IssuedPending:
        doStateChange = await this.issueConfirmation();
        break;
      case PurchaseOrderState.Issued:
        throw new DevelopmentError('Cannot set State to Issued');
        break;
      case PurchaseOrderState.Completed:
        doStateChange = await this.completeConfirmation();
        break;
      case PurchaseOrderState.Cancelled:
        doStateChange = await this.cancelConfirmation();
        break;
      case PurchaseOrderState.None:
      case PurchaseOrderState.Draft:
        break;
    }

    if (!doStateChange) return false;

    //super is setting the state and calling auto save.
    let saved = await super.internalSetState(state);
    if (saved && this.purchaseOrder.state == PurchaseOrderState.IssuedPending) {
      let success = true;
      try {
        success = await V6SupplierQuoteBase.issuePurchaseOrder(
          this.branchId,
          await this.quoteId(),
          await this.projectId(),
          this.purchaseOrder.id
        );
      } catch {
        success = false;
      }
      if (!success)
        // Getting here means that report wasn't generated and uploaded. Reset the PO state to original
        saved = await super.internalSetState(initialState);
      else {
        fireQuickInformationToast(
          tlang`%%purchase-order%% has been submitted and you will be emailed a link when the order is issued`
        );
      }
    } else if (
      (saved && this.purchaseOrder.state == PurchaseOrderState.Cancelled) ||
      this.purchaseOrder.state == PurchaseOrderState.Completed
    ) {
      goURL(resourceProject, await this.projectId(), 'resources');
    }

    return saved;
  }

  private async issueConfirmation(): Promise<boolean> {
    const terms = await this.supplierApi.getSupplierTAC({
      supplierId: this.purchaseOrder.supplierId
    });

    return await AskConfirmation(
      terms?.supplierPurchaseOrderTermsAndConditions ?? tlang`Are you sure you wish to Issue the %%purchase-order%%?`,
      {
        ok: tlang`Issue`,
        cancel: tlang`Cancel`
      },
      'modal-lg'
    );
  }
  canIssue(): boolean | undefined {
    return !this.detailView.isOutOfDate() && flagInSet(this.purchaseOrder.state, PurchaseOrderState.Draft);
  }
  private async completeConfirmation(): Promise<boolean> {
    return await AskConfirmation(tlang`Are you sure you wish to Complete the %%purchase-order%%?`, {
      ok: tlang`Yes`,
      cancel: tlang`No`
    });
  }
  async performQuoteValidation(): Promise<boolean> {
    const handler = new V6OutOfDateQuoteItemHandlerSafeMode(
      this.purchaseOrderContainerManager.quoteManager as FranchiseeQuoteContainerManager
    );
    await validateOutOfDateQuoteItems(this.purchaseOrderContainerManager.quoteManager, handler);
    return true;
  }
  private async cancelConfirmation(): Promise<boolean> {
    const title = tlang`Cancel %%purchase-order%%`;
    const msg =
      this.purchaseOrder.state == PurchaseOrderState.Issued
        ? tlang`${'ref:cancelIssuedPurchaseOrderWarning:markdown'}
The %%purchase-order%% has already been issued and cancelling can have financial implications.  
Cancelling the %%purchase-order%% here has no effect on the order at the %%supplier%%.
Contact the %%supplier%% to get the order cancelled.  
Do you want to Cancel the %%purchase-order%%?`
        : tlang`Please select the reason for cancelling the %%purchase-order%%`;
    const label = tlang`Cancellation Reason`;
    return await this.tryGetUserProvidedStateChangeReason(PurchaseOrderState.Cancelled, title, msg, label, title);
  }

  protected async tryGetUserProvidedStateChangeReason(
    purchaseOrderState: PurchaseOrderState,
    modalTitle: string,
    modalMessage: string,
    selectReasonLabel: string,
    confirmButtonText: string
  ): Promise<boolean> {
    const stateReasons = await this.getPurchaseOrderStateReasons(purchaseOrderState);
    if (stateReasons) {
      const reasonResult = await getConfirmationReasonFor(
        stateReasons,
        modalTitle,
        modalMessage,
        selectReasonLabel,
        confirmButtonText
      );
      if (reasonResult.result && reasonResult.reason) {
        const selectedReason = reasonResult.reason as PurchaseOrderStateChangeReason;
        this.purchaseOrderContainerManager.stateChangeReason = {
          comment: reasonResult.comment ?? '',
          stateChangeReason: selectedReason
        };
        return true;
      }
      return false;
    }
    return true;
  }
  protected override async getPurchaseOrderStateReasons(
    purchaseOrderState: PurchaseOrderState
  ): NullPromise<PurchaseOrderStateChangeReason[]> {
    const franchiseeId = userDataStore.franchisee.id;
    const stateReasons = await getPurchaseOrderStateChangeReasonsForState(purchaseOrderState, franchiseeId);
    if (stateReasons) {
      const displayOrder = stateReasons.presentation.itemDisplayOrder;
      const sortedItems: PurchaseOrderStateChangeReason[] = [];
      displayOrder.forEach(elementId => {
        const item = stateReasons.stateChangeReasons.find(item => item.id === elementId);
        if (item) {
          sortedItems.push(item);
        }
      });
      return sortedItems;
    }
    return null;
  }
}
@customElement('wm-franchiseepurchaseorderview')
export class FranchiseePurchaseOrderView extends PurchaseOrderView {
  protected createView(): PurchaseOrderDataEntryView {
    return new FranchiseePurchaseOrderDEView(this.options, this);
  }
}
