import { css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { urlForName } from '../../components/router';
import { tlang } from '@softtech/webmodule-components';
import { PageBase } from './helpers/page-base';
import { pages } from '../page-constants';

@customElement('page-not-found')
export class PageNotFound extends PageBase {
  static styles = css`
    :host {
      display: block;
    }

    section {
      padding: 1rem;
      text-align: center;
    }
  `;

  render() {
    return html`
      <section>
        <h1>${tlang`Page not found`}</h1>
        <p>
          <a href="${urlForName(pages.home)}">${tlang`Back to home`}</a>
        </p>
      </section>
    `;
  }
}
