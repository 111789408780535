//Copyright - Kyley Harris (2021) - All rights to copy and reuse allowed
import { tlang } from '@softtech/webmodule-components';
import * as db from './indexed-db';
import { GetOptions } from './indexed-db';
// wrap indexed db in a handler.
function information(title: string, message: string) {
  alert(title + ' ' + message);
}

export class IndexedDBAccess {
  private _connection: IDBDatabase | null;

  constructor() {
    this._connection = null;
  }

  public dbName(): string {
    throw new Error('override dbname');
  }
  private close() {
    if (this._connection) {
      this._connection.close();
      this._connection = null;
    }
  }
  public async connect(): Promise<IDBDatabase> {
    if (this._connection) {
      db.log('open connection requested again');
      return this._connection;
    }

    this._connection = await db.openDb({
      dbName: this.dbName(),
      version: this.version(),
      upgrades: this.getDatabaseUpgrades(),
      onBlocked: () => {
        information(
          tlang`Database Access Blocked`,
          tlang`Please close all other browser tabs/windows accessing this page to continue`
        );
      }
    });

    this._connection.onversionchange = () => {
      if (this._connection) this._connection.close();
      db.log('a new version of the databse is ready, please reload this page');
      information(tlang`Application Update`, tlang`Please reload the page to continue`);
    };

    return this._connection;
  }

  protected version(): number {
    let version = 1;
    this.getDatabaseUpgrades().forEach(x => (version = Math.max(version, x.version)));
    return version;
  }

  //return a list of upgrades that get this database to the correct version
  protected getDatabaseUpgrades(): db.DatabaseUpgrade[] {
    throw new Error('Method not implemented.');
  }

  public async getFirst<T>(objectStore: string, options?: GetOptions): Promise<T | null> {
    const conn = await this.connect();
    let log = await db.get<T>(conn, objectStore, options);
    this.close();
    return log;
  }
  store(type: any): string {
    return type.name;
  }

  public async getAll<T>(objectStore: string, options?: GetOptions): Promise<T[]> {
    const conn = await this.connect();
    try {
      return db.getAll<T>(conn, objectStore, options);
    } finally {
      this.close();
    }
  }
  public async put<T>(item: T, objectStore: string): Promise<boolean> {
    const conn = await this.connect();
    try {
      await db.put<T>(conn, objectStore, item);
      return true;
    } catch {
      return false;
    } finally {
      this.close();
    }
  }

  public async putMany<T>(items: T[], objectStore: string): Promise<boolean> {
    const conn = await this.connect();
    try {
      await db.putAll<T>(conn, objectStore, items);
      return true;
    } catch {
      return false;
    } finally {
      this.close();
    }
  }

  public async del(id: IDBValidKey, objectStore: string): Promise<void> {
    const conn = await this.connect();
    try {
      await db.del(conn, objectStore, id);
    } finally {
      this.close();
    }
  }
}
