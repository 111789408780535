import { DocumentSubType, ResourceType } from '../../api/dealer-api-interface-project';
import { NullPromise } from '../../null-promise';
import { cache } from './cache-registry';

export async function getPermanentProjectDocumentURL(
  projectId: string,
  resourceId: string,
  resourceType: ResourceType,
  subType: DocumentSubType,
  forceCheck = true
): NullPromise<string> {
  const document = async () => {
    const results = await cache().projectPermanentDocuments.getData(projectId);
    if (!results) return null;
    const result = results.documents.find(item => {
      const dt = item.documentTracker;
      return dt.resourceId == resourceId && dt.resourceType == resourceType && dt.subType == subType;
    });
    if (result) return result.publicFileURL;
    return null;
  };
  const foundDocument = await document();
  if (foundDocument) {
    return foundDocument;
  } else if (forceCheck) {
    cache().projectPermanentDocuments.flush([projectId]);
    return await document();
  }
  return null;
}
