import { TemplateResult, html } from 'lit';
import { EventTemplate, Snippet } from './events';
import { ModalDialog } from './modal-base';
import { tlang } from '@softtech/webmodule-components';
export async function previewImage(src: string) {
  const d = new ImagePreview(src);
  await d.showModal();
}
export class ImagePreview extends ModalDialog {
  src: string;

  constructor(src: string) {
    super();
    this.src = src;
  }
  protected modalClasses(): string {
    return 'modal-dialog ';
  }
  protected modalSize(): string {
    return 'img-modal modal-fullscreen';
  }
  protected template(): TemplateResult {
    const modalContentClasses = `modal-content  border border-${this.borderType()}`;
    const modalClasses = `${this.modalClasses()} ${this.modalSize()}`;
    const eventClose = () => this.hideModal();
    return html` <div
      @click=${eventClose}
      class="modal "
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
    >
      <div class=${modalClasses}>
        <div class=${modalContentClasses}>
          <div class="image-preview d-flex justify-content-center">
            <img @click=${eventClose} src=${this.src} />
          </div>
        </div>
      </div>
    </div>`;
  }

  protected getTitle(): Snippet {
    return tlang`Image Preview`;
  }
  protected bodyTemplate(): EventTemplate {
    return html``;
  }
}
