import { TemplateResult } from 'lit';
import { EventTemplate, Snippet } from './events';
import { ModalDialog } from './modal-base';
import { customElement } from 'lit/decorators.js';
import { LitModalScreenEvents } from '@softtech/webmodule-data-contracts';

export function litModalScreenFactoryImpl(event: LitModalScreenEvents): LitModalScreen {
  return new LitModalScreen(event);
}

@customElement('wm-litmodalscreen')
class LitModalScreen extends ModalDialog implements LitModalScreen {
  events: LitModalScreenEvents;
  constructor(events: LitModalScreenEvents) {
    super();
    this.events = events;
    this.events.owner = this;
  }
  get hiding(): boolean {
    return this._hiding;
  }
  protected ZIndex(): number | undefined {
    return this.events.zIndex;
  }
  private asString(val) {
    if (typeof val == 'function') return val();
    return val;
  }
  protected modalSize(): string {
    let size = this.asString(this.events.modalSize);
    if (!size) size = super.modalSize();
    return size;
  }
  protected modalClasses(): string {
    return this.events.modalClasses ?? super.modalClasses();
  }
  protected getTitle(): Snippet {
    return this.events.title();
  }
  protected async onShowModal(): Promise<void> {
    await this.events.onShowModal?.();
  }
  async canClose(): Promise<boolean> {
    return (await this.events.canClose?.()) ?? super.canClose();
  }
  bodyTemplate(): EventTemplate {
    return this.events.bodyTemplate();
  }
  protected renderFooterTemplate(): boolean {
    return this.events.isFooterVisible;
  }
  protected footerTemplate(): TemplateResult | null {
    return this.events.footerTemplate?.() ?? null;
  }
}
