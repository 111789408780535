import { ApiCommunications } from '@softtech/webmodule-data-contracts';
import {
  InputCopyFilesByVirtualPath,
  InputDeleteFilesByVirtualPath,
  InputUpdateFileByVirtualPath,
  ResultCopyFilesByVirtualPath,
  ResultDeleteFilesByVirtualPath,
  ResultUpdateFileByVirtualPath
} from '../api/dealer-api-interface-blob';
import { NullPromise } from '../null-promise';
import { BlobApi } from './blob-api';

export class BlobApiImpl implements BlobApi {
  api: ApiCommunications;

  constructor(api: ApiCommunications) {
    this.api = api;
  }

  async copyFileByVirtualPath(input: InputCopyFilesByVirtualPath): NullPromise<ResultCopyFilesByVirtualPath> {
    return await this.api.post<ResultCopyFilesByVirtualPath>('api/File/CopyFilesByVirtualPath', input);
  }

  async deleteFilesByVirtualPath(input: InputDeleteFilesByVirtualPath): NullPromise<ResultDeleteFilesByVirtualPath> {
    return await this.api.post<ResultDeleteFilesByVirtualPath>('api/File/DeleteFilesByVirtualPath', input);
  }

  async updateFileByVirtualPath(input: InputUpdateFileByVirtualPath): NullPromise<ResultUpdateFileByVirtualPath> {
    return await this.api.post<ResultUpdateFileByVirtualPath>('api/File/UpdateFileByVirtualPath', input);
  }
}
