// noinspection HtmlUnknownAttribute

// eslint-disable-next-line import/named
import { TemplateResult, html } from 'lit';
import { MenuIconOption, PageControl, PageControlOptions, PageManager } from '../../components/ui/page-control';
import { EventBoolean, EventSnippet, EventTemplate, Snippet } from '../../components/ui/events';
import { ClientApi } from '../../api/client-api';
import { ClientDetailView } from './client-detail-view';
import { tlang } from '@softtech/webmodule-components';
import { getApiFactory } from '../../api/api-injector';
import { allowPageControlTabChange, isAutoSaving } from '../../components/save-workflow';
import { ClientContainer, ClientContainerManager as ClientContainerManager } from '../data/client-container-manager';
import { DataEntryPageControlView, ModalViewBase } from '../../components/ui/data-entry-screen-base';
import { DataEntryOwner } from '../../components/ui/DataEntryOwner';
import { constructAsync } from '../../async-constructor';
import { validId } from '../../components/ui/helper-functions';
import { buttonsSaveCancel } from '../../components/ui/modal-confirmation';
import { customElement } from 'lit/decorators.js';

export interface ClientViewOptions {
  viewTitle: EventSnippet;
  clientContainerManager: ClientContainerManager;
}
@customElement('wm-clientdataentryview')
export class ClientDataEntryView extends DataEntryPageControlView {
  clientApi: ClientApi = getApiFactory().client();

  options: ClientViewOptions;
  get forceLocked(): EventBoolean | undefined {
    return this.options.clientContainerManager.forceLocked;
  }
  public get clientContainer(): ClientContainer {
    return this.clientContainerManager.container;
  }
  protected clientContainerManager: ClientContainerManager;
  private get eventTitle(): EventSnippet {
    return this.options.viewTitle;
  }
  private readonly detailView: ClientDetailView;

  constructor(options: ClientViewOptions, owner: DataEntryOwner) {
    super(owner);

    this.options = options;
    this.clientContainerManager = options.clientContainerManager;

    this.detailView = this.clientDetailViewFactory();
  }

  /**
   * inherited;
   * @returns
   */
  protected bodyTemplate(): EventTemplate {
    this.buildClientActionMenu();
    return super.bodyTemplate();
  }
  protected isNew(): boolean {
    return !validId(this.clientContainer.clientId);
  }
  /**
   * inherited;
   * @returns
   */
  public async afterConstruction() {
    await this.clientContainerManager.needsClient();

    await constructAsync(this.detailView);
    //this will create the pagecontrol
    await super.afterConstruction();
    this.buildClientActionMenu();
  }

  /**
   * inherited;
   * @returns
   */
  protected createPageControl(): PageControl {
    // build static pages for each of the configured table settings
    const getInitialPageManagers = (): PageManager[] => {
      const pages: PageManager[] = [];
      pages.push(this.detailView?.createPageManager());
      return pages;
    };

    const options: PageControlOptions = {
      defaultTabIndex: 0,
      menuIcons: undefined,
      pageInitializer: () => getInitialPageManagers()
    };
    return new PageControl(options);
  }

  /**
   * inherited;
   * @returns
   */
  public internalDataChanged(): boolean {
    return this.clientContainerManager.changed();
  }

  /**
   * inherited;
   * @returns
   */
  protected async internalSaveData(): Promise<boolean> {
    return await this.clientContainerManager.saveClient(isAutoSaving());
  }

  /**
   * inherited;
   * @returns
   */
  public isDataReadonly(): boolean {
    return this.clientContainerManager.isReadonly();
  }

  /**
   * inherited;
   * @returns
   */
  protected getValidationErrors() {
    const errors = this.detailView.getValidationErrors();
    return errors;
  }

  /**
   * inherited;
   * @returns
   */
  public async prepareForSave(): Promise<void> {
    if (this.isDataReadonly()) return;

    this.detailView.prepareForSave();
  }

  /**
   * inherited;
   * @returns
   */
  public getTitle(): Snippet {
    return html`${this.eventTitle()}`;
  }

  /**
   * inherited;
   * @returns
   */
  async allowPageSwitch(): Promise<boolean> {
    return await allowPageControlTabChange(this.getAutoSavePromptOptions());
  }

  protected clientDetailViewFactory() {
    return new ClientDetailView(this, {
      clientContainerManager: this.clientContainerManager
    });
  }

  private buildClientActionMenu() {
    if (!this._pageControl) return;
    const menuIcons: MenuIconOption[] = [];

    const save = {
      event: async () => await this.saveAndClose(),
      caption: () => tlang`Save %%client%%`
    };

    if (!isAutoSaving()) menuIcons.push(save);

    this.pageControl.setMenuIcons(menuIcons);
  }

  public async saveAndClose(): Promise<boolean> {
    if (this.isDataReadonly()) return false;

    const result = await this.performAutoSave();
    if (result) {
      return this.tryClose();
    }
    return false;
  }
}

export class ClientView extends ModalViewBase {
  view: ClientDataEntryView | null = null;
  options: ClientViewOptions;

  constructor(options: ClientViewOptions) {
    super();
    this.options = options;
  }
  /**
   * inherited
   * @returns
   */
  protected getTitle(): Snippet {
    return this.view?.getTitle() ?? '';
  }
  /**
   * inherited
   * @returns
   */
  protected modalSize(): string {
    return 'modal-fullscreen';
  }

  /**
   * inherited
   * @returns
   */
  protected footerTemplate(): TemplateResult | null {
    const buttons = buttonsSaveCancel();
    return this.createConfirmCancelButtons(buttons.ok, () => this.view?.saveAndClose(), buttons.cancel);
  }

  /**
   * inherited
   * @returns
   */
  async canClose(): Promise<boolean> {
    return (await this.view?.canClose()) ?? true;
  }
  protected createView(): ClientDataEntryView {
    return new ClientDataEntryView(this.options, this);
  }
  async afterConstruction(): Promise<void> {
    this.view = await constructAsync(this.createView());
  }

  protected bodyTemplate(): EventTemplate {
    return html`${this.view?.ui}`;
  }
  protected renderCloseButtonTemplate(): boolean {
    return false;
  }
}
