import { customElement } from 'lit/decorators.js';

import { QuoteState } from '../../../api/dealer-api-interface-quote';
import { Snippet } from '../../../components/ui/events';
import { tlang } from '@softtech/webmodule-components';
import { BranchMonthlyRevenueByState } from './branch-monthly-revenue-bystate';

@customElement('dashboard-branch-monthly-revenue')
export class BranchMonthlyRevenue extends BranchMonthlyRevenueByState {
  protected async firstUpdated(): Promise<void> {
    this.state = QuoteState.Approved;
    await this.getData();
  }

  protected getHeader(): Snippet {
    return tlang`Monthly Generated Revenue`;
  }
}
