import { ApiCommunications } from '@softtech/webmodule-data-contracts';
import { Client } from '../../api/dealer-api-interface-client';
import { NullPromise } from '../../null-promise';
import { ItemReference } from '../../cache/definitions/cache-item-reference';
import { DataCacheBase } from '../../cache/data-cache-base';
import { FranchiseeClient, ResultGetFranchiseeClientListByIds } from '../../api/dealer-api-interface-franchisee';
import { ClientCacheData } from './cache-data';

interface RequestGetClientListByIds {
  clientIds: string[];
}
interface ResultGetClientListByIds {
  clients: Client[];
}

export class ClientCache extends DataCacheBase<ClientCacheData> {
  constructor(api: ApiCommunications) {
    super(api);
    //depending on what high level controller api we create we could swap this to ClientApi etc.
  }
  //override;
  protected async internalFetch(requestIds: string[]): NullPromise<ItemReference<ClientCacheData>[]> {
    const input: RequestGetClientListByIds = {
      clientIds: requestIds
    };
    const clientPromise = this.api.post<ResultGetClientListByIds>(`api/client/GetClientListByIds`, input);
    const franchiseeclientPromise = this.api.post<ResultGetFranchiseeClientListByIds>(
      `api/Franchisee/GetFranchiseeClientListByIds`,
      input
    );
    await Promise.all([clientPromise, franchiseeclientPromise]);
    const resultClients = await clientPromise;
    const resultFranchiseeClients = await franchiseeclientPromise;
    if (!resultClients || !resultFranchiseeClients) return null;

    const fclient = (id: string): FranchiseeClient | null => {
      return resultFranchiseeClients.clients.find(x => x.id === id) ?? null;
    };
    return resultClients.clients.map(c => {
      const data: ClientCacheData = { client: c, franchiseeClient: fclient(c.id) };
      const qcr: ItemReference<ClientCacheData> = {
        id: c.id,
        displayValue: `${c.name}`,
        data: data,
        onClick: null
      };
      return qcr;
    });
  }
}
