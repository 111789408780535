import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('icon-refresh')
export class IconRefresh extends LitElement {
  @property() active = false;
  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }
  render() {
    const classes = this.active ? 'fa-solid fa-arrows-rotate fa-spin' : 'fa-solid fa-arrows-rotate ';
    return html`<i class=${classes}></i>`;
  }
}
