import { QuoteFrameProcessingDataProvider } from '@softtech/webmodule-data-contracts';
import { showDevelopmentError } from '../../../../development-error';
import { tlang } from '@softtech/webmodule-components';
import { QuoteContainerManager } from '../../../../quotes/data/quote-container';
import { isFrame, processQuoteItemState } from '../../../../quotes/data/quote-helper-functions';
import { QuoteItemContainer } from '../../../../quotes/data/quote-item-container';
import {
  getV6FrameForContainer,
  getV6QuoteItemForContainer,
  getV6SupplierForContainer,
  isV6,
  updateQuoteItemContainer
} from '../../../../quotes/data/v6/helper-functions';
import {
  OutOfDateQuoteItemHandler,
  QuoteItemProcessingCompleteItem
} from '../../../../quotes/views/quote-item-validation-modal';
import { v6Config } from '../../../../v6config/v6config';
import { V6FranchiseeQuoteProviderData } from '../franchisee-quote-provider-data';
import { compare } from '../../../../components/clone';
import { information } from '../../../../components/ui/modal-option';
import { Message, MessageType } from '../../../../components/messages';
import { FranchiseeQuoteContainerManager } from '../franchisee-quote-manager';
import { OutOfDateFailure, FinalProcessItem, ItemContainerCompareResult } from './out-of-date-quote-item-handler';

/***
 * only update items if they have not changed.
 */
export class V6OutOfDateQuoteItemHandlerSafeMode implements OutOfDateQuoteItemHandler {
  failedItems: OutOfDateFailure[] = [];

  private finalStageItems: FinalProcessItem[] = [];
  allowStructureChange: boolean;

  qcm: FranchiseeQuoteContainerManager;
  constructor(qcm: FranchiseeQuoteContainerManager) {
    this.allowStructureChange = false;
    this.qcm = qcm;
  }

  isOutOfDate(qic: QuoteItemContainer): boolean {
    return this.qcm.isFrameOutOfDate(qic, [], false);
  }
  isValidatingItem(qic: QuoteItemContainer): boolean {
    return isV6(qic) && isFrame(qic.item);
  }
  displayInfo(qic: QuoteItemContainer): string {
    const si = getV6SupplierForContainer(qic);
    return si?.quoteItemVersion ?? 'N/A';
  }

  async finalProcessing(qm: QuoteContainerManager): Promise<boolean> {
    if (this.failedItems.length === 0) {
      for (let i = 0; i < this.finalStageItems.length; i++) {
        const itemContainer = this.finalStageItems[i].qic;
        const completeItem = this.finalStageItems[i].completeItem;
        const dataVer = itemContainer.data?.recordVersion;
        const newContainer = await qm.saveAndUpdateQuoteItem(itemContainer, '', undefined, undefined, true);
        itemContainer.item = newContainer.item;
        itemContainer.data = newContainer.data;
        itemContainer.price = newContainer.price;
        itemContainer.buyInData = newContainer.buyInData;
        if (dataVer === newContainer.data?.recordVersion) {
          completeItem.completionStatus = processQuoteItemState.errors;
          completeItem.error = tlang`could not be saved`;
          await information(tlang`Item: ${qm.itemPosition(itemContainer.item.id)} could not be saved`);
          return false;
        }
      }
      return true;
    }
    await information(this.combineErrors(qm), tlang`Error re-validating`);
    return false;
  }
  combineErrors(qm: QuoteContainerManager): string {
    let result = '';
    this.failedItems.forEach(x => {
      result += tlang`\n Item ${qm.itemPosition(x.qic.item.id)}: ${x.reason} `;
    });
    return result;
  }
  async processItemEvent(
    qm: QuoteContainerManager,
    itemContainer: QuoteItemContainer,
    completeItem: QuoteItemProcessingCompleteItem,
    progress: (caption: string) => Promise<void>
  ): Promise<void> {
    //get the v6QuoteItem that was placed in memory that maps to the ui.
    const v6QuoteItem = getV6QuoteItemForContainer(itemContainer);
    if (!v6QuoteItem) {
      //should never happen.
      await showDevelopmentError('quote-item-validation-modal.ts, v6QuoteItem is null');
      return;
    }

    const originalItemContainer = qm.cloneItemContainer(itemContainer);

    const data = qm.quoteProviderData() as V6FranchiseeQuoteProviderData;
    const input: QuoteFrameProcessingDataProvider = {
      supplierId: qm.quote.supplierId,
      supplierReferenceOverrideKey: qm.quote.quoteOwnerId,
      quantity: itemContainer.item.quantity,
      quoteDefaults: data.defaultOptions,
      quoteIGUs: data.igu,
      quoteItem: v6QuoteItem,
      progress: progress
    };
    const result = await v6Config().processV6QuoteItem(input, false);

    if (result) {
      //    const thumb = result.thumbnail ?? '';

      //in safe mode, we are never updating buyin information
      //we may want to pull buyin data at the v6 only level to compare for structural change
      //current design doesn't allow this as its already merged.
      //const buyInCost = await qm.getBuyInCosts(result.quoteItem['buyIn']);
      const newContainer = qm.cloneItemContainer(itemContainer);
      updateQuoteItemContainer(
        newContainer,
        result.quoteItem,
        /*    result.price 
        //do not update the price in the safe mode. this handler is looking to update versions for structural integrity only
        */
        null,
        undefined
      );
      completeItem.newPrice = newContainer.price.quantityCost;
      await progress(processQuoteItemState.finalizing);

      const validations = getV6FrameForContainer(newContainer)?.validations;
      completeItem.validation =
        validations?.map(x => {
          const m: Message = {
            message: x.text ?? '',
            messageType: MessageType.Warning
          };
          return m;
        }) ?? [];

      const compareResult = this.compareItems(originalItemContainer, newContainer);
      if ((!compareResult.structureChanged || this.allowStructureChange) && !compareResult.validationsChanged) {
        this.finalStageItems.push({
          qic: newContainer,
          completeItem: completeItem
        });
        completeItem.completionStatus = processQuoteItemState.completed;
        await progress(processQuoteItemState.completed);
      }
    } else await progress(processQuoteItemState.errors);
  }
  compareItems(
    originalItemContainer: QuoteItemContainer,
    itemContainer: QuoteItemContainer
  ): ItemContainerCompareResult {
    const originalData = getV6FrameForContainer(originalItemContainer);
    const newData = getV6FrameForContainer(itemContainer);

    const result: ItemContainerCompareResult = {
      structureChanged: false,
      validationsChanged: false
    };
    if (!compare(originalData?.validations, newData?.validations)) {
      result.validationsChanged = true;

      this.failedItems.push({
        qic: originalItemContainer,
        reason: `new Validations dont match`
      });
      if (originalData) originalData.validations = [];
      if (newData) newData.validations = [];
    }

    if (!compare(originalData, newData)) {
      result.structureChanged = true;
      if (!this.allowStructureChange)
        this.failedItems.push({
          qic: originalItemContainer,
          reason: tlang`%%frame%% Design Changed`
        });
    }
    return result;
  }
  get displayInfoTitle(): string {
    return tlang`Version`;
  }
}
