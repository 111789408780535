import { constructAsync } from '../../async-constructor';
import { ClientView } from '../../clients/views/client-view';
import { EventBoolean } from '../../components/ui/events';
import { FranchiseeClientContainer, FranchiseeClientContainerManager } from './data/franchisee-client-container';
import { FranchiseeClientView } from './views/franchisee-client-view';
import { waitPatiently } from '../../components/ui/modal-spinner';
import { tlang } from '@softtech/webmodule-components';
import { getClientTitle } from '../clients/data/client-helper-functions';
import { ResourceLocker } from '../common/resource-lock';
import { getApiFactory } from '../../api/api-injector';
import { cache } from '../cache/cache-registry';

export class ClientLock extends ResourceLocker {
  constructor(id: string, lockRevokedEvent: () => Promise<void>) {
    super(id, 'client', 'client', lockRevokedEvent);
  }
}
export function clientContainerManagerFactory(
  container: FranchiseeClientContainer,
  forceLocked?: EventBoolean
): FranchiseeClientContainerManager {
  const m = new FranchiseeClientContainerManager(
    container,
    getApiFactory().client(),
    getApiFactory().paymentProfile(),
    () => getClientTitle(container.client),
    forceLocked
  );
  m.afterSave.push(() => cache().client.flush([container.clientId]));
  return m;
}

export function getClientContainer(clientId: string): FranchiseeClientContainer {
  return new FranchiseeClientContainer(clientId, null, null, '', '');
}

export async function clientViewFactory(clientContainerManager: FranchiseeClientContainerManager): Promise<ClientView> {
  return await constructAsync(
    new FranchiseeClientView({
      viewTitle: () => clientContainerManager.title(),
      clientContainerManager: clientContainerManager
    })
  );
}

export async function createClientView(containerManager: FranchiseeClientContainerManager): Promise<ClientView> {
  let view: ClientView | null = null;

  const waiting = waitPatiently(
    () => tlang`Opening %%client%%`,
    () => tlang`Please wait while we construct the %%client%%"`
  );
  try {
    view = await clientViewFactory(containerManager);
    await waiting.hideModal();
    return view;
  } finally {
    await waiting.hideModal();
  }
}
