import { ApiCommunications, NullPromise } from '@softtech/webmodule-data-contracts';
import { ResultGetAllUserProfileOfTenant } from './dealer-api-interface-user';
import { UserApi } from './user-api';

export class UserApiImplementation implements UserApi {
  api: ApiCommunications;

  constructor(api: ApiCommunications) {
    this.api = api;
  }

  public async getAllUserProfilesForCurrentTenant(): NullPromise<ResultGetAllUserProfileOfTenant> {
    return await this.api.post<ResultGetAllUserProfileOfTenant>('api/User/GetAllUserProfileOfTenant', {});
  }
}
