import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('icon-plus')
export class IconPlus extends LitElement {
  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }
  render() {
    return html`<i class="fa-solid fa-square-plus"></i>`;
  }
}
