import { tlang } from '@softtech/webmodule-components';
import { EventSnippet } from './events';

import { WaitPatiently } from './modal-spinner';
import { customElement } from 'lit/decorators.js';

export function waitPatientlyLoading(title?: EventSnippet, message?: EventSnippet) {
  const modal = new WaitPatientlyLoading();
  modal.eventTitle = title ?? (() => tlang`Loading`);
  modal.message = message ?? (() => tlang`just a moment...`);

  modal.delayedShow();
  return modal;
}

@customElement('wm-waitpatientlyloading')
//a progress window default to run will doing a save to the server
export class WaitPatientlyLoading extends WaitPatiently {
  borderType() {
    return 'basic';
  }
}
