import { html } from 'lit';
import { customElement } from 'lit/decorators.js';

import { getInternalId } from '../../components/ui/databinding/databinding';

import { getCurrentUser } from '@softtech/webmodule-components';

import { PageBase } from './helpers/page-base';
import { goStaticURL } from '../../components/ui/resource-resolver';
import { clearCurrentUserFromSession } from '../../components/user-session-verifier';
import { getApi } from '../../api/api-injector';

@customElement('app-login')
export class AppLogin extends PageBase {
  internalId: string = getInternalId();
  // For more information on using properties and state in lit
  // check out this link https://lit.dev/docs/components/properties/
  constructor() {
    super();
  }

  protected divertToLogin(): boolean {
    return false; //we are the login
  }
  loginEvent = async () => {
    try {
      await this.performLoginProcess();
    } catch (e) {
      //
    }
    //
  };
  login() {
    setTimeout(this.loginEvent, 100);
  }
  private get elementId(): string {
    return `login-page-${this.internalId}`;
  }

  protected async awaken() {
    //if we ever reach this page and we are not logged in
    //then we want to force a page reload which will
    //take us through our full reload process of the apoplication
    //in index.html including the user login process
    if (this.loggedIn) goStaticURL('');
    else this.login();
  }
  async dispose() {
    super.dispose();
  }
  async performLoginProcess() {
    clearCurrentUserFromSession();
    if (getCurrentUser() == null) {
      await getApi().performAuthenticationProcess();
      if (getCurrentUser() !== null) {
        goStaticURL('');
      } else this.login();
    } else goStaticURL('');
    return true;
  }

  render() {
    return html`
      <div id=${this.elementId} class="page-content">
        <div class="page-container"></div>
      </div>
    `;
  }
  meta() {
    return {
      title: 'SoftTech Dealer',
      titleTemplate: null,
      description: 'SoftTech dealer CPQ'
    };
  }
}
