import { FrameValidation, FrameValidationType } from '@softtech/webmodule-data-contracts';

export function highestValidationRank(issues?: FrameValidation[]) {
  let rank = 0;
  issues?.forEach(x => {
    rank = Math.max(rank, validationTypeToRank(x.type));
  });
  return rank;
}

export function validationTypeToRank(type: FrameValidationType) {
  switch (type) {
    case FrameValidationType.nothing:
      return 0;
    case FrameValidationType.note:
      return 1;
    case FrameValidationType.information:
      return 2;
    case FrameValidationType.warning:
      return 3;
    case FrameValidationType.critical:
      return 4;
    case FrameValidationType.outOfDate:
      return 5;
  }
}

export function rankToValidationType(rank: number): FrameValidationType {
  switch (rank) {
    case 0:
      return FrameValidationType.nothing;
    case 1:
      return FrameValidationType.note;
    case 2:
      return FrameValidationType.information;
    case 3:
      return FrameValidationType.warning;
    case 4:
      return FrameValidationType.critical;
    case 5:
      return FrameValidationType.outOfDate;
  }
  throw new Error(`Unrecognized FrameValidationType: ${rank}`);
}
