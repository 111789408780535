import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('icon-delete')
export class IconDelete extends LitElement {
  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }
  render() {
    return html`<i class="fa-solid fa-trash-can"></i>`;
  }
}
