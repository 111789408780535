import { getCurrentUser, tlang } from '@softtech/webmodule-components';
import { isCynclyStaff } from '../components/currentuser-claims';
import { information } from '../components/ui/modal-option';
import { userDataStore } from '../dealer-franchisee/common/current-user-data-store';
import {
  DealerApiCommunications,
  checkPATTokenOnURL,
  performInitialLoginAttempt,
  refreshUserToken,
  startTokenRefreshTimer
} from './dealer-api-communications';

export async function performPageLoadLoginVerifications(updateNote: (message: string) => void) {
  // if we resolved a PAT token, then we just force switched authentication and can exit
  if (await checkPATTokenOnURL()) return true;

  //This is going to go to the api and verify the user token is valid
  //and if it is, it will also set the security roles of the user
  const valid = await DealerApiCommunications.verifyUserAndSetSecurityClaims('api/LicenseServer/verifyuser');
  if (!valid) {
    await performInitialLoginAttempt();
    return getCurrentUser() !== null;
  } else {
    const user = await refreshUserToken(updateNote);
    if (user) {
      updateNote('Fetching User Settings');
      await userDataStore.loadCoreDetails();

      const usingPAT = localStorage.getItem('PAT-in-use') !== null;
      if (usingPAT) {
        const title = (user as any).tenantName ? `${(user as any).tenantName}` : tlang`Support Access Granted`;
        if (!isCynclyStaff())
          await information(
            tlang`You are accessing this system using a temporary access login. please logoff if this is unintentional`,
            title
          );
      } else {
        startTokenRefreshTimer();
      }
      return true;
    } else return false;
  }
}

(function () {
  globalThis.dealerVerifyUser = async (updateNote: (message: string) => void) => {
    return await performPageLoadLoginVerifications(updateNote);
  };
})();
