//this will contain the content of a quote-item tab
//which will include a v6/v7 config frame + surrounds for

// eslint-disable-next-line import/named
import { html, nothing } from 'lit';
import { EventQuoteItemContainerAction } from '../data/events';
import { QuoteContainerManager } from '../data/quote-container';
import { DevelopmentError } from '../../development-error';
import { DataCacheGeneric } from '../../cache/generic-data-cache';
import { EventTemplate } from '../../components/ui/events';
import { ViewBase } from '../../components/ui/view-base';
import { customElement } from 'lit/decorators.js';

export interface QuoteItemsViewOptions {
  quoteManager: QuoteContainerManager;
  userProfileCache: DataCacheGeneric;
  eventRunQuoteItemAction: EventQuoteItemContainerAction;
}

//saving buttons etc.
@customElement('wm-quoteitemsview')
export class QuoteItemsView extends ViewBase {
  protected readonly quoteManager: QuoteContainerManager;
  protected readonly eventRunQuoteItemActions: EventQuoteItemContainerAction;

  constructor(options: QuoteItemsViewOptions) {
    super();
    this.quoteManager = options.quoteManager;
    this.eventRunQuoteItemActions = options.eventRunQuoteItemAction;
  }

  public async prepareForSave() {
    throw new DevelopmentError('quote-items-view, Method not implemented.');
  }

  getValidationErrors(): string[] {
    return [];
  }

  public async refreshData(): Promise<void> {
    //this is a force reload of the data. This is not something that we want to do, if items are inuse
    //as we would end up with a bad loading of data.
  }

  public async invalidate(): Promise<void> {
    this.requestUpdate();
  }

  protected template(): EventTemplate {
    return html`${nothing}`;
  }
}

globalThis.checkClosest = function (item: any, checked: boolean) {
  $(item).closest('.multi-actions').find('input').prop('checked', checked);
};
