import { ContactDataEntryView, ContactView, ContactViewOptions } from '../../../clients/views/contact-view';
import { ContactContainerManager } from '../../../clients/data/contact-container-manager';
import { cache } from '../../cache/cache-registry';
import { DataEntryOwner } from '../../../components/ui/DataEntryOwner';
import { showValidations } from '../../../components/ui/modal-validationhandler';
import { customElement } from 'lit/decorators.js';

@customElement('wm-franchiseecontactdataentryview')
export class FranchiseeContactDataEntryView extends ContactDataEntryView {
  constructor(options: ContactViewOptions, owner: DataEntryOwner) {
    super(options, owner);
  }

  protected contactContainerManagerFactory(): ContactContainerManager {
    const m = new ContactContainerManager(this.contactContainer, this.eventTitle, this.clientApi);
    m.afterSave.push(() => {
      cache().primaryContact.flush([this.contactContainerManager.contact.clientId]);
      cache().contact.flush([this.contactContainerManager.contactId]);
    });
    return m;
  }

  protected async checkValidations(): Promise<boolean> {
    const errors = this.getValidationErrors();
    if (errors.length != 0) {
      await showValidations(errors);
      return false;
    }
    return true;
  }
}
@customElement('wm-franchiseecontactview')
export class FranchiseeContactView extends ContactView {
  options: ContactViewOptions;

  constructor(options: ContactViewOptions) {
    super(options);
    this.options = options;
  }
  protected createView(): ContactDataEntryView {
    return new FranchiseeContactDataEntryView(this.options, this);
  }
}
